import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Utils
import { ValidatorContext } from 'context/ValidatorContext';
import {
  mustBeFilled,
  mustBeNumber,
  mustBeMore0,
  mustBeMore0AndLess6,
  mustBeMore320AndLess1024,
} from 'config/validationRules';

// Components
import Label from 'components/Label';
import ValidateInput from 'components/ValidateInput';
import Grid from '../../Grid';

const GridBlock = ({ element, onChange, mobileView, t }) => {
  const { actions } = React.useContext(ValidatorContext);
  const general = element;
  const countCalls = general.countCalls || '';

  return (
    <>
      {mobileView && (
        <div>
          <Label
            labelText={t('basic.mobileVersionWidth')}
            mark={t('basic.mobileFieldHint')}
            asterisk
          />
          <ValidateInput
            name="triggerMobile"
            type="number"
            validateName="general-block-triggerMobile"
            defaultValue={general.triggerMobile}
            value={general.triggerMobile}
            controlled
            placeholder="768"
            onChange={value =>
              onChange({
                item: 'general',
                response: { name: 'triggerMobile', value },
              })
            }
            rules={[mustBeFilled, mustBeNumber, mustBeMore320AndLess1024]}
            onValidate={input => actions.validate(input)}
          />
          <br />
        </div>
      )}

      <Grid columns={2}>
        <div>
          <Label labelText={t('basic.columns')} asterisk />
          <ValidateInput
            name="countCalls"
            validateName="grid-block-countCalls"
            defaultValue={countCalls}
            value={countCalls}
            controlled
            placeholder="3"
            type="number"
            onChange={value =>
              onChange({
                item: 'general',
                response: { name: 'countCalls', value },
              })
            }
            rules={[mustBeFilled, mustBeNumber, mustBeMore0AndLess6]}
            onValidate={input => actions.validate(input)}
          />
        </div>

        <div>
          <Label labelText={t('basic.rows')} asterisk />
          <ValidateInput
            name="countRows"
            validateName="grid-block-countRows"
            defaultValue={general.countRows}
            value={general.countRows}
            controlled
            placeholder="2"
            type="number"
            onChange={value =>
              onChange({
                item: 'general',
                response: { name: 'countRows', value },
              })
            }
            rules={[mustBeFilled, mustBeNumber, mustBeMore0]}
            onValidate={input => actions.validate(input)}
          />
        </div>

        <div>
          <Label labelText={t('basic.verticalDistance')} asterisk />
          <ValidateInput
            name="marginBottom"
            validateName="container-marginBottom"
            defaultValue={general.marginBottom}
            value={general.marginBottom}
            controlled
            placeholder="2"
            type="number"
            onChange={value =>
              onChange({
                item: 'general',
                response: { name: 'marginBottom', value },
              })
            }
            rules={[mustBeFilled, mustBeNumber]}
            onValidate={input => actions.validate(input)}
          />
        </div>

        <div>
          <Label labelText={t('basic.horizontalDistance')} asterisk />
          <ValidateInput
            name="paddingHorizontal"
            validateName="container-paddingHorizontal"
            defaultValue={general.paddingHorizontal}
            value={general.paddingHorizontal}
            controlled
            placeholder="2"
            type="number"
            onChange={value =>
              onChange({
                item: 'general',
                response: { name: 'paddingHorizontal', value },
              })
            }
            rules={[mustBeFilled, mustBeNumber]}
            onValidate={input => actions.validate(input)}
          />
        </div>
      </Grid>
    </>
  );
};

GridBlock.propTypes = {
  element: PropTypes.shape({}),
  onChange: PropTypes.func,
  mobileView: PropTypes.bool,
};

GridBlock.defaultProps = {
  element: {},
  onChange: () => {},
  mobileView: false,
};

export default withTranslation()(GridBlock);
