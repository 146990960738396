import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { defaultDate } from 'config/datesFormat';
import { moment } from 'utils/date';
import PropTypes from 'prop-types';

import { insertItemToArrayConditionally } from '../../../utils';
import {
  getDiffLengthBetweenDays,
  setBoundaryTimes,
} from '../../../utils/date';
import { truthyDecorator } from '../../../utils/decorators';

const { RangePicker } = DatePicker;

class FilterPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datesRange: setBoundaryTimes(props.categoriesForX),
    };
  }

  setDatesRange = datesRange => {
    this.setState({ datesRange });
  };

  handleDatesRange = datesRange => {
    this.props.onChange(datesRange);
    this.setDatesRange(datesRange);
  };

  isDatesRangeFull = datesRange => datesRange && datesRange.length >= 2;

  isDatesRangeMoreThan = (datesRange, number) =>
    getDiffLengthBetweenDays(datesRange) > number;

  isDatesRangeMoreThan2 = datesRange =>
    this.isDatesRangeMoreThan(datesRange, 1);

  isDatesNotEqualPrev = datesRange => {
    return !datesRange.every(
      (_, index) =>
        datesRange[index].format().valueOf() ===
        this.state.datesRange[index].format().valueOf(),
    );
  };

  calendarTruthyDecorator = (...args) =>
    truthyDecorator([
      this.isDatesRangeFull,
      this.isDatesNotEqualPrev,
      ...insertItemToArrayConditionally(
        !this.props.enableSingleDateSelection,
        this.isDatesRangeMoreThan2,
      ),
    ])(...args);

  handleSetDatesRange = days => {
    if (!days.length) return;

    const momentDays = setBoundaryTimes([days[0].toDate(), days[1].toDate()]);

    return this.calendarTruthyDecorator(momentDays, this.handleDatesRange);
  };

  render() {
    const { style, t } = this.props;
    const { datesRange } = this.state;

    return (
      <RangePicker
        onChange={this.handleSetDatesRange}
        format={defaultDate}
        defaultValue={[moment(datesRange[0]), moment(datesRange[1])]}
        style={style}
        ranges={{
          [t('dates.today')]: [moment(), moment()],
          [t('dates.thisWeek')]: [moment().startOf('week'), moment()],
          [t('dates.thisMonth')]: [moment().startOf('month'), moment()],
        }}
        locale={locale}
      />
    );
  }
}

FilterPanel.defaultProps = {
  enableSingleDateSelection: true,
  onChange: () => {},
  button: null,
};

FilterPanel.propTypes = {
  enableSingleDateSelection: PropTypes.bool,
  onChange: PropTypes.func,
  button: PropTypes.shape({}),
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(withTranslation('common')(FilterPanel));
