import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

export const CodeToggler = ({ isCollapsible, isVisible, toggleVisibility }) => {
  const { t } = useTranslation();

  return (
    <>
      {isCollapsible === true && (
        <Button
          type="link"
          icon={`${isVisible ? 'minus' : 'plus'}-square`}
          onClick={toggleVisibility}
        >
          {`${t(`basic.${isVisible ? 'hide' : 'show'}`)} ${t(
            'basic.code',
          ).toLowerCase()}`}
        </Button>
      )}
    </>
  );
};
