import React, { useCallback, useEffect, useState } from 'react';
import { string, oneOfType, element, shape, func } from 'prop-types';
import { Button, Popover } from 'antd';
import { colorBlue } from '../../../styles/js/variables/colors';
import { getShownPopups, setShownPopups } from '../../../services/localStorage';
import WithComponent from '../../../HOC/WithComponent';

const TableNotice = ({
  iconComponent: Icon,
  color = colorBlue,
  title = 'info',
  buttonProps,
  buttonText,
  storageKey,
  iconProps,
  onClick,
  text,
}) => {
  const [withWrapper, setWithWrapper] = useState(true); // Used to unwrap icon and run onClick prop (if it is present)
  const [isVisible, setIsVisible] = useState(false);
  const shownPopups = getShownPopups();

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    setShownPopups({ ...shownPopups, [storageKey]: true });
    setWithWrapper(!onClick);
  };

  const handleButtonClick = () => {
    toggleVisibility();
    buttonProps.onClick();
  };

  const showFirstTimeNotice = useCallback(() => {
    const timeout = setTimeout(
      () => setIsVisible(!(shownPopups && shownPopups[storageKey] === true)),
      1000,
    );
    return () => clearTimeout(timeout);
  }, [shownPopups, storageKey]);

  // Show notice for the first time
  useEffect(showFirstTimeNotice, []);

  return (
    <WithComponent
      withComponent={withWrapper}
      component={Popover}
      componentProps={{
        title: <b style={{ color }}>{title}</b>,
        content: (
          <div style={{ maxWidth: '300px' }}>
            {text}&nbsp;
            {buttonText && (
              <Button {...buttonProps} onClick={handleButtonClick}>
                {buttonText}
              </Button>
            )}
          </div>
        ),
        trigger: 'click',
        onVisibleChange: toggleVisibility,
        visible: isVisible,
      }}
    >
      <Icon
        {...iconProps}
        className="look-at-me"
        style={{
          color,
          cursor: 'pointer',
          fontSize: iconProps ? 'inherit' : '1.125em',
          marginLeft: '10px',
        }}
        onClick={onClick}
      />
    </WithComponent>
  );
};

TableNotice.propTypes = {
  storageKey: string.isRequired,
  text: oneOfType([string, element]).isRequired,
  title: string,
  color: string,
  iconComponent: func,
  iconProps: shape({}),
  buttonProps: shape({}),
  buttonText: string,
};

export default TableNotice;
