import { HIDE_MODAL, SHOW_MODAL } from '../../types';

export const initialState = {
  type: '',
  params: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case HIDE_MODAL: {
      return initialState;
    }
    default:
      return state;
  }
}
