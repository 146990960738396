import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import history from 'redux/history';
import auth from 'redux/reducers/auth';
import dataLists from 'redux/reducers/dataLists';
import informer from 'redux/reducers/informer';
import informerByDate from 'redux/reducers/informerByDate';
import informerNews from 'redux/reducers/informerNews';
import informerSettings from 'redux/reducers/informerSettings';
import modals from 'redux/reducers/modals';
import network from 'redux/reducers/network';
import notifications from 'redux/reducers/notifications';
import site from 'redux/reducers/site';
import sites from 'redux/reducers/sites';
import userSettings from 'redux/reducers/userSettings';

export default combineReducers({
  router: connectRouter(history),
  auth,
  informer,
  informerByDate,
  informerSettings,
  network,
  sites,
  site,
  notifications,
  modals,
  informerNews,
  userSettings,
  dataLists,
});
