import React from 'react';
import { Button } from 'antd';
import { withTranslation } from 'react-i18next';

const defaultStyles = {
  marginTop: '1em',
  textAlign: 'right',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
const borderedStyles = {
  paddingTop: '1em',
  borderTop: '1px solid rgba(0,0,0,0.125)',
};
const stickyStyles = {
  position: 'fixed',
  left: 0,
  bottom: 0,
  background: '#fff',
  padding: '1em 1.5em',
  boxShadow: '0 0 30px rgba(0,0,0,0.125)',
  width: '100%',
  zIndex: 6,
};

const FormActions = ({
  wrapperStyles,
  titleSubmit,
  titleCancel,
  appendRight,
  appendLeft,
  buttonsRef,
  isLoading,
  disabled,
  bordered,
  onSubmit,
  onCancel,
  sticky,
  style = defaultStyles,
  type,
  t,
}) => {
  const styles = {
    ...style,
    ...(bordered && borderedStyles),
    ...(sticky && stickyStyles),
  };
  const Left = () => appendLeft || '';
  const Right = () => appendRight || '';

  return (
    <div
      className="form-actions"
      style={styles}
      {...(buttonsRef && { ref: buttonsRef })}
    >
      {appendLeft && <Left />}

      <div style={wrapperStyles}>
        {appendRight && (
          <>
            <Right />
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          </>
        )}
        <Button onClick={onCancel} type="default" disabled={disabled}>
          {titleCancel || t('form.buttonCancel')}
        </Button>
        &nbsp;
        <Button
          onClick={onSubmit}
          type={type || 'primary'}
          disabled={disabled}
          loading={isLoading}
        >
          {titleSubmit || t('form.buttonProceed')}
        </Button>
      </div>
    </div>
  );
};

export default withTranslation()(FormActions);
