import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { notification } from 'antd';
import { string, oneOf, shape, arrayOf } from 'prop-types';

import { hideNotification } from '../../redux/actions/notifications';

const Notifications = ({ actions, notifications, placement }) => {
  useEffect(() => {
    notifications.forEach(({ id, text, type = 'info', timeout }) =>
      notification[type]({
        key: id,
        message: type.toUpperCase(),
        description: text,
        duration: timeout / 1000 || (text && text.length * 0.125),
        placement,
        onClose: () => actions.hideNotification(id),
      }),
    );
  }, [notifications, placement, actions]);

  return null;
};

const mapStateToProps = ({ notifications }) => ({
  notifications: notifications.list,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators({ hideNotification }, dispatch),
  },
});

Notifications.defaultProps = {
  placement: 'topRight',
  actions: {},
};

Notifications.propTypes = {
  actions: shape({ id: string, text: string, type: string }),
  notifications: arrayOf(shape({})).isRequired,
  placement: oneOf(['topLeft', 'topRight', 'bottomRight', 'bottomLeft']),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notifications);
