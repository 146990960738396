import {
  FAILURE_FETCH_CHART_INFORMER_NEWS,
  FAILURE_FETCH_TABLE_INFORMER_NEWS,
  START_FETCH_CHART_INFORMER_NEWS,
  START_FETCH_TABLE_INFORMER_NEWS,
  SUCCESS_FETCH_CHART_INFORMER_NEWS,
  SUCCESS_FETCH_NEWS,
  SUCCESS_FETCH_TABLE_INFORMER_NEWS,
} from 'redux/types';
import { convertToChartsData } from 'services/formatter';

const initialState = {
  info: {
    url: '',
    title: '',
  },
  table: {
    isLoading: true,
    total: 0,
    limit: 20,
    offset: 0,
    data: [],
  },
  chart: {
    isLoading: true,
    data: [],
  },
};

export default function informer(state = initialState, action = {}) {
  switch (action.type) {
    case START_FETCH_TABLE_INFORMER_NEWS:
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: true,
        },
      };

    case SUCCESS_FETCH_TABLE_INFORMER_NEWS:
      return {
        ...state,
        table: {
          ...state.table,
          ...action.payload,
          isLoading: false,
        },
      };

    case FAILURE_FETCH_TABLE_INFORMER_NEWS:
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: false,
        },
      };

    case START_FETCH_CHART_INFORMER_NEWS:
      return {
        ...state,
        chart: {
          ...state.chart,
          isLoading: true,
        },
      };

    case SUCCESS_FETCH_CHART_INFORMER_NEWS:
      return {
        ...state,
        chart: {
          ...convertToChartsData(action.payload.data),
          isLoading: false,
        },
      };

    case FAILURE_FETCH_CHART_INFORMER_NEWS:
      return {
        ...state,
        chart: {
          ...state.chart,
          isLoading: false,
        },
      };

    case SUCCESS_FETCH_NEWS:
      return {
        ...state,
        info: {
          ...state.info,
          url: action.payload.data.url,
          title: action.payload.data.title,
        },
      };

    default:
      return state;
  }
}
