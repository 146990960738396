import {
  FAILURE_FETCH_TABLE_INFORMER_BY_DATE,
  START_FETCH_TABLE_INFORMER_BY_DATE,
  SUCCESS_FETCH_TABLE_INFORMER_BY_DATE,
} from 'redux/types';

const initialState = {
  info: {},
  table: {
    isLoading: true,
    total: 0,
    limit: 20,
    offset: 0,
    data: [],
  },
};

export default function informer(state = initialState, action = {}) {
  switch (action.type) {
    // Fetch table informer by id
    // ________________________________________________________
    case START_FETCH_TABLE_INFORMER_BY_DATE:
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: true,
        },
      };

    case SUCCESS_FETCH_TABLE_INFORMER_BY_DATE:
      return {
        ...state,
        table: {
          ...action.payload,
          isLoading: false,
        },
      };

    case FAILURE_FETCH_TABLE_INFORMER_BY_DATE:
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: false,
        },
      };
    // ________________________________________________________
    // END Fetch table informer by id

    default:
      return state;
  }
}
