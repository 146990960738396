import React from 'react';
import { connect } from 'react-redux';
import { bool, shape, func } from 'prop-types';
import { Col, Form, Icon, Input, Row } from 'antd';
import { withTranslation } from 'react-i18next';

// Utils
import useValidation from '../utils/useValidation';
import { mustBeFilled, mustBeUrl } from '../../../config/validationRules';

// Components
import FormActions from '../components/FormActions';

const initialForm = {
  domain: { value: '', rules: [mustBeFilled, mustBeUrl] },
  name: { value: '', rules: [mustBeFilled] },
};

const AddSiteForm = ({ formData, onSubmit, onCancel, isSaving, t }) => {
  const {
    errors,
    currentForm,
    bindedSubmit,
    isFormTouched,
    bindedInputFunctions,
  } = useValidation(onSubmit, initialForm, formData);

  return (
    <Form>
      <h4>{t('basic.general')}:</h4>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item {...errors.domain} label={t('basic.domain')}>
            <Input
              name="domain"
              size="large"
              placeholder="..."
              defaultValue={currentForm.domain}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item {...errors.name} label={t('basic.title')}>
            <Input
              name="name"
              size="large"
              placeholder="..."
              defaultValue={currentForm.name}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
      </Row>

      <div className="box">
        <h3>
          <Icon type="bar-chart" /> &nbsp;{t('basic.siteStatsTitle')}
        </h3>

        <p>
          {t('basic.siteStatsDesc')} <b>{process.env.REACT_APP_ADMIN_MAIL}</b>
        </p>
        <p>
          <Icon type="info-circle" /> {t('basic.siteStatsInstruction')}: &nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.google.com/analytics/answer/1009702?hl=ru"
          >
            <Icon type="link" /> {t('basic.openInNewTab')}
          </a>
        </p>
      </div>

      <br />
      <FormActions
        isLoading={isSaving}
        style={{ textAlign: 'right' }}
        titleSubmit={t('form.buttonSave')}
        titleCancel={t('form.buttonCancel')}
        onSubmit={bindedSubmit}
        onCancel={onCancel}
        disabled={!isFormTouched}
      />
    </Form>
  );
};

AddSiteForm.propTypes = {
  formData: shape({}),
  onSubmit: func,
  onCancel: func,
  isSaving: bool,
  t: func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(AddSiteForm));
