import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from '../types';

export const showNotification = payload => ({
  type: SHOW_NOTIFICATION,
  payload,
});

export const hideNotification = payload => ({
  type: HIDE_NOTIFICATION,
  payload,
});
