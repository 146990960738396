import { getSingleHeader } from 'components/Table/config/moldingTable';

import { getSingleHeaders } from './moldingTable';

// todo CALL ALL FUNCTION
const tableHeaders = {
  // label SITES (publisher)
  sitesHeader: params => [
    getSingleHeader('sites', params, true),
    ...getSingleHeaders(
      [
        'status',
        'views',
        'views_real',
        'display',
        'ctr',
        'click_from',
        'click_on',
        'ko',
      ],
      params,
    ),
  ],

  // label SITE STATISTICS
  siteStatisticsHeader: params => [
    getSingleHeader('date', params, true),
    ...getSingleHeaders(
      ['views', 'views_real', 'display', 'ctr', 'click_from', 'click_on', 'ko'],
      params,
    ),
  ],

  // label SITE (informers list)
  siteHeader: params => [
    getSingleHeader('informer', params, true),
    getSingleHeader('status', params, true),
    ...getSingleHeaders(
      ['views', 'views_real', 'display', 'ctr', 'click_from'],
      params,
    ),
  ],

  // label INFORMER STATISTICS
  informerHeader: params => [
    getSingleHeader('date', params, true),
    ...getSingleHeaders(
      [
        'page_informer_stats_views',
        'views_real',
        'page_informer_stats_display',
        'page_informer_stats_click_from',
        'page_informer_stats_ctr',
      ],
      params,
    ),
  ],

  // label INFORMER NEWS (url)
  informerByDateHeader: params => [
    getSingleHeader('url', params),
    ...getSingleHeaders(
      ['views', 'views_real', 'display', 'ctr', 'click_from'],
      params,
    ),
  ],

  // label INFORMER BY DATE
  informerNewsHeader: params => [
    getSingleHeader('date', params, true),
    ...getSingleHeaders(
      ['views', 'views_real', 'display', 'ctr', 'click_from'],
      params,
    ),
  ],
};

export const {
  sitesHeader,
  siteHeader,
  siteStatisticsHeader,
  informerHeader,
  informerByDateHeader,
  informerNewsHeader,
} = tableHeaders;
