import styled from 'styled-components';

export const TermsWrapper = styled.div`
  max-width: 760px;
  margin: 3em auto;
  text-align: justify;
  line-height: 1.5;
`;

export const TermsItem = styled.div`
  max-width: 760px;
  margin: 0em auto 1em;
  text-align: justify;
  line-height: 1.5;

  > b {
    display: block;
    margin-bottom: 10px;
  }

  &:after {
    content: '*       *       *';
    display: block;
    text-align: center;
    font-size: 2.25em;
    line-height: 1;
    opacity: 0.25;
    margin-top: 1em;
  }

  ul {
    margin-bottom: 10px;
    li {
      margin-bottom: 10px;
    }
  }
`;
