import React, { useEffect, useState } from 'react';
import { bool, element, shape, func } from 'prop-types';
import { Tooltip } from 'antd';

const WithTooltip = ({
  onVisibleChange,
  withTooltip,
  withTimeout,
  children,
  ...tooltipProps
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleChangeVisibility = () => {
    setIsVisible(!isVisible);

    if (onVisibleChange) {
      onVisibleChange();
    }
  };

  useEffect(() => {
    const timeout = setTimeout(
      () => setIsVisible(true),
      withTimeout ? 2000 : 0,
    );
    return () => clearTimeout(timeout);
  }, [withTimeout]);

  return withTooltip ? (
    <Tooltip
      {...tooltipProps}
      visible={isVisible}
      trigger={['hover', 'click', 'focus']}
      onVisibleChange={handleChangeVisibility}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
};

WithTooltip.propTypes = {
  withTooltip: bool,
  children: element,
  tooltipProps: shape({}),
  withTimeout: bool,
  onVisibleChange: func,
};

export default WithTooltip;
