export const fontsVariants = [
  { key: 'Default', text: '-- Default --', value: 'Default' },
  { key: 'Roboto', text: 'Roboto', value: 'Roboto' },
  { key: 'Helvetica', text: 'Helvetica', value: 'Helvetica' },
  { key: 'Gotham', text: 'Gotham', value: 'Gotham' },
  { key: 'Arial', text: 'Arial', value: 'Arial' },
  { key: 'TimesNewRoman', text: 'Times New Roman', value: 'Times New Roman' },
  { key: 'Georgia', text: 'Georgia', value: 'Georgia' },
  { key: 'ComicSansMS', text: 'Comic Sans MS', value: 'Comic Sans MS' },
  { key: 'Impact', text: 'Impact', value: 'Impact' },
];
