import React from 'react';

export const WithComponent = ({
  isWithComponent,
  component: Component,
  children,
  ...componentProps
}) =>
  isWithComponent ? (
    <Component {...componentProps}>{children}</Component>
  ) : (
    children
  );
