import {
  arrayOf,
  bool,
  exact,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';

import { iInformerSiteNews } from './informer';

export const iSite = {
  chart: shape({
    isLoading: bool,
    views: arrayOf(number),
    efficiencies: arrayOf(number),
  }),
  info: exact({
    id: number,
    name: string,
    domain: string,
    status: string,
  }),
  statisticsChart: exact({
    isLoading: bool,
  }),
  statisticsTable: exact({
    isLoading: bool,
    total: number,
    limit: number,
    offset: number,
    start: number,
    end: number,
    data: arrayOf(shape({})),
  }),
  table: exact({
    isLoading: bool,
    total: number,
    limit: number,
    offset: number,
    start: number,
    end: number,
    data: arrayOf(shape({})),
    siteId: oneOfType([number, string]),
  }),
  news: arrayOf(exact(iInformerSiteNews)),
};
