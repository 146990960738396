import styled from 'styled-components';

export const Box = styled.div`
  text-align: left;
  padding: 1em;
  border-radius: 0.5em;
  border: 1px solid #eeeee9;
  background-color: rgba(238, 238, 233, 0.5);
  transition: all 0.5s;
`;
