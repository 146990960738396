import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ValidateInput extends Component {
  constructor(props) {
    super(props);

    const { defaultValue } = this.props;
    this.state = {
      isError: false,
      error: '',
      value: defaultValue || '',
    };

    this.ref = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.controlled) {
      return {
        ...state,
        value: props.value,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.value !== nextProps.value ||
      this.state.value !== nextState.value
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.controlled &&
      this.props.force &&
      this.props.value !== prevProps.value
    ) {
      this.onChange(this.state.value, true);
    }
  }

  validate = value => {
    const { rules } = this.props;
    if (!rules) return null;

    return rules.find(({ regexp }) => !value.match(regexp));
  };

  extractValue = event => event.target.value;

  onChangeValue = event => {
    const value = this.extractValue(event);
    this.onChange(value);
  };

  setError = () => {
    const { value } = this.state;
    const error = this.validate(value);
    const nextState = {
      isError: !!error,
      error: error ? error.text : '',
      value,
    };
    this.setState(nextState);
  };

  onChange = (value, isInit) => {
    const error = this.validate(value);
    const nextState = {
      isError: !!error,
      error: error ? error.text : '',
      value,
    };

    const initState = {
      isError: false,
      error: '',
      value,
    };

    this.setState({ ...(isInit ? initState : nextState) }, () => {
      this.props.onChange(value, !!error, this.ref.current, this.setError);

      this.props.onValidate({
        isError: !!error,
        el: this.ref.current,
        trigger: this.setError,
        name: this.props.validateName,
      });
    });
  };

  render() {
    const { isError, error, value } = this.state;
    const { placeholder, type } = this.props;
    const inputStyles = `${isError ? 'error-border' : ''} `;

    return (
      <div className="validator-field">
        <input
          ref={this.ref}
          className={inputStyles}
          value={value || ''}
          onChange={this.onChangeValue}
          placeholder={placeholder}
          type={type}
        />
        {isError && (
          <div className="ui bottom center popup transition visible">
            <div className="content">{error}</div>
          </div>
        )}
      </div>
    );
  }
}

ValidateInput.propTypes = {
  defaultValue: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onValidate: PropTypes.func,
  rules: PropTypes.arrayOf(PropTypes.shape({})),
};

ValidateInput.defaultProps = {
  defaultValue: '',
  type: 'text',
  placeholder: '',
  onChange: () => {},
  onValidate: () => {},
  rules: [],
};

export default ValidateInput;
