import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bool, shape, func } from 'prop-types';
import { Button, Form, Icon, Input } from 'antd';
import { withTranslation } from 'react-i18next';

// Utils
import useValidation from '../utils/useValidation';
import { mustBeEmail, mustBeFilled } from '../../../config/validationRules';

// Components
import FormCaptcha from '../components/FormCaptcha';

const initialForm = {
  name: { value: '', rules: [mustBeFilled] },
  email: { value: '', rules: [mustBeFilled, mustBeEmail] },
  message: { value: '', rules: [mustBeFilled] },
  gRecaptchaResponse: {
    value: null,
    rules: [mustBeFilled],
  },
};

const FeedbackForm = ({ formData, onSubmit, onCancel, isSaving, t }) => {
  const [isServerError, setIsServerError] = useState(null);

  const handleSubmit = currentForm =>
    onSubmit(currentForm)
      .then(() => setIsServerError(false))
      .then(onCancel)
      .catch(() => setIsServerError(true));

  const {
    errors,
    currentForm,
    bindedSubmit,
    bindedInputFunctions,
  } = useValidation(handleSubmit, initialForm, formData);

  return (
    <Form>
      <Form.Item {...errors.name} label="">
        <Input
          prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
          name="name"
          placeholder={t('basic.name')}
          autoComplete="off"
          size="large"
          defaultValue={initialForm.name.value}
          {...bindedInputFunctions}
        />
      </Form.Item>

      <Form.Item {...errors.email} label="">
        <Input
          prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
          name="email"
          placeholder={t('basic.email')}
          autoComplete="off"
          size="large"
          defaultValue={initialForm.email.value}
          {...bindedInputFunctions}
        />
      </Form.Item>

      <Form.Item {...errors.message} label="">
        <Input.TextArea
          name="message"
          placeholder={`${t('basic.message')}..`}
          defaultValue={currentForm.message}
          autoSize={{ minRows: 5 }}
          size="large"
          {...bindedInputFunctions}
        />
      </Form.Item>

      <Form.Item {...errors.gRecaptchaResponse} style={{ minHeight: '78px' }}>
        <FormCaptcha
          name="gRecaptchaResponse"
          isServerError={isServerError}
          {...bindedInputFunctions}
        />
      </Form.Item>

      <Button
        loading={isSaving}
        size="large"
        type="primary"
        onClick={bindedSubmit}
        style={{ width: '100%' }}
      >
        {t('form.buttonSend')}
      </Button>
    </Form>
  );
};

FeedbackForm.propTypes = {
  formData: shape({}),
  onSubmit: func,
  onCancel: func,
  isSaving: bool,
  t: func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(FeedbackForm));
