import { snakeToCamelCase } from '../utils';

import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_FILTER_FIXED,
  LOCAL_STORAGE_HIDDEN_CHARTS,
  LOCAL_STORAGE_IS_SIDEBAR_HIDDEN,
  LOCAL_STORAGE_LOGIN_REDIRECT,
  LOCAL_STORAGE_REFRESH_TOKEN,
  LOCAL_STORAGE_SHOWN_POPUPS,
  LOCAL_STORAGE_SIDEBAR_THEME,
  LOCAL_STORAGE_TABLE_SIZE,
  LOCAL_STORAGE_USER,
} from '../constants/localStorage';

// label: ACCESS TOKEN
export const getAccessToken = () =>
  localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) || '';
export const setAccessToken = token =>
  localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, token);
export const removeAccessToken = () =>
  localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);

// label: REFRESH TOKEN
export const getRefreshToken = () =>
  localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN) || '';
export const setRefreshToken = token =>
  localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, token);
export const removeRefreshToken = () =>
  localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);

// label: USER
export const setUser = user =>
  localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));
export const getUser = () => {
  let result = '';
  try {
    result = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER)) || {};
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return result;
};
export const logOutUser = () => localStorage.removeItem(LOCAL_STORAGE_USER);

// label: LOGIN
export const setLoginRedirect = value =>
  localStorage.setItem(LOCAL_STORAGE_LOGIN_REDIRECT, JSON.stringify(value));
export const removeLoginRedirect = () =>
  localStorage.removeItem(LOCAL_STORAGE_LOGIN_REDIRECT);
export const getLoginRedirect = () => {
  let result = '';
  try {
    result = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LOGIN_REDIRECT));
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return result;
};

// label: SIDEBAR
export const setSidebarVisibility = value =>
  localStorage.setItem(LOCAL_STORAGE_IS_SIDEBAR_HIDDEN, JSON.stringify(value));
export const getSidebarVisibility = () => {
  return new Promise(resolve => {
    let result;
    try {
      result = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_IS_SIDEBAR_HIDDEN),
      );
      // eslint-disable-next-line no-empty
    } catch (e) {}
    resolve(result);
  });
};

// label: SIDEBAR THEME
export const setSidebarTheme = value =>
  localStorage.setItem(LOCAL_STORAGE_SIDEBAR_THEME, JSON.stringify(value));
export const getSidebarTheme = () => {
  let result;
  try {
    result = JSON.parse(localStorage.getItem(LOCAL_STORAGE_SIDEBAR_THEME));
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return result;
};

// label: HIDE CHARTS
export const setChartVisibility = value =>
  localStorage.setItem(LOCAL_STORAGE_HIDDEN_CHARTS, JSON.stringify(value));
export const getChartVisibility = () => {
  let result;
  try {
    result = JSON.parse(localStorage.getItem(LOCAL_STORAGE_HIDDEN_CHARTS));
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return result;
};

// label: SHOWN POPUPS
export const setShownPopups = value =>
  localStorage.setItem(LOCAL_STORAGE_SHOWN_POPUPS, JSON.stringify(value));
export const getShownPopups = () => {
  let result = '';
  try {
    result = JSON.parse(localStorage.getItem(LOCAL_STORAGE_SHOWN_POPUPS));
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return result;
};

// label: FIXED FILTER
export const setFilterFixed = value =>
  localStorage.setItem(LOCAL_STORAGE_FILTER_FIXED, JSON.stringify(value));
export const getFilterFixed = () => {
  let result = true;
  try {
    result = JSON.parse(localStorage.getItem(LOCAL_STORAGE_FILTER_FIXED));
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return result;
};

// label: SAVE TOKENS AND USER
export const saveDataToLocalStorage = data => {
  const { accessToken, refreshToken, name, role } = snakeToCamelCase(data);

  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
  setUser({ name, role });
};

// label: SAVE TABLE SIZE
export const getTableSize = () => {
  let tableSize = {};
  const defaultValue = '';
  try {
    const storage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TABLE_SIZE));
    if (storage === null) return defaultValue;

    tableSize = storage[window.location.pathname] || defaultValue;
  } catch (e) {} // eslint-disable-line no-empty
  return tableSize;
};
export const setTableSize = value =>
  localStorage.setItem(LOCAL_STORAGE_TABLE_SIZE, JSON.stringify(value));
