import React from 'react';
import { arrayOf, exact } from 'prop-types';
import { FaImage } from 'react-icons/fa';

// Types
import {
  iInformerSiteNews,
  iInformerStyle,
} from '../../../prop-types/informer';

const SettingsGalleryItem = ({ informerStyle, news = [] }) => {
  const { general, image, font, block } = informerStyle;
  const generalStyle = {
    display: 'flex',
    boxSizing: 'borderBox',
    justifyContent: block.justifyContent,
    marginBottom: `${general.marginBottom}px`,
    paddingLeft: `${general.paddingHorizontal}px`,
    paddingRight: `${general.paddingHorizontal}px`,
    width: `${100 / general.countCalls}%`,
  };
  const blockStyle = {
    display: 'flex',
    flexBasis: '100%',
    position: block.position,
    flexWrap: block.viewType === 'vertical' ? 'wrap' : 'nowrap',
    boxSizing: 'border-box',
    maxWidth: block.maxWidth,
    textAlign: 'left',
    overflow: 'hidden',
    textDecoration: 'none',
    background: block.backgroundColor,
    cursor: 'pointer',
    border: `${block.widthBorder}px ${block.borderType} ${block.color}`,
    borderRadius: `${block.radius}px`,
    padding: `${block.paddingTop}px  ${block.paddingRight}px ${block.paddingBottom}px ${block.paddingLeft}px`,
    margin: `${block.marginTop}px  ${block.marginRight}px ${block.marginBottom}px ${block.marginLeft}px`,
  };
  const imageStyle = {
    flexBasis: block.viewType === 'vertical' ? '100%' : `${image.width}px`,
    height: `${image.height}px`,
    border: `${image.widthBorder}px ${image.borderType} ${image.color}`,
    borderRadius: `${image.radius}px`,
  };
  const fontStyle = {
    position: font.position,
    bottom: font.bottom,
    left: font.left,
    display: 'block',
    fontWeight: `${font.fontWeight}`,
    fontSize: `${font.size}px`,
    lineHeight: `${font.height}px`,
    color: `${font.color}`,
    padding: `${font.paddingTop}px ${font.paddingRight}px ${font.paddingBottom}px ${font.paddingLeft}px`,
    margin: `${font.marginTop}px ${font.marginRight}px ${font.marginBottom}px ${font.marginLeft}px`,
    ...(font.name !== 'Default' && { fontFamily: `${font.name}` }),
    ...(font.textAlign !== 'left' && { textAlign: `${font.textAlign}` }),
    ...(font.position === 'absolute' && {
      background:
        'transparent linear-gradient(180deg,transparent 0,rgba(0,0,0,.7)) repeat scroll 0 0',
    }),
  };

  return news.map(({ img, title, news_id: id }) => (
    <div key={`${id}gallery`} className="gallery__col" style={generalStyle}>
      <div className="gallery__block" style={blockStyle}>
        <div className="gallery__preview" style={imageStyle}>
          <FaImage color="rgba(0,0,0,0.1)" size="45" />
          <div
            className="gallery__preview-image"
            style={{ backgroundImage: `url(${img})` }}
          />
        </div>
        <p className="gallery__font" style={fontStyle}>
          {title}
        </p>
      </div>
    </div>
  ));
};

SettingsGalleryItem.propTypes = {
  informerStyle: exact(iInformerStyle),
  news: arrayOf(exact(iInformerSiteNews)),
};

export default SettingsGalleryItem;
