import {
  colorBlue,
  colorCyan,
  colorGreen,
  colorGrey,
  colorRed,
  colorYellow,
} from '../styles/js/variables/colors';

const statuses = {
  STATUS_VERIFICATION: 'verification',
  STATUS_ANALYTICS: 'analytics',
  STATUS_ANALYTICS_SPEEDUP: 'analytics_speedup',
  STATUS_SUSPENDED: 'suspended',
  STATUS_PENDING: 'pending',
  STATUS_DELETED: 'deleted',
  STATUS_ACTIVE: 'active',
};

export const statusesTypes = Object.keys(statuses).reduce((acc, cur) => {
  acc[cur] = cur;
  return acc;
}, {});

export const {
  STATUS_ACTIVE,
  STATUS_ANALYTICS,
  STATUS_ANALYTICS_SPEEDUP,
  STATUS_DELETED,
  STATUS_PENDING,
  STATUS_SUSPENDED,
  STATUS_VERIFICATION,
} = statuses;

export const statusesStyles = {
  [STATUS_VERIFICATION]: {
    color: colorYellow,
    theme: 'outlined',
    icon: 'file-search',
  },
  [STATUS_ANALYTICS]: {
    color: colorCyan,
    theme: 'outlined',
    icon: 'bar-chart',
  },
  [STATUS_ANALYTICS_SPEEDUP]: {
    color: colorCyan,
    theme: 'outlined',
    icon: 'bar-chart',
  },
  [STATUS_PENDING]: {
    color: colorBlue,
    icon: 'security-scan',
  },
  [STATUS_SUSPENDED]: {
    color: colorRed,
    theme: 'outlined',
    icon: 'stop',
  },
  [STATUS_ACTIVE]: {
    color: colorGreen,
    icon: 'safety-certificate',
  },
  [STATUS_DELETED]: {
    color: colorGrey,
    icon: 'delete',
  },
};

export default statuses;
