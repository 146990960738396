import React from 'react';
import { Icon, Tooltip } from 'antd';

export default props => {
  const { tooltip } = props;

  return (
    <Tooltip title={tooltip}>
      <Icon type="info-circle" className="tooltip" theme="filled" />
    </Tooltip>
  );
};
