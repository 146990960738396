const defaultForAllDevices = {
  general: {
    triggerMobile: '768',
    marginBottom: '10',
    paddingHorizontal: '5',
  },
  block: {
    position: 'relative',
    paddingTop: '0',
    paddingBottom: '0',
    paddingRight: '0',
    paddingLeft: '0',
    marginTop: '10',
    marginBottom: '0',
    marginRight: '0',
    marginLeft: '0',
    borderType: 'solid',
    radius: '0',
    widthBorder: '0',
    backgroundColor: '#fff',
    justifyContent: 'flex-start',
    flexBasis: '100%',
  },
  font: {
    paddingTop: '10',
    paddingBottom: '0',
    paddingRight: '0',
    paddingLeft: '0',
    marginTop: '10',
    marginBottom: '10',
    marginRight: '10',
    marginLeft: '10',
    name: 'Default',
    textAlign: 'left',
    fontWeight: 'normal',
    size: '16',
    height: '18',
    color: 'black',
    position: 'relative',
    bottom: '0',
    left: '0',
  },
  image: {
    widthBorder: '0',
    zoomOnHover: true,
  },
  title: {
    tag: 'h2',
    fontName: 'Default',
    color: '#000000',
    textAlign: 'left',
    size: '18',
    height: '18',
    paddingTop: '0',
    paddingBottom: '0',
    paddingRight: '0',
    paddingLeft: '0',
  },
};

export const informerTemplates = {
  // label: BASIC
  default: {
    desktop: {
      general: {
        ...defaultForAllDevices.general,
        countCalls: '3',
        countRows: '2',
      },

      block: {
        ...defaultForAllDevices.block,
        color: '#fff',
        viewType: 'vertical',
      },

      image: {
        ...defaultForAllDevices.image,
        borderType: 'dashed',
        color: '#000',
        radius: '0',
        height: '150',
      },

      font: {
        ...defaultForAllDevices.font,
      },

      title: {
        ...defaultForAllDevices.title,
        name: '',
      },
    },

    mobile: {
      general: {
        // name: 'МОБИЛЬНЫЙ ЗАГОЛОВОК',
        ...defaultForAllDevices.general,
        countCalls: '1',
        countRows: '6',
        transition: 'all .2s ease-out',
      },

      block: {
        ...defaultForAllDevices.block,
        maxWidth: '100%',
        color: '#000',
        viewType: 'horizontal',
      },

      image: {
        ...defaultForAllDevices.image,
        radius: '5',
        borderType: 'solid',
        width: '100',
        height: '100',
        color: 'red',
      },

      font: {
        ...defaultForAllDevices.font,
        paddingLeft: '10',
      },

      title: {
        ...defaultForAllDevices.title,
        name: '',
      },
    },
    settings: {
      rubric_ratio: 0.1,
      rubric_period: 'one_day',
      mix_period: 'one_day',
      rubric_self: 'disabled',
      mix_self: 'disabled',
      rotator_last_self: 'disabled',
      rotator_main_self: 'disabled',
      rotator_rubric_self: 'disabled',
      rotator_last_period: 'one_day',
      rotator_main_period: 'one_day',
      rotator_rubric_period: 'one_day',
    },
  },

  // label: BEST
  best: {
    desktop: {
      block: {
        marginTop: '10',
        radius: '7',
      },
      font: {
        position: 'absolute',
        paddingTop: '24',
        paddingRight: '24',
        paddingBottom: '24',
        paddingLeft: '24',
        marginTop: '0',
        marginBottom: '0',
        marginRight: '0',
        marginLeft: '0',
        fontWeight: 'bold',
        size: '20',
        height: '24',
        color: '#fff',
      },
      general: {
        countCalls: '2',
        countRows: '3',
        triggerMobile: '768',
        paddingHorizontal: '5',
        marginBottom: '0',
      },
      image: {
        height: '300',
        widthBorder: '0',
        zoomOnHover: true,
        borderType: 'dashed',
        radius: '0',
        color: '#000',
      },
    },

    mobile: {
      block: {
        marginTop: '10',
        radius: '7',
        maxWidth: '320px',
        justifyContent: 'center',
      },
      font: {
        position: 'absolute',
        paddingTop: '24',
        paddingRight: '24',
        paddingBottom: '24',
        paddingLeft: '24',
        fontWeight: 'bold',
        size: '20',
        height: '24',
        color: '#fff',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
      },
      general: {
        countCalls: '1',
        countRows: '3',
        triggerMobile: '768',
        paddingHorizontal: '5',
        marginBottom: '0',
      },
      image: {
        height: '300',
        width: '320',
        widthBorder: '0',
        zoomOnHover: true,
        borderType: 'dashed',
        radius: '0',
        color: '#000',
      },
    },
  },
};
