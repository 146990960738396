import { func, string } from 'prop-types';
import { withTranslation } from 'react-i18next';

const WithTranslation = ({ t, type }) => t(type);

WithTranslation.propTypes = {
  t: func.isRequired,
  type: string.isRequired,
};

export default withTranslation('common')(WithTranslation);
