const routesPath = {
  HOME: '/',
  LOGIN: '/login',
  ERROR_404: '/error-404',
  TERMS: '/terms-of-conditions',

  // Dynamic
  SITE: '/:siteId',
  SITE_STATISTICS: '/:siteId/statistics',
  INFORMER_ADD: '/:siteId/informers-list/informer_add',
  INFORMERS_LIST: '/:siteId/informers-list',
  INFORMER: '/:siteId/informers-list/:informerId',
  INFORMER_EDIT: '/:siteId/informers-list/:informerId/edit',
  INFORMER_BY_DATE: '/:siteId/informers-list/:informerId/:date',
  INFORMER_NEWS: '/:siteId/informers-list/:informerId/news/:newsId',
};

export const types = Object.keys(routesPath).reduce((acc, cur) => {
  acc[cur] = cur;
  return acc;
}, {});

export const generatePath = ({ path, params }) =>
  Object.entries(params).reduce(
    (acc, [key, value]) => acc.replace(`:${key}`, value),
    path,
  );

export const {
  HOME,
  LOGIN,
  TERMS,
  SITE,
  SITE_STATISTICS,
  INFORMER_ADD,
  INFORMER,
  INFORMERS_LIST,
  INFORMER_EDIT,
  INFORMER_BY_DATE,
  INFORMER_NEWS,
  ERROR_404,
} = routesPath;

export default routesPath;
