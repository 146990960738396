import { get } from 'lodash';

import { ERROR_404 } from '../../config/routePaths';

const errorsMiddleware = () => next => async action => {
  const reqStatus = await get(action, 'payload.response.status', true);

  if (reqStatus === 404) {
    document.location.replace(ERROR_404);
  }

  return next(action);
};

export default errorsMiddleware;
