import { sendFeedbackApi } from '../../api';

import {
  FAILURE_FETCH_FEEDBACK,
  START_FETCH_FEEDBACK,
  SUCCESS_FETCH_FEEDBACK,
} from '../types';

export const sendFeedbackAction = params => dispatch => {
  dispatch({
    type: START_FETCH_FEEDBACK,
  });

  return sendFeedbackApi(params)
    .then(() =>
      dispatch({
        type: SUCCESS_FETCH_FEEDBACK,
        notification: {
          type: 'success',
          text: 'Sended',
        },
      }),
    )
    .catch(() =>
      dispatch({
        type: FAILURE_FETCH_FEEDBACK,
        notification: {
          type: 'error',
          text: 'Sending failed',
        },
      }),
    );
};
