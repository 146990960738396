import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { withTranslation } from 'react-i18next';

// Redux
import { connect } from 'react-redux';
import {
  fetchSite,
  fetchStatisticsChart,
  fetchStatisticsTable,
} from 'redux/actions/site';
import { resetInformerStylesLocal } from 'redux/actions/informerSettings';

// Utils
import { getDaysAgoRange, getDaysRange, datesArrayToUnix } from 'utils/date';

// Components
import LinearChart from '../../components/LinearChart';
import Table from '../../components/Table';
import { siteStatisticsHeader } from '../../components/Table/config/tables';
import PageTitle from '../../components/PageTitle';
import LinkWithTag from '../../components/LinkWithTag';
import { getTableSize } from '../../services/localStorage';
import routePaths, { generatePath } from '../../config/routePaths';

class SiteStatistics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarDates: getDaysAgoRange(),
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { siteId },
      },
    } = this.props;

    this.props.fetchSite(siteId);
    this.handleUpdateAll(datesArrayToUnix(getDaysAgoRange()));
  }

  handleUpdateAll = dates => {
    const { props } = this;
    const { table } = props;
    const {
      match: {
        params: { siteId },
      },
    } = props;
    const { limit, offset } = table;

    this.props.fetchChart({
      siteId,
      start: +dates[0],
      end: +dates[1],
    });

    this.props.fetchTable({
      siteId,
      limit: getTableSize() || limit,
      offset: dates ? 0 : offset,
      start: +dates[0],
      end: +dates[1],
    });

    this.setState({
      calendarDates: getDaysRange(dates),
    });
  };

  handlePagination = async ({
    currentPage,
    sortBy,
    sortDirection: { api: sortDirection },
    limit,
  }) => {
    const { props } = this;
    const { table } = props;
    const {
      match: {
        params: { siteId },
      },
    } = props;
    const { start, end } = table;
    const offset = (currentPage - 1) * limit;

    props.fetchTable({
      siteId,
      limit: getTableSize() || limit,
      offset,
      start,
      end,
      sortBy,
      sortDirection,
      // ...filters,
    });
  };

  render() {
    const { props, state } = this;
    const {
      info,
      isTableLoading,
      isChartLoading,
      chart,
      table,
      match,
      t,
    } = props;
    const { limit, total, data } = table;
    const { name } = info;
    const linkToInformersList = generatePath({
      path: routePaths.INFORMERS_LIST,
      params: match.params,
    });

    return (
      <div className="page-body main-page">
        {name && (
          <PageTitle
            title={t('content.title.siteStatistics')}
            hint={t('content.titleHint.siteStatistics')}
            button={
              <LinkWithTag
                isButton
                href={linkToInformersList}
                pageType={routePaths.SITE_STATISTICS}
                linkProps={{
                  type: 'default',
                }}
              >
                <Icon type="unordered-list" />
                {t('form.buttonInformersList')}
              </LinkWithTag>
            }
          />
        )}

        <LinearChart
          isLoading={isChartLoading}
          categoriesForX={state.calendarDates}
          data={chart}
          onChange={this.handleUpdateAll}
        />
        <Table
          headers={siteStatisticsHeader}
          isLoading={isTableLoading}
          rowKey={record => record.date}
          data={data}
          total={total}
          limit={limit}
          fetch={this.handlePagination}
        />
      </div>
    );
  }
}

SiteStatistics.defaultProps = {
  fetchChart: () => {},
  fetchTable: () => {},
};

SiteStatistics.propTypes = {
  fetchSite: PropTypes.func,
  fetchChart: PropTypes.func,
  fetchTable: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({}).isRequired,
    url: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    info: state.site.info,
    chart: state.site.statisticsChart,
    table: state.site.statisticsTable,
    isChartLoading: state.site.statisticsChart.isLoading,
    isTableLoading: state.site.statisticsTable.isLoading,
  };
}

const mapDispatchToProps = {
  fetchSite,
  fetchChart: fetchStatisticsChart,
  fetchTable: fetchStatisticsTable,
  resetInformerStylesLocal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(SiteStatistics));
