import React, { useCallback, useEffect, useRef, useState } from 'react';
import { bool, func } from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import i18next from 'i18next';

// Components
import Loader from '../../Loader';

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const FormCaptcha = ({ defaultValue, isServerError, ...props }) => {
  const { onChange, name } = props;
  const [recaptchaKey, setRecaptchaKey] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const inputRef = useRef();
  const recaptchaRef = useRef();

  const handleChange = key => setRecaptchaKey(key);
  const handleExpired = () => setRecaptchaKey('');

  const saveChanged = useCallback(() => {
    if (recaptchaKey === null) return;

    onChange({ target: inputRef.current });
    setIsLoading(false);
  }, [onChange, inputRef, recaptchaKey]);

  const onServerError = () => {
    if (isServerError === null) return;

    recaptchaRef.current.reset();
    setRecaptchaKey('');
  };

  useEffect(saveChanged, [recaptchaKey]);
  useEffect(onServerError, [isServerError]);

  return (
    <>
      <Loader
        size="small"
        isLoading={isLoading}
        style={{ margin: '-1em 0px 0px 2em' }}
      />
      <div style={{ position: 'relative', zIndex: 1 }}>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={RECAPTCHA_KEY}
          onChange={handleChange}
          onExpired={handleExpired}
          size="normal"
          hl={i18next.language}
        />
      </div>
      <input
        ref={inputRef}
        type="text"
        name={name}
        value={recaptchaKey === null ? '' : recaptchaKey}
        onChange={() => console.error('wrong captcha event')}
        style={{ display: 'none' }}
      />
    </>
  );
};

FormCaptcha.propTypes = {
  onChange: func,
  defaultChecked: bool,
};

export default FormCaptcha;
