import React, { Fragment } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// Components
import SupportForm from '../../Form/forms/FeedbackForm';
import { sendFeedbackAction } from '../../../redux/actions/feedback';
// import SupportHistory, {historyData} from '../../Support/SupportHistory';

const FeedbackModal = props => {
  const { onSubmit, onClose, t } = props;

  return (
    <Fragment>
      <h2>{t('blocks.feedback.title')}</h2>
      <p>{t('blocks.feedback.subtitle')}</p>
      {/* <SupportHistory historyData={historyData} /> */}
      <SupportForm onSubmit={onSubmit} onCancel={onClose} />
    </Fragment>
  );
};

FeedbackModal.propTypes = {
  t: func,
};

FeedbackModal.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  onSubmit: sendFeedbackAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(FeedbackModal));
