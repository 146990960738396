export function scriptCompilator(siteId, siteDomain, isNotArmed) {
  const hash = (siteDomain || window.location.hostname).replaceAll('.', '');
  const domain = process.env.REACT_APP_DOMAIN_NAME || 'top.my.ua';
  const s = document.createElement('script');
  const action = () => !isNotArmed && document.body.appendChild(s);

  s.dataset.topSid = siteId;
  s.src = `https://${hash}.top-js-metrics.${domain}/${hash}/script.${hash}.js`;
  s.type = 'text/javascript';
  s.async = true;

  if (
    document.readyState === 'complete' ||
    document.readyState === 'interactive'
  ) {
    setTimeout(action, 1);
  } else {
    document.addEventListener('DOMContentLoaded', action);
  }

  return s.outerHTML;
}

export const getScriptCompilatorCode = (siteId, domain = '') => {
  const scriptString = scriptCompilator.toString();

  // TODO: needs refactoring
  // const replacements = {
  //   '\\w\\s?\\n?\\s{1,}?\\}\\)\\).replace\\(\\/\\=\\+\\$\\/': `${siteId}})).replace(/=+$/`,
  // };

  // const cleanedScript = scriptString.replace(
  //   new RegExp(`${Object.keys(replacements).join('|')}`, 'g'),
  //   match => replacements[match] || '',
  // );

  const cleanedScript = scriptString
    .replace(/^\s?function\s\w{1,}\(\w{1,},\s?\w{1,}\)\s?{/, '')
    .replace(/}$/, '')
    .replace('siteId', siteId)
    .replace(/.topSid\s?=\s?\w{1,}/, `.topSid=${siteId}`)
    .replace(/\s?site:\s?\w{1,}\s?/, `site: ${siteId}`)
    .replace(/\w{1,}\s?\|\|\s?window\.loc/, `'${domain}'||window.loc`)
    .replace(
      /!\w{1,}\s?&&\s?document\.body\.appendChild/,
      'document.body.appendChild',
    )
    .replaceAll('return', '')
    .replace(/,?\w{1,}\.outerHTML/, '');

  return `<script>${cleanedScript}</script>`;
};
