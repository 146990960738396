import React from 'react';
import { withTranslation } from 'react-i18next';
import { Empty } from 'antd';

const EmptyData = ({ title, style, t }) => {
  return (
    <Empty
      description={title || t('basic.emptyData')}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      style={style}
    />
  );
};

export default withTranslation('')(EmptyData);
