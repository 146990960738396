import React from 'react';
import { func } from 'prop-types';
import { Button, Result } from 'antd';
import { withTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';

// Utils
import { addLineBreaks } from '../../../utils/string';
import { colorGreen } from '../../../styles/js/variables/colors';

const SuccessAddPublisherModal = ({ t, onSuccess, onClose }) => {
  const onStart = () => {
    onSuccess();
    onClose();
  };

  return (
    <Result
      status="success"
      title={t('tooltip.successAddPublisherTitle')}
      subTitle={addLineBreaks(t('tooltip.successAddPublisherDescription'))}
      icon={<FaCheckCircle size={50} color={colorGreen} />}
      extra={[
        <Button type="primary" onClick={onStart} key="success" size="large">
          {t('form.buttonStart')}
        </Button>,
      ]}
    />
  );
};

SuccessAddPublisherModal.propTypes = {
  t: func,
  onClose: func,
  onSuccess: func,
};

export default withTranslation()(SuccessAddPublisherModal);
