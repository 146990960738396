import React from 'react';
import { Row, Col } from 'antd';

const Grid = ({ columns, children, rowProps, colProps }) => {
  const basicSize = 24;
  const lgSize = columns ? Math.ceil(basicSize / columns) : 2;

  return (
    <Row type="flex" gutter={[24, 24]} {...rowProps}>
      {Array.isArray(children) ? (
        children.map((el, i) => (
          <Col key={i} xs={24} lg={lgSize} {...colProps}>
            {el}
          </Col>
        ))
      ) : (
        <Col md={24} lg={lgSize} {...colProps}>
          {children}
        </Col>
      )}
    </Row>
  );
};

export default Grid;
