import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'antd';
import { withTranslation } from 'react-i18next';

// Redux
import {
  fetchTable,
  addSite,
  deleteSite,
  editSite,
  fetchChart,
} from 'redux/actions/sites';
import { showModal } from 'redux/actions/modals';

// Utils
import { getDaysRange, getDaysAgoRange, datesArrayToUnix } from 'utils/date';

// Components
import Table from 'components/Table';
import LinearChart from 'components/LinearChart';
import { sitesHeader } from '../../components/Table/config/tables';
import PageTitle from '../../components/PageTitle';
import WithTooltip from '../../HOC/WithTooltip';
import { colorYellow } from '../../styles/js/variables/colors';
import { getShownPopups, setShownPopups } from '../../services/localStorage';
import routesPath, { generatePath } from '../../config/routePaths';

class Sites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarDates: getDaysAgoRange(-7),
    };
  }

  componentDidMount() {
    this.handleUpdateAll(datesArrayToUnix(getDaysAgoRange(-7))).then(
      this.congratsIfNewUser,
    );
  }

  handleUpdateAll = dates => {
    const { props } = this;
    // const { limit, offset } = props.table;

    this.setState({
      calendarDates: getDaysRange(dates),
    });

    props.fetchChart({
      start: +dates[0],
      end: +dates[1],
    });

    return props.fetchTable({
      // TODO: (TOP-1705) uncomment next lines after "total" field fix on BE
      // limit: getTableSize() || limit,
      // offset: dates ? 0 : offset,
      limit: 300,
      offset: 0,

      start: +dates[0],
      end: +dates[1],
    });
  };

  handlePagination = ({
    currentPage,
    sortBy,
    sortDirection: { api: sortDirection },
    filters,
    limit,
  }) => {
    const { props } = this;
    const { table } = props;
    const { start, end } = table;
    const offset = (currentPage - 1) * limit;

    props.fetchTable({ limit, offset, start, end, sortBy, sortDirection });
  };

  handleModalAddSite = () => {
    const { props } = this;

    props.showModal({
      type: 'MODAL_ADD_SITE',
      params: {
        actions: {
          onSubmit: props.addSite,
        },
        success: {
          onSubmit: () =>
            props.fetchTable({
              limit: props.table.limit,
              offset: props.table.offset,
              start: props.table.start,
              end: props.table.end,
            }),
        },
      },
    });
  };

  handleSite = ({ id }) =>
    generatePath({
      path: routesPath.SITE,
      params: { siteId: id },
    });

  handleDeleteSite = ({ id }) => {
    const { start, end } = this.props.table;

    this.props.deleteSite(id).then(() => this.handleUpdateAll([start, end]));
  };

  handleSiteStatistics = ({ id }) => `${this.props.match.url}${id}/statistics`;

  handleSiteInformers = ({ id }) =>
    generatePath({
      path: routesPath.INFORMERS_LIST,
      params: {
        siteId: id,
      },
    });

  handleShowTooltip = ({ id, ...otherProps }) => {
    this.props.showModal({
      type: 'MODAL_SITE_INTEGRATION',
      params: {
        history: this.props.history,
        siteId: id,
        ...otherProps,
      },
    });
  };

  congratsIfNewUser = ({ payload: table }) => {
    const shownPopups = getShownPopups();
    const { showModal: showSuccessModal, username } = this.props;
    const storageKey = `registrationSuccess-${username}`;
    const isEmptyTable =
      table.data && table.data.length === 0 && table.total === 0;

    // Showing congrats for new user after registration:
    if (isEmptyTable && !(shownPopups && shownPopups[storageKey])) {
      showSuccessModal({
        type: 'MODAL_SUCCESS_ADD_PUBLISHER',
        params: {
          onSuccess: () =>
            setShownPopups({
              ...shownPopups,
              [storageKey]: true,
            }),
          modalProps: {
            closable: false,
            maskClosable: false,
          },
        },
      });
    }

    return table;
  };

  render() {
    const { props, state } = this;
    const { isChartLoading, isTableLoading, chart, table, t } = props;
    const isEmptyTable = table.data.length === 0 && table.total === 0;

    return (
      <div className="page-body main-page">
        <PageTitle
          title={t('content.title.publisher')}
          hint={t('content.titleHint.publisher')}
          button={
            <WithTooltip
              withTimeout
              withTooltip={!isTableLoading && isEmptyTable}
              defaultVisible={isEmptyTable}
              placement="bottomRight"
              title={t('tooltip.emptyListAddItem', {
                entity: t('basic.sites_5').toLowerCase(),
              })}
              color={colorYellow}
            >
              <Button type="success" onClick={this.handleModalAddSite}>
                <Icon type="plus" />
                {`${t('basic.add')} ${t('basic.site')}`}
              </Button>
            </WithTooltip>
          }
        />

        <LinearChart
          isShowChart={!!table.data.length}
          isLoading={isChartLoading}
          categoriesForX={state.calendarDates}
          data={chart}
          onChange={this.handleUpdateAll}
        />
        <Table
          headers={sitesHeader}
          isLoading={isTableLoading}
          data={table.data}
          limit={table.limit}
          actions={{
            edit: this.handleSite,
            delete: this.handleDeleteSite,
            siteIntegration: this.handleShowTooltip,
            page: this.handleSiteStatistics,
            informersList: this.handleSiteInformers,
          }}
          type="sitesActions"
          // TODO: (TOP-1705) uncomment next lines after "total" field fix on BE
          // total={table.total}
          // fetch={this.handlePagination}
          isClientPagination
          total={table.data.length}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    chart: state.sites.chart,
    isChartLoading: state.sites.chart.isLoading,
    table: state.sites.table,
    isTableLoading: state.sites.table.isLoading,
    username: state.auth.user.name,
  };
}

const mapDispatchToProps = {
  showModal,
  fetchTable,
  fetchChart,
  addSite,
  editSite,
  deleteSite,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Sites));
