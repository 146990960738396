// Colors
export const colorBlue = '#2c9eb4';
export const colorBlueSemantic = '#4183c4';
export const colorGrey = '#aaaaa9';
export const colorGreyLight = '#eeeee9';
export const colorGreyDark = '#777779';
export const colorGreen = '#b5cc19';
export const colorGreenSemantic = '#22ba46';
export const colorRed = '#e44e31';
export const colorCyan = '#37cdbc';
export const colorYellow = '#f2be22';
export const colorWhite = '#ffffff';
