import React from 'react';

import styled from 'styled-components';

export const PickerWrapper = styled.div`
  .react-colorful__pointer {
    height: 20px;
    width: 20px;
  }
`;

export const PickerChildrenWrapper = styled(props => (
  <div {...props} ref={props.pickerRef} />
))`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: absolute;
  z-index: 5;
`;

export const PickerButton = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  height: calc(100% - 2px);
  border-radius: 3px;
  width: 34px;
  flex-basis: 30px;
  z-index: 2;
  border: none;
  cursor: pointer;
  transition: all 0.15s ease-out;
  flex-shrink: 0;
  outline: none;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 15px;
    height: 15px;
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
    fill: #fff;
  }
`;
