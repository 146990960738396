import React from 'react';
import PropTypes from 'prop-types';

import scrollIt from 'utils/scrollIt';

const initialState = {
  inputs: [],
};

const ValidatorContext = React.createContext(initialState);

const UPDATE_INPUTS = 'UPDATE_INPUTS';
const FIND_ERROR_INPUT = 'FIND_ERROR_INPUT';

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_INPUTS: {
      const { name } = action.payload;
      const inputIndex = state.inputs.findIndex(input => {
        return input.name === name;
      });
      const nextInputs =
        inputIndex >= 0
          ? [
              ...state.inputs.slice(0, inputIndex),
              ...state.inputs.slice(inputIndex + 1, state.inputs.length),
              action.payload,
            ]
          : [...state.inputs, action.payload];

      return { ...state, inputs: nextInputs };
    }
    // find error input and scroll window, if not find call callback
    case FIND_ERROR_INPUT: {
      state.inputs.forEach(input => input.trigger());
      const errorInput = state.inputs.find(input => {
        return input.isError;
      });

      if (errorInput && errorInput.el) {
        scrollIt(errorInput.el, 300, 'linear', () => errorInput.el.focus());
      } else {
        action.payload.callback();
      }

      return state;
    }
    default:
      return state;
  }
};

// Validator ContextOneProvider
function ValidatorCOP(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const actions = {
    validate: input =>
      dispatch({
        type: UPDATE_INPUTS,
        payload: input,
      }),
    findErrorInput: callback =>
      dispatch({
        type: FIND_ERROR_INPUT,
        payload: {
          callback,
        },
      }),
  };

  const value = { state, dispatch, actions };

  return (
    <ValidatorContext.Provider value={value}>
      {props.children}
    </ValidatorContext.Provider>
  );
}

ValidatorCOP.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})])
    .isRequired,
};

const ValidatorConsumer = ValidatorContext.Consumer;

export { ValidatorContext, ValidatorCOP, ValidatorConsumer };
