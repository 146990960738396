import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Icon, Popover } from 'antd';
import { bool, func } from 'prop-types';

import HeaderMenu from './components/HeaderMenu';
import Logo from './components/Logo';

import { showModal } from '../../redux/actions/modals';

const Header = props => {
  const { isAuthorized, logout, user, isMobile, t } = props;
  // const isAuth = !!Object.keys(user).length;
  const isDefinedUser = !!(isAuthorized && user.name);

  return (
    <div className="header">
      <Logo />

      <div className="header-right">
        {/* TODO: enable it after creation of scripts types detect */}
        {/* {isAuth && (
          <ScriptChangeWarning
            user={user}
            showModalWarning={props.showModalWarning}
          />
        )} */}

        <Popover
          trigger="click"
          placement="topRight"
          content={<HeaderMenu logout={logout} isDefinedUser={isDefinedUser} />}
        >
          <div className="user-panel">
            <div className="user-panel__image">
              <Icon type={isDefinedUser ? 'user' : 'setting'} />
            </div>

            {!isMobile && (
              <div className="user-panel__details">
                <div className="user-panel__text">
                  {isDefinedUser ? user.name : t('basic.settings')}
                </div>
                {isDefinedUser && (
                  <div className="user-panel__role">
                    {t(`header.${user.role}`)}
                  </div>
                )}
              </div>
            )}
          </div>
        </Popover>
      </div>
    </div>
  );
};

Header.propTypes = {
  isAuthorized: bool.isRequired,
  logout: func.isRequired,
};

const mapStateToProps = ({ auth, userSettings }) => ({
  user: auth.user,
  isMobile: userSettings.isMobile,
});

const mapDispatchToProps = {
  showModalWarning: showModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(Header));
