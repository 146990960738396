import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'antd';

// Redux
import { hideModal } from '../../redux/actions/modals';

// Styles
import './styles/styles.scss';

// Components
import AddSiteModal from './components/AddSiteModal';
import ConfirmModal from './components/ConfirmModal';
import SiteIntegrationModal from './components/SiteIntegrationModal';
import CodeBlockModal from './components/CodeBlockModal';
import SuccessAddPublisherModal from './components/SuccessAddPublisherModal';
import FeedbackModal from './components/FeedbackModal';
import NewDomainWarning from './components/NewDomainWarning';

const ModalContainer = ({ modals, hideModalAction }) => {
  const { type, params, modalWidth } = modals;
  const modalComponents = {
    MODAL_CONFIRM: ConfirmModal,
    MODAL_SITE_INTEGRATION: SiteIntegrationModal,
    MODAL_ADD_SITE: AddSiteModal,
    MODAL_CODE_BLOCK: CodeBlockModal,
    MODAL_SUCCESS_ADD_PUBLISHER: SuccessAddPublisherModal,
    MODAL_NEW_DOMAIN_WARNING: NewDomainWarning,
    MODAL_FEEDBACK: FeedbackModal,
  };
  const ModalComponent = modalComponents[type];

  return (
    <Modal
      visible={!!type}
      onCancel={hideModalAction}
      width={modalWidth || 700}
      {...params.modalProps}
    >
      {ModalComponent ? (
        <ModalComponent onClose={hideModalAction} {...params} />
      ) : null}
    </Modal>
  );
};

const mapStateToProps = state => ({
  modals: state.modals,
});

const mapDispatchToProps = dispatch => ({
  hideModalAction: bindActionCreators(hideModal, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalContainer);
