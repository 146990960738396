import React from 'react';
import { formatCell } from '../helpers/formatCell';

const WithTableFooter = ({
  children,
  actions,
  className,
  summaryKeys,
  summaryData,
}) => {
  const isFixedTable = className === 'ant-table-fixed';
  const isTableFooter = summaryKeys && summaryData && !isFixedTable;

  return (
    <table className="table-wrapper">
      {children}
      {isTableFooter && (
        <tfoot className="ant-table-tbody ant-table-footer">
          <tr className="ant-table-row ant-table-row-level-0 ant-table-footer-row">
            {summaryKeys.map(column => {
              return (
                <td key={`footer${column}`} className="table-footer-column">
                  {column === 'ko' ? (
                    // KO column format was declined:
                    <div className="table-cell--right">
                      {summaryData[column] && summaryData[column].toFixed(2)}
                    </div>
                  ) : (
                    formatCell(summaryData[column])
                  )}
                </td>
              );
            })}
            {actions && <td />}
          </tr>
        </tfoot>
      )}
    </table>
  );
};

export default WithTableFooter;
