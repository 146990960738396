import { HIDE_MODAL, SHOW_MODAL } from 'redux/types';

export const showModal = payload => ({
  type: SHOW_MODAL,
  payload,
});

export const hideModal = () => ({
  type: HIDE_MODAL,
});
