import { Row, Col } from 'antd';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import ValidateInput from 'components/ValidateInput';
import ValidateInputWithColorPicker from 'components/ValidateInputWithColorPicker';
import Dropdown from 'components/common/Dropdown';
import {
  mustBeFilled,
  mustBeHexColor,
  mustBeNumber,
  mustBeMore10,
  mustBeTagName,
} from 'config/validationRules';
import Label from 'components/Label';

import { ValidatorContext } from 'context/ValidatorContext';
import Grid from '../../Grid';
import DistanceSettings from '../components/DistanceSettings';
import { fontsVariants } from '../constants/fontsVariants';
import { textAligns } from '../constants/textStyles';

const TitleBlock = props => {
  const { element, onChange, t } = props;
  const { actions } = React.useContext(ValidatorContext);
  const title = element;

  return (
    <Fragment>
      <Row type="flex" gutter={[24, 24]}>
        <Col md={24} lg={18}>
          <Label labelText={t('basic.informerTitle')} />
          <ValidateInput
            name="name"
            validateName="title-block-name"
            defaultValue={title.name}
            value={title.name}
            placeholder="..."
            controlled
            onChange={value =>
              onChange({
                item: 'title',
                response: { name: 'name', value },
              })
            }
            onValidate={input => actions.validate(input)}
          />
        </Col>
        <Col md={24} lg={6}>
          <Label labelText={t('basic.tag')} asterisk />
          <ValidateInput
            name="tag"
            validateName="title-block-tag"
            labelText={t('basic.tagTitle')}
            defaultValue={title.tag}
            value={title.tag}
            controlled
            placeholder="h1"
            onChange={value =>
              onChange({
                item: 'title',
                response: { name: 'tag', value },
              })
            }
            rules={[mustBeTagName]}
            onValidate={input => actions.validate(input)}
          />
        </Col>
      </Row>
      <Grid columns={2}>
        <div>
          <Label labelText={t('basic.fontName')} asterisk />
          <Dropdown
            name="fontName"
            validateName="title-block-font"
            options={fontsVariants}
            selection
            value={title.fontName}
            onChange={value =>
              onChange({
                item: 'title',
                response: { name: 'fontName', value },
              })
            }
            rules={[mustBeFilled]}
          />
        </div>
        <div>
          <Label labelText={t('basic.textColor')} asterisk />
          <ValidateInputWithColorPicker
            name="color"
            validateName="title-block-color"
            defaultValue={title.color}
            value={title.color}
            controlled
            colorPicker={{
              onDrag: () => {},
              onComplete: value =>
                onChange({
                  item: 'title',
                  response: { name: 'color', value },
                }),
            }}
            inputValidation={{
              placeholder: '#000000',
              type: 'text',
              onChange: value => {
                onChange({
                  item: 'title',
                  response: { name: 'color', value },
                });
              },
              onValidate: input => actions.validate(input),
              rules: [mustBeFilled, mustBeHexColor],
            }}
          />
        </div>
        <div>
          <Label labelText={t('basic.fontWeight')} />
          <ValidateInput
            name="fontWeight"
            validateName="title-block-font-weight"
            labelText={t('basic.fontWeight')}
            defaultValue={title.fontWeight}
            value={title.fontWeight}
            controlled
            placeholder="bold или 800"
            onChange={value =>
              onChange({
                item: 'title',
                response: { name: 'fontWeight', value },
              })
            }
            onValidate={input => actions.validate(input)}
          />
        </div>
        <div>
          <Label labelText={t('basic.textAlign')} />
          <Dropdown
            name="textAlign"
            validateName="title-block-text-align"
            options={textAligns}
            selection
            value={title.textAlign}
            onChange={value =>
              onChange({
                item: 'title',
                response: { name: 'textAlign', value },
              })
            }
          />
        </div>

        <div>
          <Label labelText={`${t('basic.fontSize')} (px)`} asterisk />
          <ValidateInput
            name="size"
            validateName="title-block-size"
            labelText={t('basic.fontSize')}
            defaultValue={title.size}
            value={title.size}
            controlled
            placeholder="16"
            type="number"
            onChange={value =>
              onChange({
                item: 'title',
                response: { name: 'size', value },
              })
            }
            rules={[mustBeFilled, mustBeNumber, mustBeMore10]}
            onValidate={input => actions.validate(input)}
          />
        </div>
        <div>
          <Label labelText={`${t('basic.lineHeight')} (px)`} asterisk />
          <ValidateInput
            name="height"
            validateName="title-block-height"
            labelText={t('basic.lineHeight')}
            defaultValue={title.height}
            value={title.height}
            controlled
            placeholder="18"
            type="number"
            onChange={value =>
              onChange({
                item: 'title',
                response: { name: 'height', value },
              })
            }
            rules={[mustBeFilled, mustBeNumber, mustBeMore10]}
            onValidate={input => actions.validate(input)}
          />
        </div>
      </Grid>

      <DistanceSettings
        prefix="title-block"
        element={title}
        item="title"
        onChange={onChange}
      />
    </Fragment>
  );
};

TitleBlock.propTypes = {
  element: PropTypes.shape({}),
  onChange: PropTypes.func,
};

TitleBlock.defaultProps = {
  element: {},
  onChange: () => {},
};

export default withTranslation()(TitleBlock);
