// --------------------- WARNING MESSAGES ---------------------

export const ERROR_TEST_DATA = 'Отображены тестовые данные';
export const ERROR_TEST_DATA_RESPONSE = `${ERROR_TEST_DATA}. Связь с сервером еще не настроена`;
export const ERROR_NOT_RESPONDED =
  'Не удалось получить данные. Сервер не отвечает';
export const ERROR_NOT_SAVED = 'Не удалось сохранить';
export const ERROR_AUTH_WRONG_DATA = 'Введены неверные данные';
export const ERROR_WITH_SERVER_MESSAGE = 'Сообщение с сервера';

// --------------------- SUCCESS MESSAGES ---------------------

export const SUCCESS_SAVED = 'Сохранено на сервере';
export const SUCCESS_DELETED = 'Сохранено на сервере';

// ARTICLES
export const ARTICLE_CREATED = 'Статья создана';
export const ARTICLE_NOT_CREATED = 'Статья не была создана';

export const ARTICLE_SAVED = 'Статья сохранена';
export const ARTICLE_NOT_SAVED = 'Статья не сохранена';

export const ARTICLE_DELETED = 'Статья удалена';
export const ARTICLE_NOT_DELETED = 'Статья не была удалена';

export const ARTICLE_DUPLICATE = 'Статья по данной ссылке уже существует';
