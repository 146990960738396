import { Empty } from 'antd';

import styled from 'styled-components';

import { colorGrey } from '../../styles/js/variables/colors';

export const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ErrorImage = styled(Empty)`
  height: auto;
  margin: 0;

  > .ant-empty-image {
    height: auto;
  }

  svg {
    height: 100px;
    width: 100px;
  }
`;

export const ErrorTitle = styled.h1`
  font-weight: bold;
  display: block;
  font-size: 3em;
  line-height: 1.2;
  margin-bottom: 10px;
  margin-top: -10px;
  color: ${colorGrey};
`;

export const ErrorDescription = styled.div`
  font-size: 1.5em;
  line-height: 1.2;
  margin-bottom: 30px;
  color: ${colorGrey};
`;
