import { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

// Actions

const InitRequests = ({ user }) => {
  const handleRequests = useCallback(() => {
    const isAuth = !!Object.keys(user).length;

    if (!isAuth) return;

    // getSitesStatuses();
  }, [user]);

  useEffect(handleRequests, [user]);

  return null;
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InitRequests);
