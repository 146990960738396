import React from 'react';
import { shape } from 'prop-types';
import { withRouter } from 'react-router';

// Components
import logo from '../logo.svg';

const Logo = ({ history }) => {
  const handleHomePage = () => history.push('/');

  return (
    <div className="header-logo" onClick={handleHomePage} role="presentation">
      <img className="header-logo__svg" src={logo} alt="admin logo" />
      <div className="header-logo__title">
        <div>Admin</div>
        top
      </div>
    </div>
  );
};

Logo.propTypes = {
  history: shape({}),
};

export default withRouter(Logo);
