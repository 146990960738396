import React from 'react';
import { string, oneOfType, element } from 'prop-types';

const TableColumnTitle = ({ title }) => (
  <span className="ant-table-column-title-text">{title}</span>
);

TableColumnTitle.propTypes = {
  title: oneOfType([string, element]),
};

export default TableColumnTitle;
