import { convertToChartsData } from 'services/formatter';

import { subtractDate, todayDate } from '../../utils/date';

import { getSuccessStatus } from '../helpers/statuses';
import {
  FAILURE_FETCH_CHART_INFORMERS,
  FAILURE_FETCH_CHART_SITE_STATISTICS,
  FAILURE_FETCH_TABLE_INFORMERS,
  FAILURE_FETCH_TABLE_SITE_STATISTICS,
  FETCH_SITES_LIST,
  START_FETCH_CHART_INFORMERS,
  START_FETCH_CHART_SITE_STATISTICS,
  START_FETCH_TABLE_INFORMERS,
  START_FETCH_TABLE_SITE_STATISTICS,
  SUCCESS_DELETE_INFORMER,
  SUCCESS_FETCH_CHART_INFORMERS,
  SUCCESS_FETCH_CHART_SITE_STATISTICS,
  SUCCESS_FETCH_SITE,
  SUCCESS_FETCH_SITE_NEWS,
  SUCCESS_FETCH_TABLE_INFORMERS,
  SUCCESS_FETCH_TABLE_SITE_STATISTICS,
} from '../types';

const initialState = {
  info: {},
  news: [],
  chart: {
    isLoading: true,
  },
  table: {
    isLoading: true,
    total: 0,
    limit: 20,
    offset: 0,
    start: +subtractDate(29),
    end: +todayDate,
    data: [],
  },
  statisticsChart: {
    isLoading: true,
  },
  statisticsBasic: {},
  statisticsTable: {
    isLoading: true,
    total: 0,
    limit: 20,
    offset: 0,
    start: +subtractDate(29),
    end: +todayDate,
    data: [],
  },
};

export default function site(state = initialState, action = {}) {
  switch (action.type) {
    // label SITE

    case SUCCESS_FETCH_SITE:
      return {
        ...state,
        info: action.payload.data,
      };

    case SUCCESS_FETCH_SITE_NEWS:
      return {
        ...state,
        news: action.payload.news,
      };

    case getSuccessStatus(FETCH_SITES_LIST):
      return {
        ...state,
        statisticsBasic: {
          ...state.statisticsBasic,
          ...action.payload,
        },
      };

    // label CHART
    case START_FETCH_CHART_INFORMERS:
      return {
        ...state,
        chart: {
          ...state.chart,
          isLoading: true,
        },
      };

    case SUCCESS_FETCH_CHART_INFORMERS:
      return {
        ...state,
        chart: {
          ...convertToChartsData(action.payload.data),
          isLoading: false,
        },
      };

    case FAILURE_FETCH_CHART_INFORMERS:
      return {
        ...state,
        chart: {
          ...state.chart,
          isLoading: false,
        },
      };

    // label TABLE
    case START_FETCH_TABLE_INFORMERS:
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: true,
        },
      };

    case SUCCESS_FETCH_TABLE_INFORMERS:
      return {
        ...state,
        table: {
          ...state.table,
          ...action.payload,
          isLoading: false,
        },
      };

    case FAILURE_FETCH_TABLE_INFORMERS:
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: false,
        },
      };

    // label DELETE INFORMER INTO TABLE
    case SUCCESS_DELETE_INFORMER:
      return state;

    // label SITE STATISTICS CHART
    case START_FETCH_CHART_SITE_STATISTICS:
      return {
        ...state,
        statisticsChart: {
          isLoading: true,
        },
      };

    case SUCCESS_FETCH_CHART_SITE_STATISTICS:
      return {
        ...state,
        statisticsChart: {
          ...convertToChartsData(action.payload.data),
          isLoading: false,
        },
      };

    case FAILURE_FETCH_CHART_SITE_STATISTICS:
      return {
        ...state,
        statisticsChart: {
          isLoading: false,
        },
      };

    // label SITE STATISTICS TABLE
    case START_FETCH_TABLE_SITE_STATISTICS:
      return {
        ...state,
        statisticsTable: {
          ...state.statisticsTable,
          isLoading: true,
        },
      };

    case SUCCESS_FETCH_TABLE_SITE_STATISTICS:
      return {
        ...state,
        statisticsTable: {
          ...state.statisticsTable,
          ...action.payload,
          isLoading: false,
        },
      };

    case FAILURE_FETCH_TABLE_SITE_STATISTICS:
      return {
        ...state,
        statisticsTable: {
          ...state.statisticsTable,
          isLoading: false,
        },
      };

    default:
      return state;
  }
}
