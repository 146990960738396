import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import { withTranslation } from 'react-i18next';
import { FaUserPlus, FaUserLock } from 'react-icons/fa';

// Styles
import { LoginTabs, LoginTabsItem } from './styles';

// Components
import LoginForm from '../../components/Form/forms/LoginForm';
import RegistrationForm from '../../components/Form/forms/RegistrationForm';

const Login = ({ isMobile, t }) => {
  return (
    <Row
      type="flex"
      align="top"
      justify="center"
      style={{
        height: `calc(100vh - ${isMobile ? 0 : '- 20vh'} 100px)`,
        marginTop: isMobile ? 0 : '20vh',
      }}
    >
      <Col xs={24} sm={16} md={12} lg={11} xl={8} xxl={6}>
        <LoginTabs defaultActiveKey="login" tabPosition="top" size="large">
          <LoginTabsItem
            tab={
              <div>
                <FaUserLock />
                {t('content.title.login')}
              </div>
            }
            key="login"
          >
            <LoginForm />
          </LoginTabsItem>

          <LoginTabsItem
            tab={
              <div>
                <FaUserPlus />
                {t('content.title.registration')}
              </div>
            }
            key="registration"
          >
            <RegistrationForm />
          </LoginTabsItem>
        </LoginTabs>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ userSettings }) => ({
  isMobile: userSettings.isMobile,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Login));
