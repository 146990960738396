import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown as DropdownSemantic } from 'semantic-ui-react';

export default class Dropdown extends Component {
  static propTypes = {
    name: PropTypes.string,
    callback: PropTypes.func,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    labelText: PropTypes.string,
    id: PropTypes.string,
    fluid: PropTypes.bool,
    inverted: PropTypes.bool,
  };

  onChange = (event, data) => {
    const { props } = this;

    props.onChange(
      // name: data.name,
      data.value,
    );
  };

  render() {
    const { onChange, labelText, validateName, ...props } = this.props;
    const id = props.id || props.name;

    return (
      <div className="input-container">
        {labelText && <label htmlFor={id}>{labelText}</label>}
        <DropdownSemantic onChange={this.onChange} fluid selection {...props} />
      </div>
    );
  }
}
