import React, { useState } from 'react';
import { Upload } from 'antd';
import { withTranslation } from 'react-i18next';
import {
  UploadBlockIcon,
  UploadBlockTitle,
  UploadBlockTooltip,
} from './styles';

const { Dragger } = Upload;

const getBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => reader.result;
    reader.onloadend = () => resolve(reader.result.split(',').pop());
    reader.onerror = error => reject(error);
  });

const UploadImages = ({ onUpload, uploadedFiles, t }) => {
  const [fileList, setFileList] = useState([]);

  const handleChange = ({ fileList }) => {
    // onUpload(fileList);
    // Promise.reject();
  };

  const handleBeforeUpload = file => {
    getBase64(file).then(response => {
      const result = { file: response, type: file.type };

      setFileList([...fileList, result]);
      onUpload(result);
    });

    return false;
  };

  return (
    <Dragger
      accept="image/*"
      multiple
      listType="text"
      // fileList={fileList}
      showUploadList={{
        showPreviewIcon: false,
        showDownloadIcon: false,
        showRemoveIcon: false,
      }}
      beforeUpload={handleBeforeUpload}
      onChange={handleChange}
    >
      <UploadBlockIcon size={30} />

      <UploadBlockTitle>{t('blocks.upload.drag.title')}</UploadBlockTitle>

      <UploadBlockTooltip>
        ({t('blocks.upload.drag.tooltip')})
      </UploadBlockTooltip>
    </Dragger>
  );
};

export default withTranslation()(UploadImages);
