import React from 'react';
import { Icon } from 'antd';

import TableRowTitle from '../components/TableRowTitle';
// Components
import TableStatus from '../components/TableStatus';
import WithTranslation from '../components/WithTranslation';

import statuses from '../../../constants/statuses';
// Utils
import { convertDate } from '../../../services/formatter';
import {
  colorGreenSemantic,
  colorRed,
} from '../../../styles/js/variables/colors';

const getTranslatedCell = ({
  type,
  titleKey,
  tooltipKey,
  isWithTooltip,
  isVertical,
  isBoolean,
}) => ({
  name: <WithTranslation type={`table.${titleKey || type}`} />,
  key: type,
  className: isVertical ? 'ant-table-column--vertical ' : '',
  tooltip: isWithTooltip ? (
    <WithTranslation type={`table.tooltips.${tooltipKey || type}`} />
  ) : null,
  ...(isBoolean && {
    render: text => (
      <Icon
        type={text ? 'check' : 'close'}
        twoToneColor={text ? colorGreenSemantic : colorRed}
        theme="outlined"
        style={{ color: text ? colorGreenSemantic : colorRed }}
      />
    ),
  }),
});

export default {
  sites: {
    name: <WithTranslation type="table.sites" />,
    key: 'name',
    render: (text, { domain }) => (
      <TableRowTitle title={text} isLink subtitle={domain} />
    ),
  },
  date: {
    ...getTranslatedCell({ type: 'date' }),
    render: text => convertDate(text),
  },
  url: {
    ...getTranslatedCell({ type: 'url' }),
    render: text => <div className="table-cell--left">{text}</div>,
  },
  test_type: getTranslatedCell({
    titleKey: 'test_type',
    tooltipKey: 'testType',
  }),
  display: getTranslatedCell({ type: 'display', isWithTooltip: true }),
  domain: getTranslatedCell({ type: 'domain' }),
  views: getTranslatedCell({ type: 'views', isWithTooltip: true }),
  publisher: getTranslatedCell({ type: 'name', titleKey: 'publisher' }),
  id: getTranslatedCell({ type: 'id' }),
  informer: getTranslatedCell({ type: 'name', titleKey: 'informer' }),
  login: getTranslatedCell({ type: 'login' }),
  status: {
    ...getTranslatedCell({ type: 'status' }),
    render: (text, record) => <TableStatus {...record} />,
    align: 'center',
    filters: Object.keys(statuses).map(key => ({
      text: <WithTranslation type={`status.${statuses[key]}`} />,
      value: statuses[key],
    })),
    onFilter: (value, record) => record.status.indexOf(value) === 0,
  },
  actions: {
    name: 'Действия'.toUpperCase(),
    key: 'actions',
    align: 'center',
  },

  views_all: {
    name: 'Показы все',
    key: 'views_all',
    tooltip:
      'Показ засчитывается при переходе на страницу сайта, на которой стоит информер',
  },
  views_real: getTranslatedCell({ type: 'views_real', isWithTooltip: true }),
  views_our: {
    name: 'от UkrTop',
    key: 'views_our',
    tooltip:
      'Показ засчитывается при переходе на страницу сайта, на которой стоит информер, со страницы топа новостей UkrTop',
  },
  views_our_real: {
    name: 'Информер от UkrTop',
    key: 'views_our_real',
    tooltip:
      'Показ засчитывается при просмотре информера на странице сайта после перехода на нее со страницы топа новостей UkrTop',
  },
  views_another: {
    name: 'Помимо UrkTop',
    key: 'views_another',
    tooltip:
      'Показ засчитывается при переходе на страницу сайта, на которой стоит информер, с других источников траффика, за исключением страницы топа новостей UkrTop',
  },
  views_another_real: {
    name: 'Информер помимо UkrTop',
    key: 'views_another_real',
    tooltip:
      'Показ засчитывается при просмотре информера на странице сайта после перехода на нее с других источников траффика, за исключением страницы топа новостей UkrTop',
  },
  ko: {
    ...getTranslatedCell({ type: 'ko', isWithTooltip: true }),
    render: text => (
      <div className="table-cell--right">{Number(text).toFixed(2)}</div>
    ),
  },
  rpm: {
    name: 'Коэффициент',
    key: 'rpm',
    tooltip: 'Кликов за тысячу просмотров',
  },

  clicks: {
    name: 'Клики все',
    key: 'clicks',
    tooltip: 'Количество кликов по информеру',
  },
  clicks_all: {
    name: 'Клики все',
    key: 'clicks_all',
    tooltip: 'Количество кликов по информеру',
  },
  click_on: getTranslatedCell({ type: 'click_on', isWithTooltip: true }),
  click_from: getTranslatedCell({ type: 'click_from', isWithTooltip: true }),

  click_from_site: {
    name: 'На сайт',
    key: 'click_from',
    tooltip: 'Переходы с UkrTop на сайт издателя',
  },
  click_on_site: {
    name: 'С сайта',
    key: 'click_on',
    tooltip: 'Клики на информер на сайте издателя',
  },
  ctr: getTranslatedCell({ type: 'ctr', isWithTooltip: true }),

  // label: Informers page
  page_informer_stats_click_from: getTranslatedCell({
    type: 'click_from',
    titleKey: 'page_informer_stats_click_from',
    tooltipKey: 'page_informer_stats_click_from',
    isWithTooltip: true,
  }),
  page_informer_stats_ctr: getTranslatedCell({
    type: 'ctr',
    tooltipKey: 'page_informer_stats_ctr',
    isWithTooltip: true,
  }),
  page_informer_stats_display: getTranslatedCell({
    type: 'display',
    titleKey: 'page_informer_stats_display',
    tooltipKey: 'page_informer_stats_display',
    isWithTooltip: true,
  }),
  page_informer_stats_views: getTranslatedCell({
    type: 'views',
    tooltipKey: 'page_informer_stats_views',
    isWithTooltip: true,
  }),
};
