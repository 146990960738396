/* eslint-disable indent */

import { convertToChartsData } from 'services/formatter';

import { subtractDate, todayDate } from 'utils/date';

import {
  ADD_SITES_ANALYTICS_FILE_UPLOAD,
  FAILURE_ADD_SITE,
  FAILURE_DELETE_SITE,
  FAILURE_EDIT_SITE,
  FAILURE_FETCH_SITES_CHART,
  FAILURE_FETCH_SITES_TABLE,
  START_ADD_SITE,
  START_DELETE_SITE,
  START_EDIT_SITE,
  START_FETCH_SITES_CHART,
  START_FETCH_SITES_TABLE,
  SUCCESS_ADD_SITE,
  SUCCESS_DELETE_SITE,
  SUCCESS_EDIT_SITE,
  SUCCESS_FETCH_SITES_CHART,
  SUCCESS_FETCH_SITES_TABLE,
  SUCCESS_SITES_ANALYTICS_SPEED_UP,
} from '../types';

const initialState = {
  chart: {
    data: [],
    isLoading: true,
  },
  table: {
    isLoading: true,
    total: 0,
    limit: 20,
    offset: 0,
    start: +subtractDate(29),
    end: +todayDate,
    data: [],
  },
  uploadedFiles: [],
};

export default function sites(state = initialState, action = {}) {
  switch (action.type) {
    // Fetch table sites
    // ________________________________________________________
    case START_FETCH_SITES_TABLE:
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: true,
        },
      };

    case SUCCESS_FETCH_SITES_TABLE:
      return {
        ...state,
        table: {
          ...state.table,
          ...action.payload,
          isLoading: false,
        },
      };

    case FAILURE_FETCH_SITES_TABLE:
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: false,
        },
      };
    // ________________________________________________________
    // END Fetch table sites

    // Add  site in table
    // ________________________________________________________
    case START_ADD_SITE:
      return state;

    case SUCCESS_ADD_SITE:
      return state;

    case FAILURE_ADD_SITE:
      return state;
    // ________________________________________________________
    // END Add  site in table

    // Edit  site in table
    // ________________________________________________________
    case START_EDIT_SITE:
      return state;

    case SUCCESS_EDIT_SITE:
      return {
        ...state,
        table: {
          ...state.table,
          data: action.payload,
        },
      };

    case FAILURE_EDIT_SITE:
      return state;
    // ________________________________________________________
    // END Edit  site in table

    // Delete site in table
    // ________________________________________________________
    case START_DELETE_SITE:
      return state;

    case SUCCESS_DELETE_SITE:
      return state;

    case FAILURE_DELETE_SITE:
      return state;
    // ________________________________________________________
    // END Delete site in table

    // Fetch chart data
    // ________________________________________________________
    case START_FETCH_SITES_CHART:
      return {
        ...state,
        chart: {
          ...state.chart,
          isLoading: true,
        },
      };

    case SUCCESS_FETCH_SITES_CHART:
      return {
        ...state,
        chart: {
          ...convertToChartsData(action.payload.data),
          isLoading: false,
        },
      };

    case FAILURE_FETCH_SITES_CHART:
      return {
        ...state,
        chart: {
          ...state.chart,
          isLoading: false,
        },
      };

    case ADD_SITES_ANALYTICS_FILE_UPLOAD:
      return {
        ...state,
        uploadedFiles: [
          ...state.uploadedFiles,
          action.payload.data,
          // ...action.payload.data,
        ],
      };

    case SUCCESS_SITES_ANALYTICS_SPEED_UP:
      return {
        ...state,
        uploadedFiles: [],
      };
    // ________________________________________________________
    // END Fetch chart data

    default:
      return state;
  }
}
