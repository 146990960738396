import React from 'react';
import { Descriptions as AntDescriptions } from 'antd';

import styled from 'styled-components';

const List = styled(AntDescriptions)`
  display: inline-block;
  min-width: 40%;
  th {
    position: relative;
  }
  td {
    text-align: right;
    > * {
      display: inline-block !important; // For Status component
    }
  }
`;

const Item = styled(AntDescriptions.Item)``;

export const Descriptions = ({ title, list = [] }) => {
  return (
    <List title={title} column={1} bordered size="middle">
      {list.map(({ key, value }) => (
        <Item key={key} label={key}>
          {value}
        </Item>
      ))}
    </List>
  );
};
