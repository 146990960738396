import React from 'react';
import { Popconfirm } from 'antd';

import LinkWithTag from '../../../../LinkWithTag';
import WithTranslation from '../../WithTranslation';
import { linksActions } from '../actionsConfig';
import { WithComponent } from '../helpers/WithComponent';

const ActionButtonsItem = props => {
  const { clickKey, color, confirm, tooltip, row, action, ...button } = props;
  const onClick = data => action(row, data);
  const isTargetBlank = clickKey === 'link';
  // const isIntegration = tooltip === 'form.title.siteIntegration';

  // TODO: it's a bad idea. It should be automatic (see actionsConfig)
  const isLinkWrap = linksActions.includes(clickKey);

  return (
    <WithComponent
      component={Popconfirm}
      isWithComponent={confirm}
      onConfirm={onClick}
      {...confirm}
    >
      <LinkWithTag
        isButton
        linkProps={{
          icon: 'question',
          ...button,
          tooltip: <WithTranslation type={tooltip} />,
        }}
        isTargetBlank={isTargetBlank}
        className={`button--inverted button--${color}`}
        href={isLinkWrap ? onClick({}) : null}
        onClick={!confirm && !isLinkWrap && onClick}
      />
    </WithComponent>
  );
};

ActionButtonsItem.propTypes = {};

export default ActionButtonsItem;
