import React, { useCallback } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { Select } from 'antd';
import { withTranslation } from 'react-i18next';

// Constants
import { informerTemplates } from '../../../constants/informerTemplates';
import { choiceInformer } from '../../../redux/actions/informerSettings';

// Utils
import { replaceObjectByKeys } from '../../../utils/object';

// Components
import Label from '../../Label';

const { Option } = Select;

// const templates = {
//   init: null,
// ...informerTemplates,
// }

const TemplatesBlock = ({ t, informerSettings, setStyles }) => {
  const onSelectTemplate = useCallback(
    template => {
      setStyles(
        replaceObjectByKeys(informerSettings, informerTemplates[template]),
      );
    },
    [informerSettings, setStyles],
  );

  // label: Styles reset
  // const initTemplates = useCallback(() => {
  //   if (!isLoading && templates.init === null) {
  //     templates.init = {
  //       desktop: cloneDeep(informerSettings.desktop),
  //       mobile: cloneDeep(informerSettings.mobile),
  //     };
  //   }
  // }, [informerSettings, isLoading]);
  //
  // useEffect(initTemplates, [informerSettings]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Label
        labelText={t('pages.informerSettings.templates.label')}
        mark={t('basic.mobileFieldHint')}
      />
      <Select
        placeholder="..."
        onChange={onSelectTemplate}
        style={{ minWidth: '200px' }}
      >
        {Object.keys(informerTemplates).map(template => (
          <Option key={template} value={template}>
            {t(`pages.informerSettings.templates.${template}`)}
          </Option>
        ))}
      </Select>
    </div>
  );
};

TemplatesBlock.propTypes = {
  t: func,
};

const mapStateToProps = ({ informerSettings, network }) => ({
  informerSettings,
  isLoading: network.START_FETCH_INFORMER,
});

const mapDispatchToProps = {
  setStyles: choiceInformer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(TemplatesBlock));
