import React, { Fragment, PureComponent } from 'react';
import { Empty, Table as TableAntD } from 'antd';
import classNames from 'classnames';

// Utils
import { withTranslation } from 'react-i18next';
import columnsAuto from './config/columnsAuto';

// Components
import actionsConfig from './components/Actions/actionsConfig';
import renderActions from './components/Actions/renderActions';
import WithTableFooter from './components/WithTableFooter';
import sortDirections from '../../constants/sortDirections';
import { getTableSize, setTableSize } from '../../services/localStorage';

class Table extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      limit: getTableSize() || props.limit,
      sortBy: '',
      sortDirection: sortDirections.DESC,
      tableHeaders: [],
      filters: {},
      rowActions: {},
    };
  }

  componentDidMount() {
    this.convertActions();
  }

  // prettier-ignore
  componentDidUpdate(prevProps, prevState) {
    const { data } = this.props;
    const { sortBy, sortDirection } = this.state;
    const isNewData = prevProps.data && prevProps.data.length !== data && data.length;
    const isEmptyHeaders = prevState.tableHeaders && prevState.tableHeaders.length === 0;
    const isSortingChanged = sortBy !== prevState.sortBy || sortDirection.table !== prevState.sortDirection.table;

    if ((isNewData && isEmptyHeaders) || isSortingChanged) {
      this.setHeaders();
    }
  }

  changePagination = () => {
    const { fetch, isClientPagination } = this.props;
    if (!isClientPagination) fetch(this.state);
  };

  getAutoHeader = () => {
    const {
      customHeaders = {},
      data,
      actions,
      isAutoHeadersSort,
      t,
    } = this.props;
    const { sortDirection, sortBy, rowActions } = this.state;
    const result = [];
    const params = {
      sorter: isAutoHeadersSort,
      sortOrder: sortDirection.table,
      sortBy,
    };
    const columnsKeys = Object.keys(data[0]);
    const newActions = actions ? [renderActions(rowActions)] : [];

    const getTitle = key => {
      const translation = t(`table.auto.${key}`);
      const translationKey = `table.auto.${key}`;

      return translation === translationKey ? key : translation;
    };

    columnsKeys.forEach(key => {
      if (key in customHeaders && customHeaders[key] === null) return;

      result.push({
        name: key,
        align: 'center',
        title: getTitle(key),
        dataIndex: key,
        ...columnsAuto[key],
        ...customHeaders[key],
        ...params,
        sortOrder: params.sortBy === key ? params.sortOrder : null,
      });
    });

    return [...result, ...newActions];
  };

  getHeader = () => {
    const { headers, actions, isClientPagination } = this.props;
    const { sortDirection, sortBy, rowActions } = this.state;
    const params = {
      sorter: true,
      sortOrder: sortDirection.table,
      sortBy,
      isClientPagination,
    };
    const newActions = actions ? [renderActions(rowActions)] : [];
    const newHeaders =
      typeof headers === 'function' ? headers(params) : headers;

    return [...newHeaders, ...newActions];
  };

  convertActions = () => {
    const { actions = {}, t } = this.props;

    const result = Object.entries(actions).reduce(
      (acc, [key, action]) => ({
        ...acc,
        [key]: {
          ...actionsConfig[key],
          tooltip: t(`table.rowActions.${key}`),
          clickKey: key,
          action,
        },
      }),
      {},
    );

    this.setState({ rowActions: result });
  };

  setHeaders = () => {
    const { isAutoHeaders } = this.props;
    const tableHeaders = isAutoHeaders ? this.getAutoHeader : this.getHeader;

    this.setState({ tableHeaders: tableHeaders() });
  };

  // prettier-ignore
  handleChange = (pagination, filters, sorter) => {
    const { isClientPagination } = this.props;
    const { sortBy, sortDirection, currentPage } = this.state;
    const { columnKey, order } = sorter;
    const { ASC, DESC } = sortDirections;

    // Order config
    const isOrderSwitched = columnKey !== order && columnKey === sortBy && order !== sortDirection.table;
    const reversedOrder = sortDirection.table === ASC.table ? DESC : ASC;
    const direction = isOrderSwitched ? reversedOrder : sortDirection;

    // Pagination config
    const isPaginationChanged = currentPage !== pagination.current;
    const isSortingChanged = sortBy !== columnKey || sortDirection.table !== order;
    const isPaginationFetchOff = isClientPagination && isPaginationChanged && !isSortingChanged;

    this.setState(
      {
        currentPage: pagination.current,
        limit: pagination.pageSize,
        sortBy: columnKey,
        sortDirection: direction,
        filters,
      },
      isPaginationFetchOff ? () => {} : this.changePagination,
    );
  };

  savePageSizeToLocalStorage = (current, size) => {
    const tableSizes = getTableSize() || {};
    setTableSize({
      ...tableSizes,
      [window.location.pathname]: size,
    });
  };

  render() {
    const { props } = this;
    const {
      actions,
      data = [],
      fetch,
      headers,
      isAutoHeaders,
      isClientPagination,
      isLoading,
      summaryData = {},
      summaryKeys,
      t,
      total,
      rowKey = record => record.id,
      ...antTableProps
    } = props;
    const { tableHeaders, limit } = this.state;
    const isMarkRowByStatus = tableHeaders.find(
      ({ dataIndex }) => dataIndex === 'status',
    );

    const rowClassNamesString = ({ status, display, views }) =>
      classNames({
        [`ant-table-row--${status}`]: isMarkRowByStatus && status,
        'ant-table-row--warning':
          status === 'active' && display < 1 && views === 0,
      });

    return (
      <Fragment>
        <TableAntD
          loading={isLoading}
          columns={tableHeaders}
          dataSource={data}
          rowKey={rowKey}
          rowClassName={rowClassNamesString}
          bordered
          locale={{
            filterConfirm: t('table.confirm'),
            filterReset: t('table.reset'),
            emptyText: (
              <Empty
                description={t('basic.emptyData')}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ),
          }}
          size="default"
          pagination={{
            total,
            defaultPageSize: limit,
            size: 'large',
            position: 'bottom',
            hideOnSinglePage: false,
            showQuickJumper: false,
            showSizeChanger: true,
            onShowSizeChange: this.savePageSizeToLocalStorage,
            pageSizeOptions: ['5', '10', '20', '30'],
            locale: {
              jump_to: 'Перейти',
            },
          }}
          onChange={this.handleChange}
          components={{
            table: tableProps => (
              <WithTableFooter
                {...tableProps}
                summaryKeys={summaryKeys}
                summaryData={summaryData}
              />
            ),
          }}
          {...antTableProps}
        />
      </Fragment>
    );
  }
}

export default withTranslation('common')(Table);
