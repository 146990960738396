export default {
  ASC: {
    api: 'asc',
    table: 'ascend',
  },
  DESC: {
    api: 'desc',
    table: 'descend',
  },
};
