/* eslint-disable no-param-reassign */
// eslint-disable import/no-cycle
import axios from 'axios';

import { convertToUTC } from '../../utils/date';

import { api } from '../../api/config';

import { LOGIN } from '../../config/routePaths';
import store from '../../redux/store';
import {
  getAccessToken,
  getRefreshToken,
  logOutUser,
  removeAccessToken,
  removeRefreshToken,
  saveDataToLocalStorage,
} from '../localStorage';
import { secureAxios } from './enhanceAxios';

// Variables
const apiRefresh = `${api}/refresh`;
const getRefreshTokenApi = params => secureAxios(params).get(apiRefresh, {});
const instance = axios.create({ baseURL: api });
let isPendingToken = false;

const convertRequestToUTC = config => {
  const startDate = config.params && config.params.start;
  const endDate = config.params && config.params.end;

  if (startDate && endDate) {
    config.params.start = +convertToUTC(startDate);
    config.params.end = +convertToUTC(endDate);
  }

  return config;
};

// label REQUEST
instance.interceptors.request.use(
  config => convertRequestToUTC(config),
  error => Promise.reject(error),
);

// label RESPONSE
instance.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;
    const isError401 = error.response.status === 401;
    const isRefreshTokenApi = apiRefresh === originalRequest.url;
    const isExpiredToken =
      isError401 && !isRefreshTokenApi && !originalRequest._retry;

    const redirectToLoginPage = () => {
      removeAccessToken();
      removeRefreshToken();
      logOutUser();
      document.location.replace(LOGIN);
    };

    const getTokenWithDelay = ms =>
      new Promise(resolve =>
        setTimeout(async () => {
          const newToken = await getAccessToken();
          resolve(newToken);
        }, ms),
      );

    // label TOKENS block:

    if (isPendingToken) {
      return getTokenWithDelay(1000).then(token => {
        originalRequest.headers.Authorization = token;
        return axios(originalRequest);
      });
    }

    if (!isPendingToken && isExpiredToken) {
      isPendingToken = true;

      return (
        getRefreshTokenApi({ token: getRefreshToken() })
          // Checking errors
          .then(({ status, data }) => {
            if (status !== 200) {
              if (status === 401) redirectToLoginPage();
              throw new Error();
            }

            isPendingToken = false;

            return data;
          })

          // Saving tokens
          .then(data => {
            saveDataToLocalStorage(data);

            return data.access_token;
          })

          // Updating request
          .then(accessToken => {
            originalRequest.headers.Authorization = accessToken;
            originalRequest._retry = true; // eslint-disable-line no-underscore-dangle

            return originalRequest;
          })

          // Resending request
          .then(request => {
            return axios(request);
          })

          // Error redirecting
          .catch(e => {
            const { modals } = store.getState();
            const isModalShowing = !!modals.type;

            if (!isModalShowing || (e.response && e.response.status === 401)) {
              redirectToLoginPage();
            }
          })
      );
    }

    return Promise.reject(error);
  },
);

export default instance;
