import React from 'react';
import { func } from 'prop-types';
import { Button, Result } from 'antd';
import { withTranslation } from 'react-i18next';
import { FaExclamationTriangle } from 'react-icons/fa';

// Utils
import { colorYellow } from '../../../styles/js/variables/colors';

const SuccessAddPublisherModal = ({ t, onSuccess, onClose }) => {
  const onStart = () => {
    onSuccess();
    onClose();
  };

  return (
    <Result
      status="warning"
      title={t('tooltip.warningNewDomainTitle')}
      subTitle={
        <div
          dangerouslySetInnerHTML={{
            __html: t('tooltip.warningNewDomainDescription'),
          }}
        />
      }
      icon={<FaExclamationTriangle size={50} color={colorYellow} />}
      extra={[
        <Button type="primary" onClick={onStart} key="success" size="large">
          {t('form.buttonUnderstand')}
        </Button>,
      ]}
    />
  );
};

SuccessAddPublisherModal.propTypes = {
  t: func,
  onClose: func,
  onSuccess: func,
};

export default withTranslation()(SuccessAddPublisherModal);
