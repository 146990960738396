import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Checkbox } from 'antd';

// Utils
import { fontsVariants } from '../constants/fontsVariants';
import { textAligns } from '../constants/textStyles';
import {
  mustBeFilled,
  mustBeHexColor,
  mustBeNumber,
  mustBeMore10,
} from '../../../config/validationRules';
import { ValidatorContext } from '../../../context/ValidatorContext';

// Components
import Label from 'components/Label';
import ValidateInput from 'components/ValidateInput';
import ValidateInputWithColorPicker from 'components/ValidateInputWithColorPicker';
import Dropdown from 'components/common/Dropdown';
import DistanceSettings from 'components/SettingPanel/components/DistanceSettings';

import Grid from '../../Grid';

function FontBlock({ element, onChange, t }) {
  const { actions } = React.useContext(ValidatorContext);
  const font = element;

  return (
    <Fragment>
      <Grid columns={2}>
        <div>
          <Label labelText={t('basic.fontName')} asterisk />
          <Dropdown
            name="name"
            validateName="font-block-font"
            options={fontsVariants}
            selection
            value={font.name}
            onChange={value =>
              onChange({
                item: 'font',
                response: { name: 'name', value },
              })
            }
          />
        </div>
        <div>
          <Label labelText={t('basic.color')} asterisk />
          <ValidateInputWithColorPicker
            name="color"
            validateName="font-block-color"
            defaultValue={font.color}
            value={font.color}
            controlled
            colorPicker={{
              onDrag: () => {},
              onComplete: value =>
                onChange({
                  item: 'font',
                  response: { name: 'color', value },
                }),
            }}
            inputValidation={{
              placeholder: '#000000',
              type: 'text',
              onChange: value => {
                onChange({
                  item: 'font',
                  response: { name: 'color', value },
                });
              },
              onValidate: input => {
                actions.validate(input);
              },
              rules: [mustBeFilled, mustBeHexColor],
            }}
          />
        </div>
        <div>
          <Label labelText={t('basic.fontWeight')} />
          <ValidateInput
            name="fontWeight"
            validateName="font-block-font-weight"
            labelText={t('basic.fontWeight')}
            defaultValue={font.fontWeight}
            value={font.fontWeight}
            controlled
            placeholder="bold / 800"
            type="text"
            onChange={value =>
              onChange({
                item: 'font',
                response: { name: 'fontWeight', value },
              })
            }
            onValidate={input => actions.validate(input)}
          />
        </div>
        <div>
          <Label labelText={t('basic.textAlign')} asterisk />
          <Dropdown
            name="textAlign"
            validateName="font-block-text-align"
            options={textAligns}
            selection
            value={font.textAlign}
            onChange={value =>
              onChange({
                item: 'font',
                response: { name: 'textAlign', value },
              })
            }
          />
        </div>

        <div>
          <Label labelText={t('basic.fontSize')} asterisk />
          <ValidateInput
            name="size"
            validateName="font-block-size"
            labelText={t('basic.fontSize')}
            defaultValue={font.size}
            value={font.size}
            controlled
            placeholder="16"
            type="number"
            onChange={value =>
              onChange({
                item: 'font',
                response: { name: 'size', value },
              })
            }
            rules={[mustBeFilled, mustBeNumber, mustBeMore10]}
            onValidate={input => actions.validate(input)}
          />
        </div>
        <div>
          <Label labelText={t('basic.lineHeight')} asterisk />
          <ValidateInput
            name="height"
            validateName="font-block-height"
            labelText={t('basic.lineHeight')}
            defaultValue={font.height}
            value={font.height}
            controlled
            placeholder="18"
            type="number"
            onChange={value =>
              onChange({
                item: 'font',
                response: { name: 'height', value },
              })
            }
            rules={[mustBeFilled, mustBeNumber, mustBeMore10]}
            onValidate={input => actions.validate(input)}
          />
        </div>
        <div>
          <Label labelText="" />
          <Checkbox
            checked={font.position === 'absolute'}
            onChange={({ target }) =>
              onChange({
                item: 'font',
                response: {
                  name: 'position',
                  value: target.checked ? 'absolute' : 'relative',
                },
              })
            }
          >
            {t('basic.aboveImage')}
          </Checkbox>
        </div>
      </Grid>

      <DistanceSettings
        prefix="font-block"
        element={font}
        item="font"
        onChange={onChange}
      />

      <DistanceSettings
        isMargin
        prefix="font-block"
        element={font}
        item="font"
        onChange={onChange}
      />
    </Fragment>
  );
}

FontBlock.propTypes = {
  element: PropTypes.shape({}),
  onChange: PropTypes.func,
};

FontBlock.defaultProps = {
  element: {},
  onChange: () => {},
};

export default withTranslation()(FontBlock);
