import React from 'react';
import { FaTelegramPlane, FaEnvelope } from 'react-icons/fa';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// Redux
import { showModal as showModalAction } from '../../redux/actions/modals';

// Styles
import { FooterFeedback, FooterFeedbackText, FooterWrapper } from './styles';

const Footer = ({ t, user, isAuthorized, showModal }) => {
  const isDefinedUser = !!(isAuthorized && user.name);

  const openFeedbackForm = () =>
    showModal({ type: 'MODAL_FEEDBACK', modalWidth: '500px' });

  return (
    <FooterWrapper>
      <FooterFeedback
        {...(isDefinedUser
          ? {
              target: '_blank',
              href: `https://t.me/${process.env.REACT_APP_SUPPORT_TELEGRAM_USER}`,
              rel: 'noopener noreferrer',
            }
          : {
              onClick: openFeedbackForm,
            })}
        type="link"
        fixed="true"
      >
        <FooterFeedbackText>
          <span>{t('header.mailUs')}</span>
        </FooterFeedbackText>
        {isDefinedUser ? (
          <FaTelegramPlane size={28} />
        ) : (
          <FaEnvelope size={28} />
        )}
      </FooterFeedback>
    </FooterWrapper>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

const mapDispatchToProps = {
  showModal: showModalAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Footer));
