import React from 'react'; // eslint-disable-line
import { renderToString } from 'react-dom/server';
import { Icon } from 'semantic-ui-react';

import {
  colorBlue,
  colorGreen,
  colorRed,
} from '../../styles/js/variables/colors';

export const chartColors = [
  colorBlue,
  colorGreen,
  colorRed,
  // colorGreyLight,
  '#FF9655',
  '#6AF9C4',
  '#058DC7',
  '#50B432',
  '#ED561B',
  '#DDDF00',
  '#24CBE5',
  '#64E572',
];

const getChartData = (data, t) => ({
  views: {
    name: t('table.views'),
    data: data.views,
    hint: t('table.tooltips.views'),
  },
  clicksOn: {
    name: t('table.click_on'),
    data: data.clicksOn,
    hint: t('table.tooltips.click_on'),
  },
  clicksFrom: {
    name: t('table.click_from'),
    data: data.clicksFrom,
    hint: t('table.tooltips.click_from'),
  },
  efficiencies: {
    name: 'Эффективность',
    data: data.efficiencies,
    hint:
      'Показатель, который позволяет увидеть рентабельность работы всей системы и соответствует общему коэффициенту переходов',
  },
  coefficients: {
    name: t('table.ko'),
    data: data.coefficients,
    hint: t('table.tooltips.ko'),
  },
});

const getKeys = ({ data, showKeys, isAutoKeys, excludeKeys }) =>
  (isAutoKeys ? Object.keys(data) : showKeys).filter(
    key => !excludeKeys.includes(key),
  );

export const propsDataToChart = props => {
  const { data, t } = props;
  const chartData = getChartData(data, t);
  const keys = getKeys(props);
  const result = [];

  keys.forEach(key => {
    result.push(chartData[key]);
  });

  return result;
};

export function labeling() {
  const { name } = this;
  const hint = this.options.hint || '';
  const style = {
    top: '-0.5em',
    position: 'relative',
    opacity: '0.6',
  };
  const stringIcon = renderToString(
    <Icon
      name="info circle"
      color="olive"
      corner="top right"
      size="small"
      style={style}
    />,
  );

  return `<b title='${hint}'>${name} ${hint && stringIcon}</b>`;
}
