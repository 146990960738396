import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// Utils
import { ValidatorContext } from 'context/ValidatorContext';

// Components
import ValidateInput from 'components/ValidateInput';
import BorderSettings from 'components/SettingPanel/components/BorderSettings';
import {
  mustBeFilled,
  mustBeNumber,
  mustBeMore0AndLess320,
} from 'config/validationRules';
import Label from 'components/Label';
import { withTranslation } from 'react-i18next';
import Grid from '../../Grid';

const ImageBlock = ({ element, onChange, typeDevice, t }) => {
  const { actions } = React.useContext(ValidatorContext);
  const image = element;

  return (
    <Fragment>
      <Grid columns={2}>
        <div>
          <Label labelText={t('basic.imageHeight')} asterisk />
          <ValidateInput
            name="height"
            validateName="image-block-height"
            defaultValue={image.height}
            value={image.height}
            controlled
            placeholder="150"
            type="number"
            onChange={value =>
              onChange({
                item: 'image',
                response: { name: 'height', value },
              })
            }
            rules={[mustBeFilled, mustBeNumber, mustBeMore0AndLess320]}
            onValidate={input => actions.validate(input)}
          />
        </div>
        {typeDevice === 'mobile' && (
          <div>
            <Label
              labelText={t('basic.imageWidth')}
              mark={t('basic.mobileFieldHint')}
              asterisk
            />
            <ValidateInput
              name="width"
              validateName="image-block-width"
              defaultValue={image.width}
              value={image.width}
              controlled
              placeholder="100"
              type="number"
              onChange={value =>
                onChange({
                  item: 'image',
                  response: { name: 'width', value },
                })
              }
              rules={[mustBeFilled, mustBeNumber]}
              onValidate={input => actions.validate(input)}
            />
          </div>
        )}
      </Grid>

      <BorderSettings
        prefix="image-block"
        border={image}
        item="image"
        onChange={onChange}
      />
    </Fragment>
  );
};

ImageBlock.propTypes = {
  element: PropTypes.shape({}),
  onChange: PropTypes.func,
  typeDevice: PropTypes.string,
};

ImageBlock.defaultProps = {
  element: {},
  onChange: () => {},
  typeDevice: '',
};

export default withTranslation()(ImageBlock);
