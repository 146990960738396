import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { exact, string } from 'prop-types';

import SettingsGalleryItem from './components/SettingsGalleryItem';
import SettingsGalleryTitle from './components/SettingsGalleryTitle';

import { iInformerSettings } from '../../prop-types/informer';
import { iSite } from '../../prop-types/site';
import { getSiteNews } from '../../redux/actions/site';
import { useExampleNews } from '../CodeBlock/helpers/useExampleNews';

const SettingGallery = props => {
  const { typeDevice, informerSettings, site, t } = props;
  const informerStyle = informerSettings[typeDevice];
  const { general, title } = informerStyle;
  const { info, news } = site;
  const siteId = info.id;
  const backgroundImg = typeDevice === 'mobile' ? 'iphone' : 'macbook';

  const { limit } = useExampleNews(typeDevice);

  const testNews = useMemo(
    () =>
      Array.from(Array(limit), (d, i) => ({
        img: '',
        news_id: i,
        site_id: siteId,
        title: t('basic.titleNews'),
      })),
    [limit, siteId, t],
  );

  return (
    <div className="gallery">
      <div className={`gallery__sticky gallery__sticky--${typeDevice}`}>
        <div className={`gallery__${typeDevice}`}>
          <div className={`gallery__${typeDevice}__img`}>
            <img src={`/img/content/${backgroundImg}.png`} alt="" />
          </div>
          <div className={`gallery__${typeDevice}__content`}>
            <div className="gallery__row">
              <SettingsGalleryTitle title={title} titleName={title.name} />
            </div>
            <div
              className="gallery__row"
              style={{
                margin: `0 -${general.paddingHorizontal}px`,
              }}
            >
              <SettingsGalleryItem
                informerStyle={informerStyle}
                news={news.length ? news : testNews}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SettingGallery.propTypes = {
  typeDevice: string,
  informerSettings: exact(iInformerSettings),
  site: exact(iSite),
};

const mapStateToProps = ({ informerSettings, informer, site }) => ({
  informerSettings,
  informer,
  site,
});

const mapDispatchToProps = {
  getNews: getSiteNews,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(SettingGallery));
