import { getUser } from '../../../services/localStorage';
import {
  CLEAR_USER,
  START_SIGN_IN_AUTH,
  SUCCESS_REFRESH_TOKEN,
  SUCCESS_REGISTRATION_AUTH,
  SUCCESS_SIGN_IN_AUTH,
} from '../../types';

const initialState = {
  user: getUser(),
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case START_SIGN_IN_AUTH:
      return state;

    case SUCCESS_SIGN_IN_AUTH: {
      return {
        ...state,
        user: action.payload,
      };
    }

    case CLEAR_USER:
      return {
        ...state,
        user: {},
      };

    case SUCCESS_REGISTRATION_AUTH: {
      return {
        ...state,
        user: action.payload,
      };
    }

    case SUCCESS_REFRESH_TOKEN:
      return {
        ...state,
        user: action.payload,
      };

    default:
      return state;
  }
}
