import {
  EDIT_SITE_FORM,
  FAILURE_FETCH_CHART_INFORMERS,
  FAILURE_FETCH_CHART_SITE_STATISTICS,
  FAILURE_FETCH_SITE,
  FAILURE_FETCH_SITE_NEWS,
  FAILURE_FETCH_TABLE_INFORMERS,
  FAILURE_FETCH_TABLE_SITE_STATISTICS,
  FETCH_SITES_LIST,
  START_FETCH_CHART_INFORMERS,
  START_FETCH_CHART_SITE_STATISTICS,
  START_FETCH_SITE,
  START_FETCH_SITE_NEWS,
  START_FETCH_TABLE_INFORMERS,
  START_FETCH_TABLE_SITE_STATISTICS,
  SUCCESS_FETCH_CHART_INFORMERS,
  SUCCESS_FETCH_CHART_SITE_STATISTICS,
  SUCCESS_FETCH_SITE,
  SUCCESS_FETCH_SITE_NEWS,
  SUCCESS_FETCH_TABLE_INFORMERS,
  SUCCESS_FETCH_TABLE_SITE_STATISTICS,
} from 'redux/types';

import {
  editSiteById,
  getInformersBySite,
  getInformersStatisticBySite,
  getSiteById,
  getSiteNewsApi,
  getSitesListApi,
  getSiteStatisticsChart,
  getSiteStatisticsTable,
} from 'api';

import { basicAction } from '../helpers/actions';

export const fetchSite = id => dispatch => {
  dispatch({
    type: START_FETCH_SITE,
  });

  return getSiteById(id)
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_SITE,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_SITE,
        payload: error,
        notification: {
          type: 'error',
          text: `Fetch site has failure. Status ${error.status}`,
        },
      });
    });
};

export const fetchChart = params => dispatch => {
  dispatch({
    type: START_FETCH_CHART_INFORMERS,
  });

  return getInformersStatisticBySite(params)
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_CHART_INFORMERS,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_CHART_INFORMERS,
        payload: error,
        notification: {
          type: 'error',
          text: `Fetch chart informers of sites has failure. Status ${error.status}`,
        },
      });
    });
};

export const fetchTable = params => dispatch => {
  dispatch({
    type: START_FETCH_TABLE_INFORMERS,
  });

  return getInformersBySite(params)
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_TABLE_INFORMERS,
        payload: {
          ...payload.data,
          ...params,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_TABLE_INFORMERS,
        payload: error,
        notification: {
          type: 'error',
          text: `Fetch table informers of sites has failure. Status ${error.status}`,
        },
      });
    });
};

export const fetchStatisticsChart = params => dispatch => {
  dispatch({
    type: START_FETCH_CHART_SITE_STATISTICS,
  });
  return getSiteStatisticsChart(params)
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_CHART_SITE_STATISTICS,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_CHART_SITE_STATISTICS,
        payload: error,
        notification: {
          type: 'error',
          text: `Fetch chart of site statistics has failure. Status ${error.status}`,
        },
      });
    });
};

export const fetchStatisticsTable = params => dispatch => {
  dispatch({
    type: START_FETCH_TABLE_SITE_STATISTICS,
  });

  return getSiteStatisticsTable(params)
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_TABLE_SITE_STATISTICS,
        payload: {
          ...payload.data,
          ...params,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_TABLE_SITE_STATISTICS,
        payload: error,
        notification: {
          type: 'error',
          text: `Fetch site statistics has failure. Status ${error.status}`,
        },
      });
    });
};

export const getSiteNews = params => dispatch => {
  dispatch({
    type: START_FETCH_SITE_NEWS,
  });

  return getSiteNewsApi(params)
    .then(response => {
      const { data } = response;

      dispatch({
        type: SUCCESS_FETCH_SITE_NEWS,
        payload: data,
      });
    })
    .catch(fail => {
      dispatch({
        type: FAILURE_FETCH_SITE_NEWS,
        fail,
      });
    });
};

export const editSite = params => dispatch =>
  basicAction({
    apiCall: () => editSiteById(params),
    type: EDIT_SITE_FORM,
    payloadData: params,
  })(dispatch);

export const getSites = params => dispatch =>
  basicAction({
    apiCall: () => getSitesListApi(params),
    type: FETCH_SITES_LIST,
  })(dispatch);
