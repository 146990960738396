import React from 'react';
import { string, shape, func } from 'prop-types';
import { withTranslation } from 'react-i18next';

import ValidateInput from 'components/ValidateInput';
import { mustBeFilled, mustBeNumber } from 'config/validationRules';

import { ValidatorContext } from 'context/ValidatorContext';
import { Icon, Col, Row, Divider } from 'antd';
import { colorGrey } from '../../../styles/js/variables/colors';

const DistanceSettings = ({ element, item, onChange, prefix, isMargin, t }) => {
  const distanceType = isMargin ? 'margin' : 'padding';
  const { actions } = React.useContext(ValidatorContext);
  const inputParams = {
    placeholder: '0',
    fluid: true,
    type: 'number',
    onChange: response => onChange({ item, response }),
  };

  return (
    <>
      <Divider>
        <div>{t('basic.distance')}</div>
        <div style={{ opacity: 0.5, fontSize: '0.75em' }}>
          ({t(`basic.${distanceType}`)})
        </div>
      </Divider>
      <Row type="flex" gutter={[24, 24]}>
        <Col xs={8} />
        <Col xs={8}>
          <ValidateInput
            name={`${distanceType}Top`}
            validateName={`${prefix}-${distanceType}Top`}
            defaultValue={element[`${distanceType}Top`]}
            value={element[`${distanceType}Top`]}
            controlled
            {...inputParams}
            rules={[mustBeFilled, mustBeNumber]}
            onValidate={input => actions.validate(input)}
            onChange={value =>
              onChange({
                item,
                response: { name: `${distanceType}Top`, value },
              })
            }
          />
        </Col>
        <Col xs={8} />
      </Row>
      <Row type="flex" gutter={[24, 24]}>
        <Col xs={8}>
          <ValidateInput
            name={`${distanceType}Left`}
            validateName={`${prefix}-${distanceType}Left`}
            defaultValue={element[`${distanceType}Left`]}
            value={element[`${distanceType}Left`]}
            controlled
            {...inputParams}
            rules={[mustBeFilled, mustBeNumber]}
            onValidate={input => actions.validate(input)}
            onChange={value =>
              onChange({
                item,
                response: { name: `${distanceType}Left`, value },
              })
            }
          />
        </Col>
        <Col xs={8} style={{ textAlign: 'center' }}>
          <Icon
            type="fullscreen"
            style={{
              rotate: '45deg',
              color: colorGrey,
              fontSize: '3em',
            }}
          />
        </Col>
        <Col xs={8}>
          <ValidateInput
            name={`${distanceType}Right`}
            validateName={`${prefix}-${distanceType}Right`}
            defaultValue={element[`${distanceType}Right`]}
            value={element[`${distanceType}Right`]}
            controlled
            {...inputParams}
            rules={[mustBeFilled, mustBeNumber]}
            onValidate={input => actions.validate(input)}
            onChange={value =>
              onChange({
                item,
                response: { name: `${distanceType}Right`, value },
              })
            }
          />
        </Col>
      </Row>
      <Row type="flex" gutter={[24, 24]}>
        <Col xs={8} />
        <Col xs={8}>
          <ValidateInput
            name={`${distanceType}Bottom`}
            validateName={`${prefix}-${distanceType}Bottom`}
            defaultValue={element[`${distanceType}Bottom`]}
            value={element[`${distanceType}Bottom`]}
            controlled
            {...inputParams}
            rules={[mustBeFilled, mustBeNumber]}
            onValidate={input => actions.validate(input)}
            onChange={value =>
              onChange({
                item,
                response: { name: `${distanceType}Bottom`, value },
              })
            }
          />
        </Col>
        <Col xs={8} />
      </Row>
      <br />
    </>
  );
};

DistanceSettings.propTypes = {
  element: shape({
    paddingTop: string,
    paddingBottom: string,
    paddingRight: string,
    paddingLeft: string,
    marginTop: string,
    marginBottom: string,
    marginRight: string,
    marginLeft: string,
  }).isRequired,
  item: string.isRequired,
  onChange: func.isRequired,
};

export default withTranslation()(DistanceSettings);
