import React, { Fragment } from 'react';
import { node, oneOfType, string, shape, func } from 'prop-types';
import { withTranslation } from 'react-i18next';

import FormActions from '../../Form/components/FormActions';

const ConfirmModal = ({
  actions,
  onConfirm,
  onClose,
  submitType,
  submitTitle,
  titleText,
  contentText,
  isLoadingConfirm,
  t,
}) => {
  const handleConfirm = () => {
    actions.onConfirm();
    onConfirm();

    return !isLoadingConfirm && onClose();
  };

  return (
    <Fragment>
      <h2>{titleText}</h2>

      <div>{contentText}</div>

      <FormActions
        style={{ textAlign: 'right' }}
        titleSubmit={submitTitle || t('form.buttonConfirm')}
        onCancel={onClose}
        onSubmit={handleConfirm}
        type={submitType}
        isLoading={isLoadingConfirm}
      />
    </Fragment>
  );
};

ConfirmModal.propTypes = {
  onClose: func.isRequired,
  onConfirm: func,
  actions: shape({}),
  titleText: oneOfType([string, node]),
  contentText: oneOfType([string, node]),
  t: func.isRequired,
};

ConfirmModal.defaultProps = {
  onConfirm: () => {},
  actions: {},
  titleText: '',
  contentText: '',
};

export default withTranslation('common')(ConfirmModal);
