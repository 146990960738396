import React from 'react';
import { func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Icon } from 'antd';

// Components
import PageTitle from '../../components/PageTitle';
import { TermsItem, TermsWrapper } from './styles';
import { colorGreen } from '../../styles/js/variables/colors';

const Terms = ({ t }) => {
  const websiteTitle = process.env.REACT_APP_DOMAIN_NAME;
  const adminMail = process.env.REACT_APP_ADMIN_MAIL;

  return (
    <>
      <PageTitle
        title={t('content.title.conditions')}
        hint={`${t('content.titleHint.conditions')} ${websiteTitle}`}
        centered
      />
      {/* eslint-disable */}
      <TermsWrapper>
        <TermsItem>
          <b>1. Общие положения</b>
          <ul>
            <li>
              1.1. Использование сайта {websiteTitle} («сайт») означает согласие
              пользователя на соблюдение всех условий, изложенных в этих
              правилах пользования сайтом (далее - «Правила»).
            </li>
            <li>
              1.2. Мы можем изменять, добавлять или удалять части настоящих
              Правил предоставления услуг в любое время и такие изменения
              вступают в силу немедленно после публикации. Обязанность
              рассмотреть эти Правила перед каждым использованием сайта и/или
              продолжением использовать его возлагается исключительно на
              пользователя и приравнивается к безоговорочному согласию на любые
              изменения, опубликованные на сайте.
            </li>
            <li>
              1.3. Мы можем изменить, приостановить или прекратить в любое время
              доступ к сайту, включая доступность любой информации, базы данных
              или контента. Мы можем накладывать ограничения на определенные
              функции или ограничивать ваш доступ к части или всего сайта без
              уведомления и без возникновения у нас ответственности.
            </li>
          </ul>
        </TermsItem>

        {/* label: 2 */}
        <TermsItem>
          <b>2. Защита информации</b>
          <p>
            Ограничение ответственности за достоверность полученной информации.
          </p>
          <ul>
            <li>
              <p>
                2.1. Мы не проверяем достоверность полученной о пользователях
                информации и не контролируем правосубъектность пользователей.
              </p>
              <p>
                Информация, которая может быть обработана в процессе
                взаимодействия с пользователем:
              </p>
              <ul>
                <li>
                  Информация, предоставляемая пользователями социальных сетей -
                  при использовании сайта может использоваться
                  предоставленная​​пользователемличнаяинформация.
                </li>
                <li>
                  Информация о посещении - при доступе к службам сайта через
                  браузер, программу или другой клиент, серверы автоматически
                  записывают определенную информацию. Эти журналы сервера могут
                  содержать информацию о веб-запросах, IP-адресе, типе и языке
                  браузера, дате и времени запроса, а также содержать один или
                  несколько файлов cookie, по которым можно определить браузер
                  или аккаунт пользователя.
                </li>
                <li>
                  Когда пользователь в процессе взаимодействия при использовании
                  сайта отправляет сообщение, в том числе в службу поддержки, -
                  возможно хранение и обработка этих сообщений для ответов на
                  вопросы и дальнейшего совершенствования функционирования
                  сайта. Для связи с пользователем по вопросам работы сайта
                  может использоваться адрес электронной почты, предоставленный
                  пользователем.
                </li>
              </ul>
              <p>
                Сайт не контролирует сайты, которые используют продукты и услуги
                сайта. Эти сайты могут размещать на Ваш компьютер собственные
                файлы cookie, собирать данные или запрашивать у пользователей
                социальных сетей личную информацию, предоставляемую последними
                на свой страх и риск.
              </p>
              <p>
                Кроме этого, информация, предоставляемая пользователями
                социальных сетей, может быть использована в следующих целях:
              </p>
              <ul>
                <li>поддержка, защита и улучшение сайта.</li>
                <li>защита прав и собственности сайта и пользователей.</li>
              </ul>
              <p>
                Настоящим подтверждается, что использование информации,
                указанной пользователем, разрешено для целей, предусмотренных
                настоящими Правилами.
              </p>
              <p>
                Большинство браузеров изначально настроены на получение файлов
                cookie, однако вы можете сбросить эти настройки и указать, чтобы
                браузер блокировал все файлы cookie или оповещал об отправке
                этих файлов. В случае отключения возможна некорректная работа
                некоторых разделов сайта.
              </p>
              <p>
                Материалы, опубликованные на сайте, предназначены для личного
                некоммерческого использования. Все материалы, опубликованные на
                сайте защищены авторским правом и находятся во владении или под
                контролем сайта или стороны, является поставщиком контента. Вы
                должны соблюдать все предварительного уведомления об авторских
                правах, информации или ограничений, содержащихся на любой
                странице.
              </p>
            </li>
            <li>
              2.2. Содержание сайта защищено авторским правом в соответствии с
              законодательством Украины и международного авторского права. Вы не
              имеете права изменять, публиковать, передавать, участвовать в
              передаче или продаже, воспроизводить (за исключением случаев,
              предусмотренных в пункте 2.3, 2.4 и 2.5 настоящих Правил),
              создавать новые произведения, распространять, исполнять, или
              каким-либо образом использовать содержание сайта (включая
              программное обеспечение) в целом или в части.
            </li>
            <li>
              2.3. Частный пользователь может скачать или скопировать содержимое
              сайта, другие компоненты и элементы, отображаемые на сайте, только
              для личного использования, при условии соблюдения всех авторских
              прав и других сообщений, содержащихся в нем.
            </li>
            <li>
              2.4. Интернет-изданиям разрешается бесплатно использовать
              информацию, размещенную на сайтах сайта, при условии открытого
              гиперссылки и упоминания первоисточника не ниже первого абзаца.
              Для печатных изданий перепечатка материалов сайта разрешается на
              гонорарной основе и только с письменного согласия сайта.
              Использовать информацию, размещенную на сайтах сайта, разрешено
              при условии ссылки на первоисточник. Под использованием информации
              подразумевается любое воспроизведение, републикация,
              распространение, переработка, перевод, включение его частей в
              другие произведения и другие способы, предусмотренные Законом
              Украины «Об авторском праве и смежных правах».
            </li>
          </ul>
        </TermsItem>

        {/* label: 3 */}
        <TermsItem>
          <b>3. Условия сотрудничества для рекламных площадок</b>
          <ul>
            <li>3.1. Требования к сайтам – партнерам.</li>
            <li>
              3.1.1. Требования к сайтам, принимаемым к сотрудничеству:
              <ul>
                <li>
                  посещаемость вашей площадки должна быть не менее 5000
                  посетителей в день;
                </li>
                <li>
                  в систему принимаются сайты содержащие статьи, обзоры, новости
                  и т.д., желательно наличие фотографий и/или видероликов;
                </li>
                <li>
                  мы не принимаем фотогалереи, цитатники, а также любые другие
                  ресурсы которые не имеют информационной наполненности;
                </li>
                <li>
                  все материалы, используемые для новостей должны быть оформлены
                  по правилам орфографии и пунктуации.
                </li>
              </ul>
            </li>
            <li>
              3.1.2. К сотрудничеству не принимаются сайты, содержащие: контент
              об алкоголе;
              <ul>
                <li>контент о наркотиках;</li>
                <li>нецензурную лексику;</li>
                <li>
                  вульгарный, непристойный, эротический и/или порнографический
                  контент;
                </li>
                <li>
                  любой дискриминационный контент в отношении какой-либо расы,
                  национального происхождения, религии, инвалидности или
                  физических недостатков, половой принадлежности, возрасту или
                  сексуальной ориентации;
                </li>
                <li>
                  контент агитационного характера и контент направленный на
                  дискредитацию политики и/или партии, новости призывающие к
                  экстремизму;
                </li>
                <li>
                  контент пропагандирующий тотализаторы, лотереи или азартные
                  игры;
                </li>
                <li>
                  контент рекламирующий табак, табачные изделия и курительные
                  принадлежности, в том числе трубки, кальяны и другие подобные
                  товары;
                </li>
                <li>
                  контент о лекарственных средствах и биологически активных
                  добавках (БАДов);
                </li>
                <li>сайты религиозных организаций;</li>
                <li>
                  загружающие на компьютеры пользователей вирусы, трояны или
                  другие вредоносные программы;
                </li>
                <li>
                  не соответствующие требованиям действующего законодательства
                  страны, в которой они транслируются;
                </li>
                <li>
                  администрация системы оставляет за собой право отказать в
                  сотрудничестве той или иной рекламной площадке без объяснения
                  причин отказа.
                </li>
              </ul>
            </li>
            <li>
              3.2. В системе запрещено:
              <ul>
                <li>
                  использование любых способов накрутки, в том числе с участием
                  третьих лиц, шифрование ява-скриптов для накрутки,
                  использование букс-систем, почтовых и ICQ-рассылок;
                </li>
                <li>
                  изменение кода информеров, переданных партнеру для установки
                  на его сайт;
                </li>
                <li>изменение содержания транслируемых рекламных блоков;</li>
                <li>
                  размещать рекламные блоки на страницах, которые автоматически
                  переходят на другие страницы без желания пользователя, в
                  движущейся строке, на странице или во фрейме, который
                  обновляется без каких-либо действий пользователя, во
                  всплывающих окнах, в блоках приложений и пр. без
                  предварительного согласования подобного метода размещения с
                  сотрудниками компании Сайт;
                </li>
                <li>
                  размещать над рекламой сети фразы (призывы) или изображения,
                  которые искусственно привлекают внимание посетителей к блокам
                  рекламы с помощью любых графических и текстовых элементов или
                  приемов (в том числе на фоне видеоплеера);
                </li>
                <li>
                  преднамеренно размещать рекламный блок поверх других элементов
                  сайта, что приводит к непринуждённым кликам по рекламному
                  блоку;
                </li>
                <li>
                  размещать рекламный блок в тулбарах, на поддоменах, других
                  сайтах, которые не зарегистрированы в системе и не принадлежат
                  Вашему аккаунту без предварительного согласования с
                  сотрудниками компании Сайт;
                </li>
                <li>
                  запрещается подмена реферера прямо или косвенно без
                  согласования с сотрудниками компании Сайт.
                </li>
              </ul>
            </li>
            <li>
              3.3. Условия вывода заработанных средств вручную:
              <ul>
                <li>
                  минимальная сумма вывода начислений –эквивалент $20 в
                  национальной валюте Украины (либо в выбранной валюте учётной
                  записи);
                </li>
                <li>
                  выплаты осуществляются по предварительной заявке партнера;
                </li>
                <li>интервал между подачами заявок не менее 15 дней;</li>
                <li>минимальный срок рассмотрения заявки – 7 дней;</li>
                <li>выплаты осуществляются каждый четверг текущего месяца;</li>
                <li>
                  компания оставляет за собой право использовать инструменты
                  сторонних сервисов для сбора статистических данных
                  характеризующих посетителей сайта-партнера.
                </li>
              </ul>
            </li>
            <li>
              3.4. Условия вывода заработанных средств автоматически:
              <ul>
                <li>
                  минимальная сумма для автоподачи заявки - эквивалент $20 в
                  национальной валюте Украины (либо в выбранной валюте учётной
                  записи);
                </li>
                <li>
                  заявка формируется автоматически в 02:00 каждый понедельник;
                </li>
                <li>интервал между подачами заявок не менее 15 дней;</li>
                <li>выплаты осуществляются каждый четверг текущего месяца.</li>
              </ul>
            </li>
            <li>3.5. Условия для рекламодателей.</li>
            <li>
              3.5.1. Мы размещаем рекламные объявления, содержащие изображение и
              рекламный текст на нашем сайте и сайтах наших партнеров. Вы
              платите только за переходы с рекламных объявлений на Ваш сайт.
            </li>
            <li>
              3.5.2. Мы обеспечиваем географический таргетинг. У нас работает
              высокоэффективная система антинакрутки, позволяющая обеспечить
              реальный поток посетителей.
            </li>
            <li>
              3.5.3. Стоимость перехода от эквивалента 1 цента (в выбранной
              валюте учётной записи). Мы работаем по принципу аукциона – Вы
              можете сами устанавливать стоимость перехода для каждого
              рекламного объявления для каждого выбранного региона.
            </li>
            <li>
              3.5.4. Информационные материалы не должны содержать упоминания или
              ссылки на товары и услуги, противоречащие законодательству
              Украины, в частности Закону «О рекламе», настоящим Правилам, равно
              как и политике сайта, в том числе на:
              <ul>
                <li>азартные игры, игровые автоматы;</li>
                <li>
                  гипноз, ясновидение, целительство, гадание, за исключением
                  гороскопов и сонников;
                </li>
                <li>
                  оружие (пневматическое, выставочное, коллекционное оружие - по
                  отдельному согласованию с руководством Сайт);
                </li>
                <li>алкогольные напитки, наркотики, виртуальные наркотики;</li>
                <li>секс-шопы, эротическое, слишком откровенное белье;</li>
                <li>антирадары, защита номеров от камер ГАИ;</li>
                <li>услуги по наращиванию груди;</li>
                <li>техника соблазнения и пикапа;</li>
                <li>
                  табачная продукция, включая электронные сигареты, сигары,
                  трубки, кальяны, мундштуки;
                </li>
                <li>реализация таможенного конфиската;</li>
                <li>скачивание фильмов;</li>
                <li>
                  товары или услуги, направленные против прав и свобод человека
                  (анонимные смс, вскрытие домофонов и т.п.).
                </li>
              </ul>
            </li>
          </ul>
        </TermsItem>

        {/* label: 4 */}
        <TermsItem>
          <b>
            4. Контент для пользователей: используемые персональные данные,
            новостные обзоры и тому подобное.
          </b>
          <ul>
            <li>
              4.1. Сайт не имеет возможности контролировать весь материал и не
              несет ответственности за содержание информационных сообщений. Сайт
              оставляет за собой право удалять, перемещать или редактировать
              новостные публикации, которые, по собственному усмотрению, считает
              оскорбительными, клеветническими, непристойными, нарушением
              авторских прав и товарных знаков, или неприемлемыми.
            </li>
            <li>
              4.2. Материалы могут содержать ссылки, связанные с другими
              Интернет-сайтами, ресурсами и рекламодателями. Так как сайт не
              несет ответственности за доступность этих внешних ресурсов или их
              содержание, пользователи должны направлять любое обращение
              относительно любого внешнего ссылки администратору
              соответствующего сайта.
            </li>
            <li>
              4.3. Сайт не гарантирует и не подтверждает точности или надежности
              любых советов, мнений, заявлений или иной загруженной, или
              распространения информации поставщиков информации или любых других
              физических или юридических лиц. Пользователь признает и осознает,
              что полагание на такие мысли, советы, заявления, меморандумы или
              иную информацию должно быть на свой страх и риск. Материалы сайта
              распространяются «КАК ЕСТЬ», без каких-либо гарантий включая
              гарантии на право собственности или пригодности для конкретных
              целей. Использованием сайте вы признаете свою самостоятельную
              деятельность на свой страх и риск.
            </li>
            <li>
              4.4. Пользователь предоставляет, а сайт оставляет за собой право
              отправлять на электронный адрес пользователей информацию об
              изменениях или дополнениях на сайте.
            </li>
            <li>
              4.5. Сайт оставляет за собой право раскрывать информацию об
              использовании и демографии при условии, что он не будет раскрывать
              личность пользователя. Рекламодатели на сайте могут собирать и
              распространять личную информацию о пользователях.
            </li>
            <li>
              4.6. Сайт принимает все необходимые меры для защиты данных от
              несанкционированного доступа, изменения, раскрытия или уничтожения
              информации. К этим мерам относятся, в частности, внутренняя
              проверка процессов сбора, хранения и обработки данных и мер
              безопасности, включая соответствующее шифрование и меры по
              обеспечению физической безопасности данных для предотвращения
              неавторизованного доступа к системам, в которых мы храним личные
              данные.
            </li>
            <li>
              4.7. Сайт может связаться с пользователем по электронной почте об
              участии в опросе пользователей, задавая вопросы с целью улучшения
              текущих или перспективных материалов. Эта информация будет
              использована для улучшения работы сайтов и для лучшего понимания
              наших пользователей. Любая информация, которую сайт получает в
              таких опросах, не будет передана третьим лицам, за исключением
              обобщенной формы без раскрытия личности.
            </li>
            <li>
              4.8. Пользователь не имеет никаких прав на проприетарное
              программное обеспечение и сопроводительную документацию, или на
              любые улучшения или изменения к ним. Пользователь не может
              сублицензировать, уступать или передавать любые лицензии,
              предоставленные сайтом, и любая попытка такого сублицензированию,
              присвоение или передачи считается недействительной и
              противоправной.
            </li>
            <li>
              4.9. Сайт может, по своему усмотрению, удалить или приостановить
              доступ ко всему сайту или его части по любой причине, включая,
              нарушение или невыполнение этих Правил.
            </li>
            <li>
              4.10. В некоторых случаях сайт оставляет за собой право
              публиковать отдельные примечания о конфиденциальности для
              определенных продуктов, а также в любое время изменять политику
              конфиденциальности по своему усмотрению.
            </li>
            <li>
              4.11. Сервисы сайта могут содержать ссылки на другие веб-сайты.
              Сайт не несет ответственности за политику конфиденциальности или
              содержание этих сайтов.
            </li>
            <li>
              4.12. Согласие на обработку персональных данных. В случае принятия
              изложенных выше условий физическое лицо, производящее акцепт
              настоящих Правил, становится Пользователем (акцепт настоящих
              Правил равносилен заключению договора в письменном виде, на
              условиях, изложенных в настоящих Правилах). Также если Вы
              физическое лицо - Пользователь, произведя акцепт этихПравил, тем
              самым дает письменное согласие на обработку своих персональных
              данных.
              <br />
              Пользователь (субъект персональных данных) дает свое согласие на
              сбор, систематизацию, накопление, хранение, уточнение (обновление,
              изменение), использование, распространение, обезличивание,
              блокирование, уничтожение следующих персональных данных:
              <table
                align="center"
                style={{ margin: '2em auto' }}
                cellPadding={10}
              >
                <thead>
                  <tr>
                    <th>Персональные данные</th>
                    <th>Согласие</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Фамилия</td>
                    <td align="center">
                      <Icon type="check" style={{ color: colorGreen }} />
                    </td>
                  </tr>
                  <tr>
                    <td>Имя</td>
                    <td align="center">
                      <Icon type="check" style={{ color: colorGreen }} />
                    </td>
                  </tr>
                  <tr>
                    <td>Отчество</td>
                    <td align="center">
                      <Icon type="check" style={{ color: colorGreen }} />
                    </td>
                  </tr>
                  <tr>
                    <td>Год, месяц, дата рождения</td>
                    <td align="center">
                      <Icon type="check" style={{ color: colorGreen }} />
                    </td>
                  </tr>
                  <tr>
                    <td>Место рождения</td>
                    <td align="center">
                      <Icon type="check" style={{ color: colorGreen }} />
                    </td>
                  </tr>
                  <tr>
                    <td>Адрес</td>
                    <td align="center">
                      <Icon type="check" style={{ color: colorGreen }} />
                    </td>
                  </tr>
                  <tr>
                    <td>Паспортные данные (серия, номер, кем и когда выдан)</td>
                    <td align="center">
                      <Icon type="check" style={{ color: colorGreen }} />
                    </td>
                  </tr>
                  <tr>
                    <td>Гражданство</td>
                    <td align="center">
                      <Icon type="check" style={{ color: colorGreen }} />
                    </td>
                  </tr>
                  <tr>
                    <td>Контактная информация (телефон, e-mail)</td>
                    <td align="center">
                      <Icon type="check" style={{ color: colorGreen }} />
                    </td>
                  </tr>
                  <tr>
                    <td>Номер лицевого счета</td>
                    <td align="center">
                      <Icon type="check" style={{ color: colorGreen }} />
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              Целью предоставления и обработки персональных данных является
              заключение договора-оферты (Пользовательского соглашения) с
              Пользователем, но также, сайт предоставляет доступ к личной
              информации другим сайтам и частным лицам, которые не имеют
              отношения к сайту, при таких ограниченных обстоятельствах:
              <br />
              <ul>
                <li>
                  Имеет место наличие разрешения пользователя. Для передачи
                  любой конфиденциальной информации сайта требуется явное
                  согласие пользователя.
                </li>
                <li>
                  Сайт предоставляет такого рода информацию дочерним и
                  аффилированным сайтам, а также другим доверенным организациям
                  и лицам только в целях обработки информации от имени сайта.
                </li>
                <li>
                  У сайта есть основания полагать, что доступ, использование,
                  сохранение или раскрытие такой информации необходимо для:
                  <ul>
                    <li>
                      соблюдение любых действующих законов, постановлений, или
                      действительного запроса из государственных органов;
                    </li>
                    <li>расследование потенциальных нарушений;</li>
                    <li>
                      при обнаружении и предотвращении мошеннических действий, а
                      также решении проблем безопасности и устранения
                      технических неполадок.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Сайт предоставляет доступ к информации пользователей только тем
                сотрудникам, подрядчикам и агентам сайта, которым необходимо
                иметь эту информацию для осуществления операций, выполняемых от
                нашего имени.
              </p>
              <p>
                Пользователь, используя сайт, принял условия этой политики
                конфиденциальности сайта и учитывает, что данная политика
                конфиденциальности может время от времени меняться. Изменения,
                вносимые в политику конфиденциальности, публикуются по ссылке{' '}
                {websiteTitle}/tos.
              </p>
              <p>
                Для вопросов политики конфиденциальности, Пользователь может
                связаться с компанией через веб-сайт или используя e-mail:
                {adminMail}
              </p>
              <p>
                Согласие на обработку персональных данных действительно в
                течение срока действия Правил с момента их акцепта. Пользователь
                уведомлен(а) о своем праве отозвать согласие путем подачи
                письменного заявления. Отказ от предоставления своих
                персональных данных влечет невозможность дальнейшего
                сотрудничества между сайтом и Пользователем (физическим лицом).
                Пользователь подтверждает, что права и обязанности в области
                защиты персональных данных ему разъяснены. Кроме того,
                Пользователь уведомлен(а), что сайтимеет право предоставлять
                информацию по официальному запросу третьих лиц только в
                установленных законом случаях.
              </p>
              <p>
                Сайт не несет ответственности ни за какие задержки, сбои,
                неверную или несвоевременную публикацию, удаление или не
                сохранность какой-либо размещенной Пользователем информации.
              </p>
              <p>
                Пользователь признает, что сайт может поменять правила и
                ограничения в любое время с или без предварительного
                уведомления.
              </p>
              <p>
                Пользователь отчуждает сайт исключительные права на созданные
                материалы в полном объеме для использования его любым способом и
                в любой форме.
              </p>
              <p>
                Исключительные права на созданные материалы, а также его части,
                фрагменты, версии, передаются Пользователем сайту на срок их
                действия. Срок действия исключительных прав на созданные
                материалы составляет 25 лет. Действие передаваемых
                исключительных прав не ограничивается территориальными
                пределами.
              </p>
              <p>
                Пользователь не сохраняет за собой право использовать созданные
                материалы самостоятельно или предоставлять аналогичные права на
                его использование третьим лицам. Исключительное право, как на
                созданные материалы, так и на любые его части, а также на любые
                варианты и фрагменты материалов, созданных Пользователем,
                считается перешедшим к сайту в момент придания созданным
                материалам объективной формы.
              </p>
              <p>
                Сайт вправе передавать (предоставлять) любым другим лицам
                полученные права на созданные материалы. Согласия Пользователя
                на такую передачу (предоставление) не требуется.
              </p>
              <p>
                Сайт вправе использовать созданные материалы, его части,
                варианты и фрагменты полностью или частично (фрагментарно,
                выборочно), совместно либо по отдельности, одновременно с
                другими объектами авторского права, а также вправе включать их в
                другие созданные материалы.
              </p>
            </li>
          </ul>
        </TermsItem>

        {/* label: 5 */}
        <TermsItem>
          <b>5. Контакты</b>
          <ul>
            <li>5.1. Переписка будет осуществляться по адресу {adminMail}.</li>
            <li>
              5.2. Пользователь соглашается немедленно сообщать о любых
              нарушениях авторских прав по материалам сайта. В случае
              возникновения претензий с Вашей стороны о нарушении авторских прав
              по материалам, которые содержатся на сайте, пожалуйста, сообщите
              по адресу {adminMail}.
            </li>
          </ul>
        </TermsItem>

        {/* label: 6 */}
        <TermsItem>
          <b>6. Права собственности сайта</b>
          <p>
            Пользователь признает и соглашается с тем, что сервисы сайта и все
            необходимые программы защищены авторскими правами, торговыми
            марками, патентами, законами об интеллектуальной собственности и
            прочими соответствующими законодательству Украины, законами других
            государств и международным правом.
          </p>
          <p>
            Сайт предоставляет Пользователю личное неисключительное и
            непередаваемое право использовать программное обеспечение,
            предоставляемое в сервисах сайт, при условии, что ни Пользователь,
            ни иные лица при содействии с Пользователем не будут копировать или
            изменять программное обеспечение; создавать программы, производные
            от программного обеспечения; проникать в программное обеспечение с
            целью получения кодов программ; осуществлять продажу, сдачу в
            аренду, передачу третьим лицам каких-либо прав относительно
            программного обеспечения сервисов, предоставляемых Пользователю, а
            также модифицировать сервисы, в том числе с целью получения
            несанкционированного доступа к ним.
          </p>
          <p>
            Любое использование объектов интеллектуальной собственности сайта (в
            том числе элементов оформления сайта, символики, текстов и т.д.) без
            разрешения сайта является незаконным и может послужить причиной для
            судебного разбирательства.
          </p>
        </TermsItem>

        {/* label: 7 */}
        <TermsItem>
          <b>7. Освобождение от гарантий</b>
          <p>
            Сайт не гарантирует, что возможности сервисов сайта будут
            предоставляться непрерывно, быстро, надежно и без ошибок.
          </p>
          <p>
            Сайт освобождается от гарантий на материалы и информацию,
            создаваемые третьими лицами и размещаемые ими на сайте.
          </p>
          <p>
            В случае предъявления к сайту со стороны третьих лиц каких-либо
            претензий, требований и исков относительно нарушения авторских прав
            на материалы, размещенные Пользователем, а также относительно
            отсутствия согласия лиц, изображенных на фотографиях, Пользователь
            обязуется самостоятельно предпринять все меры по урегулированию
            данных претензий.
          </p>
          <p>
            Пользователь несет полную ответственность за нарушение имущественных
            и личных неимущественных прав авторов и прав любых третьих лиц, а
            также обязуется возместить ущерб, причиненный сайту в результате
            претензий авторов и третьих лиц в связи с нарушением таких прав.
          </p>
          <p>
            Сайт может, но не обязан, просматривать, изменять или удалять
            информацию, размещенную Пользователем. Сайт старается обеспечивать
            бесперебойную работу своих сервисов, однако не несет ответственности
            за полную или частичную потерю материалов, размещаемых
            Пользователем, а также за недостаточное качество или скорость
            предоставления сервисов.
          </p>
        </TermsItem>

        {/* label: 8 */}
        <TermsItem>
          <b>8. Разное</b>
          <ul>
            <li>
              8.1. Эти Правила были внесены и должны толковаться и применяться в
              соответствии с законами Украины. Любые споры по реализации этих
              Правил будут решаться соответствующим судом, расположенным на
              территории Украины.
            </li>
            <li>
              8.2. Настоящие Правила разработаны в соответствии с требованиями
              законодательства Украины, а также требований Европейских директив
              и рекомендаций по защите персональных данных, поскольку сервисы
              сайта являются общедоступными как для граждан Украины, так и для
              граждан Европейского Союза.
            </li>
            <li>
              8.3. Эти Правила создавалась с целью их доступности, понятности и
              прозрачности, ради чего Правила была разделены на разделы, которые
              указаны выше.
              <br />
              Эти Правила применяется к посетителям сайта, которые могут
              получать информацию, знакомиться с информацией на сайте,
              зарегистрироваться на сайте с помощью социальных сетей,
              распространять в социальных сетях информацию с сайта, предоставляя
              таким образом доступ сайта к собственной ip-адреса.
            </li>
            <li>
              8.4. Обращаем Ваше внимание на то, что сервисы сайта порой могут
              направлять посетителя к сервисам других компаний, таких как
              сервисы "like", "share", "twitt" социальных сетей Facebook,
              Instagram, Twitter и т.д., а также анонимовани данные могут
              собираться (далее - третьи лица). Такие третьи лица имеют
              собственные политики конфиденциальности и защиты персональных
              данных, таким образом следует помнить, что режим использования
              информации, которой посетитель сайта делится с такими третьими
              лицами подпадает под политики таких третьих лиц.
              <p>
                Сайт рассматривает вопросы защиты персональных данных как одно
                из наиболее важных вопросов в современном мире. Информацию,
                которой с нами делятся посетители, сайт защищает, используя
                соответствующие технические, организационные и административные
                меры, чтобы избежать рисков потери, незаконного доступа,
                распространения или разглашения.
              </p>
              <p>
                Однако, несмотря на все усилия, сайт не может гарантировать
                абсолютную защищенность от каких - либо угроз, которые могут
                возникнуть за пределами влияния сайта.
              </p>
              <p>
                Сайт оставляет за собой право, но не обязательство, заключать
                между Пользователем и сайтом другие дополнительные соглашения,
                регулирующие иной порядок отчуждения имущественных и
                неимущественных прав на размещаемые Пользователями материалы на
                сайте сайт.
              </p>
            </li>
          </ul>
        </TermsItem>

        {/* label: 9 */}
        <TermsItem>
          <b>9. Права, установленные применимым законодательством</b>
          <p>
            Учитывая неограниченность территориального доступа к сайту, и
            обязательность соответствовать положениям Общего регламента о защите
            персональных данных, в случае, если обрабатываются и собираются
            персональные данные граждан Европейского Союза, сайт приводит
            перечень прав посетителей сайта - резидентов Украины и резидентов
            Европейского Союза:
          </p>
          <ul>
            <li>
              9.1. Права посетителей сайта в соответствии с Законом Украины «О
              защите персональных данных»:
              <ul>
                <li>
                  - знать об источниках сбора, местонахождение своих
                  персональных данных, цели их обработки, местонахождение или
                  место жительства (пребывания) владельца или распорядителя
                  персональных данных или дать соответствующее поручение о
                  получении этой информации уполномоченным им лицам, кроме
                  случаев, установленных законом;
                </li>
                <li>
                  - получать информацию об условиях предоставления доступа к
                  персональным данным, включая информацию о третьих лицах,
                  которым передаются его персональные данные;
                </li>
                <li>- на доступ к своим персональным данным;</li>
                <li>
                  - получать не позднее чем за тридцать календарных дней со дня
                  поступления запроса, кроме случаев, предусмотренных законом,
                  ответ о том, обрабатываются его персональные данные, а также
                  получать содержание таких персональных данных;
                </li>
                <li>
                  - предъявлять мотивированное требование владельцу персональных
                  данных с возражением против обработки своих персональных
                  данных;
                </li>
                <li>
                  - предъявлять мотивированное требование об изменении или
                  уничтожение своих персональных данных любым владельцем и
                  распорядителем персональных данных, если эти данные
                  обрабатываются незаконно или являются недостоверными;
                </li>
                <li>
                  - на защиту своих персональных данных от незаконной обработки
                  и случайной потери, уничтожения, повреждения в связи с
                  умышленным сокрытием, непредоставлением или несвоевременным их
                  предоставлением, а также на защиту от предоставления сведений,
                  которые являются недостоверными или порочащих честь,
                  достоинство и деловую репутацию физического лица;
                </li>
                <li>
                  - обращаться с жалобами на обработку своих персональных данных
                  к Уполномоченному или в суд;
                </li>
                <li>
                  - применять средства правовой защиты в случае нарушения
                  законодательства о защите персональных данных;
                </li>
                <li>
                  - вносить оговорки об ограничении права на обработку своих
                  персональных данных при предоставлении согласия;
                </li>
                <li>- отозвать согласие на обработку персональных данных;</li>
                <li>
                  - знать механизм автоматической обработки персональных данных;
                </li>
                <li>
                  - на защиту от автоматизированного решения, которое имеет для
                  него правовые последствия.
                </li>
              </ul>
            </li>
          </ul>
        </TermsItem>
      </TermsWrapper>
      {/* eslint-enable */}
    </>
  );
};

Terms.propTypes = {
  t: func,
};

export default withTranslation()(Terms);
