import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Input } from 'antd';
import { Trans, withTranslation } from 'react-i18next';
import {
  analyticsSpeedUpAction,
  analyticsSpeedUpFileAdd,
} from '../../../redux/actions/sites';
import UploadImages from '../../UploadImages';
import WithToggleButton from '../../../HOC/WithToggleButton';

const AnalyticsSpeedUpForm = ({
  t,
  uploadedFiles,
  fileAdd,
  analyticsSpeedUp,
  onSuccess,
  isLoading,
  siteId,
}) => {
  const [description, setDetails] = useState('');

  const sendUploaded = () =>
    analyticsSpeedUp({ description, siteId, files: uploadedFiles }).then(
      onSuccess,
    );

  const onDetailsChange = e => setDetails(e.target.value);

  return (
    <div>
      <WithToggleButton
        buttonTitle={t('verificationSteps.analytics.analyticsSpeedUp')}
      >
        <div className="box">
          <div style={{ marginBottom: '1em' }}>
            <Trans
              i18nKey="verificationSteps.analytics.descriptionFast"
              email={process.env.REACT_APP_ADMIN_EMAIL}
            >
              Чтобы ускорить прохождение этапа аналитики, предоставьте
              пожалуйста доступ к статистике Google Analytics для пользователя{' '}
              <b>{{ email: process.env.REACT_APP_ADMIN_EMAIL }}</b>, либо
              пришлите нам скриншоты со статистикой за последние 5-7 дней,
              используя данную форму:
            </Trans>
          </div>
          <UploadImages onUpload={fileAdd} fileList={uploadedFiles} />
          <Input.TextArea
            placeholder={t('basic.comment')}
            onChange={onDetailsChange}
            style={{ margin: '1em 0' }}
          />
          <div style={{ textAlign: 'center' }}>
            <Button
              type="success"
              loading={isLoading}
              onClick={sendUploaded}
              disabled={uploadedFiles.length === 0 && description.length === 0}
            >
              {t('form.buttonSend')}
            </Button>
          </div>
        </div>
      </WithToggleButton>
    </div>
  );
};

AnalyticsSpeedUpForm.propTypes = {};

const mapStateToProps = ({ sites, network }) => ({
  isLoading: network.START_SITES_ANALYTICS_SPEED_UP,
  uploadedFiles: sites.uploadedFiles,
});

const mapDispatchToProps = {
  fileAdd: analyticsSpeedUpFileAdd,
  analyticsSpeedUp: analyticsSpeedUpAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(AnalyticsSpeedUpForm));
