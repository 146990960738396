import { defaultDate } from 'config/datesFormat';
import { unix } from 'moment';

import { truthyDecorator } from 'utils';

// Convert string to number
export const getNumber = maybeDate =>
  truthyDecorator(maybeDate)(date => +date.replace(',', '.').replace(/ /g, ''));

// Convert timestamp to default date
export const convertDate = maybeDate =>
  truthyDecorator(maybeDate)(date => unix(date).format(defaultDate));

// Convert data to chart's format
export const convertToChartsData = data =>
  data.reduce(
    (acc, chunk) => {
      const views = [...acc.views, chunk.views];
      const efficiencies = [...acc.efficiencies, chunk.efficiency];

      return { views, efficiencies };
    },
    { data, views: [], efficiencies: [] },
  );

export const convertNumberToFormattedString = num => {
  const isPercent = num % 1 !== 0;
  const regexpEveryThirdSymbol = /\B(?=(\d{3})+(?!\d))/g;
  const suffix = isPercent ? '%' : '';
  const result = isPercent ? num.toFixed(2) : String(num);

  return result.replace(regexpEveryThirdSymbol, ' ') + suffix;
};
