import React from 'react';
import { Button } from 'antd';
import { string } from 'prop-types';
import { FaExternalLinkAlt } from 'react-icons/fa';

const WrapWithLink = ({ isLink, subtitle, children }) =>
  isLink ? (
    <Button
      type="link"
      href={`//${subtitle}`}
      target="_blank"
      rel="noopener noreferrer"
      className="table-cell-subtitle"
    >
      {children}
    </Button>
  ) : (
    <div className="table-cell-subtitle">{children}</div>
  );

const TableRowTitle = props => {
  const { title, subtitle, icon: IconComponent = FaExternalLinkAlt } = props;

  return (
    <div style={{ textAlign: 'left' }}>
      <div>{title}</div>
      {subtitle && (
        <div
          className="dots-overflow"
          title={subtitle}
          style={{ maxWidth: '160px' }}
        >
          <WrapWithLink {...props}>
            <IconComponent size={10} />
            &nbsp;{subtitle}
          </WrapWithLink>
        </div>
      )}
    </div>
  );
};

TableRowTitle.propTypes = {
  title: string.isRequired,
  subtitle: string,
};

export default TableRowTitle;
