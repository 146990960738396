const objectToHash = obj =>
  Object.entries(obj).reduce((acc, [key, value], i, arr) => {
    return `${acc + key}=${value.join(',')}${i + 1 === arr.length ? '' : '&'}`;
  }, '#');

export const getHash = () =>
  window.location.hash
    ? window.location.hash
        .substring(1)
        .split('&')
        .map(v => v.split('='))
        .reduce((acc, [key, value]) => {
          return { ...acc, [key]: value.split(',') };
        }, {})
    : {};

export const setHash = (hashKey, values) => {
  const hashObj = getHash();

  // Set changes
  hashObj[hashKey] = values;

  const hashStr = objectToHash(hashObj);

  window.location.hash = hashStr;
  return hashStr;
};
