import React, { Fragment } from 'react';

// Components
import TableColumnTitle from '../components/TableColumnTitle';
import { isMobileDevice } from '../../../utils/layout';

import breakpoints from '../../../constants/breakpoints';
import Tooltip from '../../Tooltip';
// Utils
import { formatCell } from '../helpers/formatCell';
import columns from './columns';

const isMobile = isMobileDevice();

export const getSingleHeader = (key, params = {}, isFixed) => {
  const column = columns[key];
  const isSorted = params.sortBy === column.key;
  const { tooltip, name, key: columnKey } = column;
  const isFixedColumn =
    !isMobile && isFixed && window.innerWidth < breakpoints.desktop;

  const isClientSortEnabled = params.isClientPagination;

  const sorter = (a, b) => {
    const isString = typeof a[columnKey] === 'string';

    return isString
      ? a[columnKey].localeCompare(b[columnKey])
      : a[columnKey] - b[columnKey];
  };

  return {
    title: (
      <Fragment>
        {tooltip && <Tooltip tooltip={tooltip} />}
        <TableColumnTitle title={name} />
      </Fragment>
    ),
    fixed: isFixedColumn ? 'left' : null,
    className: 'tooltip-wrapper',
    dataIndex: columnKey,
    render: column.render || formatCell,
    ...column,
    ...params,
    ...(isClientSortEnabled && { sorter }),
    ...{ sortOrder: isSorted ? params.sortOrder : null },
  };
};

export const getSingleHeaders = (keys, params = {}) => {
  return keys.map(key => getSingleHeader(key, params));
};

export const getGroupHeaders = (keys, params = {}) => {
  const views = {
    title: 'ПРОСМОТРЫ',
    dataIndex: 'views',
    align: 'center',
    children: [],
  };

  const clicks = {
    title: 'КЛИКИ',
    dataIndex: 'clicks',
    children: [],
  };

  const keysToEl = {
    views,
    display: views,
    views_real: views,
    views_another: views,
    views_another_real: views,
    views_our: views,
    views_our_real: views,
    views_all: views,
    rpm: views,

    clicks,
    click_from: clicks,
    click_from_site: clicks,
    click_on: clicks,
    click_on_site: clicks,
    clicks_all: clicks,
    ko: clicks,
    ctr: clicks,
  };

  keys.forEach(key => {
    const column = columns[key];
    const { tooltip, name, key: columnKey } = column;

    keysToEl[key].children.push({
      title: (
        <Fragment>
          {tooltip && <Tooltip tooltip={tooltip} />}
          <TableColumnTitle title={name} />
        </Fragment>
      ),
      dataIndex: columnKey,
      align: 'center',
      className: 'tooltip-wrapper',
      render: formatCell,
      ...params,
      ...(params.sortBy === column.key
        ? { sortOrder: params.sortOrder }
        : { sortOrder: null }),
    });
  });

  return [views, clicks].filter(item => item.children.length);
};
