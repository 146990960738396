import _ from 'lodash';

export const joinClasses = classes => {
  if (classes instanceof Array) {
    return classes.join(' ');
  }
  if (classes instanceof Object) {
    return Object.values(classes).join(' ');
  }
  if (classes) {
    return classes.toString();
  }

  return '';
};

export const truthyDecorator = date => func => {
  if (date) return func(date);
  return null;
};

export const jsonPrettifier = badJson => {
  return badJson
    .replace(/'/g, '"')
    .replace(/True/g, 'true')
    .replace(/False/g, 'false');
};

/**
 * Inserts item to array with destructuring method if 'condition' === true
 *
 * @param {boolean} condition - Condition to check.
 * @param {any} item - Item for inserting.
 *
 * @return {array} - array with inserted item or empty array.
 *
 * Usage: [a, b, ...insertItemToArrayConditionally(true, 'c'), d, e]
 */

export const insertItemToArrayConditionally = (condition, item) =>
  condition ? [item] : [];

export const snakeToCamelCase = obj => {
  const result = {};

  Object.entries(obj).forEach(([key, value]) => {
    result[_.camelCase(key)] = value;
  });

  return result;
};

export const setIntervalFromStart = (callback, timeout) => {
  const startTime = performance.now();
  const id = setInterval(() => {
    callback(id, (performance.now() - startTime) / 1000);
  }, timeout);
  callback(id);
};
