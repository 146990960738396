import {
  ERROR_NOT_SAVED,
  ERROR_WITH_SERVER_MESSAGE
} from '../../constants/messages';

const stringMessage = ({ key, value }) =>
  `${key}: [${Array.isArray(value) ? value.join(', ') : value}]`;

const errorObjectToString = message =>
  Object.entries(message).reduce((acc, [key, value]) => {
    const isObject =
      typeof value === 'object' && !Array.isArray(value) && value !== null;
    const valueToString = isObject
      ? errorObjectToString(value)
      : stringMessage({ key, value });

    return `${acc}\n    ${valueToString}`;
  }, '');

export const showErrorSavingMessage = error => {
  let fieldsStr = '';

  try {
    const { message } = error.response.data;
    fieldsStr = errorObjectToString(message);
  } catch (e) {
    // console.error(e)
  }

  return `${ERROR_NOT_SAVED}. \n${ERROR_WITH_SERVER_MESSAGE}: ${fieldsStr}`;
};
