import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';

export const languagesList = {
  en: {
    shortCode: 'en',
    key: 'en',
    title: 'English',
  },
  ru: {
    shortCode: 'ru',
    key: 'ru',
    title: 'Русский',
  },
};

i18n
  .use(initReactI18next)
  .use(Backend) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    fallbackLng: languagesList.ru.shortCode,

    defaultNS: 'common',
    fallbackNS: 'common',
    ns: ['common'],

    detection: {
      order: [
        'localStorage',
        'htmlTag',
        'querystring',
        'cookie',
        'navigator',
        'path',
        'subdomain',
      ],
      caches: ['localStorage', 'cookie'],
    },

    /*
      keySeparator: ".",
      nsSeparator: ":",
      pluralSeparator: "_",
      contextSeparator: "_",
      */

    saveMissing: false,
    debug: false,

    react: {
      wait: true,
      useSuspense: false,
    },

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      // addPath: 'public/locales/missing.json'
    },
  });

export default i18n;
