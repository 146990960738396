import React, { Fragment, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { bool, string, shape, func, node, oneOfType } from 'prop-types';
import { Button, Tooltip } from 'antd';

const WrapComponent = ({
  condition,
  componentA: WrapperA,
  componentB: WrapperB,
  children,
  ...wrapperProps
}) => {
  // eslint-disable-next-line no-shadow,no-nested-ternary
  return condition ? (
    <WrapperA {...wrapperProps}>{children}</WrapperA>
  ) : WrapperB ? (
    <WrapperB {...wrapperProps}>{children}</WrapperB>
  ) : (
    children
  );
};

const LinkWithTag = ({
  children,
  className,
  history,
  href,
  isButton,
  isNoWrapper,
  linkProps,
  onClick,
  isTargetBlank,
  style,
}) => {
  const { tooltip } = linkProps;
  const handleOnClick = useCallback(
    event => {
      event.persist();
      event.preventDefault();

      const { metaKey, ctrlKey } = event;
      const isOpenNewWindow = metaKey || ctrlKey;
      const usualClickAction = () => (onClick ? onClick() : history.push(href));

      return isOpenNewWindow || isTargetBlank
        ? window.open(href)
        : usualClickAction();
    },
    [href, onClick, history, isTargetBlank],
  );

  const Tag = useCallback(
    props => {
      // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
      return isNoWrapper ? <Fragment {...props} /> : <a {...props} />;
    },
    [isNoWrapper],
  );

  return (
    <WrapComponent condition={!!tooltip} componentA={Tooltip} title={tooltip}>
      <WrapComponent
        condition={isButton}
        componentA={Button}
        componentB={Tag}
        {...linkProps}
        href={href}
        onClick={handleOnClick}
        rel="noopener noreferrer"
        className={className}
        style={style}
      >
        {children}
      </WrapComponent>
    </WrapComponent>
  );
};

LinkWithTag.defaultProps = {
  linkProps: {},
  isNoWrapper: false,
};

LinkWithTag.propTypes = {
  children: node,
  className: string,
  href: string,
  isButton: bool,
  isNoWrapper: bool,
  linkProps: shape({}),
  onClick: oneOfType([func, bool]),
  target: string,
};

export default withRouter(LinkWithTag);
