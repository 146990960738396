import React from 'react';
import { connect } from 'react-redux';
import { number, string } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FaEyeSlash, FaExclamation, FaEnvelope } from 'react-icons/fa';

// Redux
import { showModal as showModalAction } from '../../../redux/actions/modals';

// Constants
import {
  STATUS_ANALYTICS,
  STATUS_VERIFICATION,
} from '../../../constants/statuses';
import { colorRed, colorYellow } from '../../../styles/js/variables/colors';

// Components
import TableTag from '../../Status';
import TableNotice from './TableNotice';

const flexStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const TableStatus = ({
  status,
  notice,
  id: siteId,
  display,
  views,
  showModal,
  styles = null,
  domain,
  t,
}) => {
  const isBadInformerView = status === 'active' && display < 1 && views === 0;
  const isSiteStatus = !!domain;

  const showIntegrationModal = event => {
    event.preventDefault();
    event.stopPropagation();

    showModal({
      type: 'MODAL_SITE_INTEGRATION',
      params: { siteId, status },
    });
  };

  return (
    <div style={flexStyle}>
      <TableTag
        status={status}
        style={{ width: '100%', ...(isSiteStatus && { cursor: 'pointer' }) }}
        {...(isSiteStatus && { onClick: showIntegrationModal })}
      />
      <div style={{ ...flexStyle, fontSize: '1.125em' }}>
        {isBadInformerView && (
          <TableNotice
            storageKey={`notice${siteId + t('basic.attention')}`}
            iconComponent={FaEyeSlash}
            color={colorYellow}
            title={t('basic.attention')}
            text={t(
              `tooltip.${
                styles === null ? 'siteInformerBadPlace' : 'informerBadPlace'
              }`,
            )}
          />
        )}
        {notice && (
          <TableNotice
            storageKey={`notice${siteId + t('basic.attention')}`}
            iconComponent={FaEnvelope}
            color={colorYellow}
            title={t('message.notice')}
            text={notice}
          />
        )}
        {(status === STATUS_VERIFICATION || status === STATUS_ANALYTICS) && (
          <FaExclamation
            className="look-at-me"
            style={{
              color: colorRed,
              cursor: 'pointer',
              fontSize: '1.125em',
              marginLeft: '10px',
            }}
            onClick={showIntegrationModal}
          />
        )}
      </div>
    </div>
  );
};

TableStatus.defaultProps = {
  notice: '',
  description: '',
};

TableStatus.propTypes = {
  status: string.isRequired,
  notice: string,
  description: string,
  id: number,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  showModal: showModalAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(TableStatus));
