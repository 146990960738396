import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'antd';

import { copyToClipboard } from '../../../utils/clipboard';

import * as S from '../styles';

export const CodeInput = ({ html, code = '' }) => {
  const { t } = useTranslation();
  const inputRef = createRef();
  const [rowsQty, setRowsQty] = useState(2);

  const countRows = React.useCallback(() => {
    const testStyles = window.getComputedStyle(inputRef.current);
    const testLineHeight = parseInt(testStyles.lineHeight.slice(0, 2), 0);
    const testHeight = inputRef.current.clientHeight + 1;
    const rows = Math.ceil(testHeight / testLineHeight);

    if (rows > rowsQty) setRowsQty(rows);
  }, [inputRef, rowsQty]);

  useEffect(countRows, [code, inputRef, countRows]);

  return (
    <>
      <S.CodeBlockDescription dangerouslySetInnerHTML={{ __html: html }} />

      <S.CodeBlockInputWrapper>
        <div>
          <S.CodeBlockTest ref={inputRef}>{code}</S.CodeBlockTest>
          <S.CodeBlockInput value={code} rows={rowsQty} />
        </div>

        <S.CodeBlockButton
          className="button--transparent"
          title={t('basic.copy')}
          onClick={() => copyToClipboard(code)}
        >
          <Icon type="copy" style={{ fontSize: '16px' }} />
          <span style={{ margin: 0 }}>{t('basic.copy')}</span>
        </S.CodeBlockButton>
      </S.CodeBlockInputWrapper>
    </>
  );
};
