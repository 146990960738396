import React from 'react';
import { withTranslation } from 'react-i18next';
import { bool, func, string } from 'prop-types';

// Components
import CodeBlock from '../../CodeBlock';
import EmptyData from '../../EmptyData';

const CodeInsertBlock = ({ isEditMode, t, ...restProps }) => (
  <div className="box">
    <h3>{t('blocks.scriptAdd.title')}</h3>
    {isEditMode ? (
      <>
        <p>{t('blocks.scriptAdd.description')}</p>
        <CodeBlock {...restProps} isAmp />
      </>
    ) : (
      <EmptyData title={t('blocks.scriptAdd.ableAfterSave')} />
    )}
  </div>
);

CodeInsertBlock.propTypes = {
  isEditMode: bool,
  informerId: string,
  t: func,
};

export default withTranslation()(CodeInsertBlock);
