import { showNotification } from 'redux/actions/notifications';

const notificationMiddleware = store => next => action => {
  if (action.notification) {
    store.dispatch(showNotification(action.notification));
  }
  return next(action);
};

export default notificationMiddleware;
