import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Icon, Tabs } from 'antd';

import { ColumnDescriptions } from '../../../components/Descriptions/components/ColumnDescriptions';
import EmptyData from '../../../components/EmptyData';
import LinkWithTag from '../../../components/LinkWithTag';
import Loader from '../../../components/Loader';
import PageTitle from '../../../components/PageTitle';
import TabsWithQuery from '../../../components/TabsWithQuery';
import { msFromStartTillNow } from '../../../utils/date';

import routesPath, { generatePath } from '../../../config/routePaths';
import { fetchTable } from '../../../redux/actions/site';
import { START_FETCH_TABLE_INFORMERS } from '../../../redux/types';
import { colorBlue } from '../../../styles/js/variables/colors';

export const SiteInformers = ({ siteId }) => {
  const dispatch = useDispatch();
  const { table } = useSelector(s => s.site);
  const { data } = table;
  const { t } = useTranslation();
  const match = useRouteMatch();
  const network = useSelector(s => s.network);
  const { isMobile } = useSelector(s => s.userSettings);
  const isLoading = network[START_FETCH_TABLE_INFORMERS];
  const pathToInformersList = generatePath({
    path: routesPath.INFORMERS_LIST,
    params: match.params,
  });

  const getInformersList = useCallback(() => {
    if (siteId)
      fetchTable({ site_id: siteId, ...msFromStartTillNow })(dispatch);
  }, [siteId, dispatch]);

  useEffect(getInformersList, [getInformersList, siteId]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <PageTitle
        title={t('basic.informersList')}
        titleTag="h4"
        button={
          <LinkWithTag
            isButton
            href={pathToInformersList}
            linkProps={{
              type: 'default',
            }}
          >
            <Icon type="line-chart" style={{ color: colorBlue }} />
            {!isMobile && t('basic.informersStatistics')}
          </LinkWithTag>
        }
      />
      {!data.length && <EmptyData />}
      <TabsWithQuery type="card">
        {data.map(el => (
          <Tabs.TabPane key={el.id} tab={el.name || `... (${el.id})`}>
            <ColumnDescriptions data={el} excludedFields={['styles']} />
            <br />
            <LinkWithTag
              href={generatePath({
                path: routesPath.INFORMER_EDIT,
                params: {
                  ...match?.params,
                  informerId: el.id,
                },
              })}
              linkProps={{ type: 'primary' }}
              isButton
              style={{ marginTop: 15 }}
            >
              {t('basic.edit')} {t('basic.informer').toLowerCase()} №{el.id}
            </LinkWithTag>
          </Tabs.TabPane>
        ))}
      </TabsWithQuery>
    </>
  );
};
