import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { string, shape } from 'prop-types';
import { Layout } from 'antd';
import { debounce } from 'lodash';

// Redux
import { clearUser as clearUserAction } from '../../redux/actions/auth';
import { setIsMobileAction } from '../../redux/actions/userSettings';

// Services
import layout from '../../services/layout';
import * as LS from '../../services/localStorage';

// Utils
import { isMobileDevice } from '../../utils/layout';

// Components
import Header from '../../components/Header';
import Breadcrumbs from '../../components/Breadcrumbs';
import Footer from '../../components/Footer';

const LayoutDefault = ({
  component: Component,
  isMobile,
  setIsMobile,
  clearUser,
  isAuthorized = true,
  ...props
}) => {
  const logout = history => () => {
    LS.removeAccessToken();
    LS.removeRefreshToken();
    LS.removeLoginRedirect();
    LS.logOutUser();
    clearUser();
    history.push('/login');
  };

  const handleSetIsMobile = useCallback(
    debounce(() => setIsMobile(isMobileDevice()), 300),
    [setIsMobile],
  );

  useEffect(() => {
    if (isMobile === null) handleSetIsMobile();

    window.addEventListener('resize', handleSetIsMobile);
    return () => window.removeEventListener('resize', handleSetIsMobile);
  }, [handleSetIsMobile, isMobile]);

  return (
    <div id="page-wrapper" className="page-wrapper">
      <Layout className="page-layout page-layout--member">
        <Layout className="page-layout__content">
          <Layout.Header>
            <Header
              logout={logout(props.history)}
              history={props.history}
              isAuthorized={!!isAuthorized}
            />
            <Breadcrumbs history={props.history} urlParams={props.match} />
          </Layout.Header>

          <Layout.Content className={props.fullscreen ? 'fullscreen' : ''}>
            <Component {...props} />
          </Layout.Content>

          <Layout.Footer>
            <Footer isAuthorized={!!isAuthorized} />
          </Layout.Footer>
        </Layout>
      </Layout>
    </div>
  );
};

LayoutDefault.propTypes = {
  location: shape({
    pathname: string,
  }).isRequired,
  history: shape({}).isRequired,
  component: shape({}).isRequired,
};

const mapStateToProps = ({ userSettings }) => ({
  isMobile: userSettings.isMobile,
  userSettings,
});

const mapDispatchToProps = {
  setIsMobile: setIsMobileAction,
  clearUser: clearUserAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(layout(LayoutDefault));
