export const LOCAL_STORAGE_LOGIN_REDIRECT = 'loginRedirect';
export const LOCAL_STORAGE_IS_SIDEBAR_HIDDEN = 'isSidebarHidden';
export const LOCAL_STORAGE_SHOWN_POPUPS = 'shownPopups';
export const LOCAL_STORAGE_FILTER_FIXED = 'isFixedFilter';
export const LOCAL_STORAGE_ACCESS_TOKEN = 'access_token';
export const LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';
export const LOCAL_STORAGE_USER = 'user';
export const LOCAL_STORAGE_HIDDEN_CHARTS = 'hiddenCharts';
export const LOCAL_STORAGE_SIDEBAR_THEME = 'sidebarTheme';
export const LOCAL_STORAGE_TABLE_SIZE = 'tableSizes';
