import 'moment/locale/ru';

import {
  DD_MM_YYYY_TYPE,
  defaultDate,
  YYYY_MM_DD_TYPE,
} from 'config/datesFormat';
import { extendMoment } from 'moment-range';
import momentRaw from 'moment-timezone';

// setting default timezone to all dates
momentRaw.tz.setDefault('Europe/Kiev');
export const moment = momentRaw;

const momentWithRange = extendMoment(moment);

// return array of dates from date - range to date, example: [01-03-2019, 02-03-2019, 03-03-2019]
export const getDaysAgo = (
  range = -29,
  date = moment.now(),
  format = defaultDate,
) =>
  Array.from(
    momentWithRange
      .rangeFromInterval(
        'day',
        range,
        moment(date === 'now' ? moment.now() : date),
      )
      .by('day', { excludeEnd: false }),
  ).map(m => m.format(format));

// return array with two dates, date - range and date, example: [01-03-2019, 01-04-2019]
export const getDaysAgoRange = (
  range = -29,
  date = 'now',
  formatType = YYYY_MM_DD_TYPE,
) => {
  const rangeStartDate =
    date === 'now' ? moment().format(formatType) : moment(date, formatType);

  return Object.values(
    momentWithRange.rangeFromInterval('day', +range, rangeStartDate),
  ).map(momentDate => momentDate.format(formatType));
};

export const getDaysRange = (dates = [], formatType = defaultDate) => {
  const endDate = dates.length === 1 ? dates[0] : dates[1];

  return Array.from(moment.range(dates[0], endDate).by('day')).map(date =>
    date.format(formatType),
  );
};

// return length between two dates, example: 29
export const getDiffLengthBetweenDays = dates =>
  Math.abs(moment(dates[0]).diff(moment(dates[1]), 'days'));

// format date to format which you transfered, example: 20-03-19
export const formatIn = (value, formatType) => moment(value).format(formatType);

export const dateToUnix = (date, format) => moment(date, format).format('x');

export const todayDate = moment(new Date());

export const subtractDate = days => moment().subtract(days, 'days');

// converting '01.01.2021' => '2021-01-01'
// prettier-ignore
const formatDotDateToShort = (date) =>
  typeof date === 'string' && date.length === 10
    ? date.split('.').reverse().join('-') // eslint-disable-line newline-per-chained-call
    : date;

export const setBoundaryTimes = dates =>
  dates.map((date, i) => {
    const d = moment(formatDotDateToShort(date));

    if (i === 0) {
      d.startOf('day');
    } else {
      d.endOf('day');
    }

    return d;
  });

export const datesArrayToUnix = dates =>
  setBoundaryTimes(dates).map(date => moment(date).unix() * 1000);

export const getDayUnix = date => {
  const splitDate = date.split(defaultDate === DD_MM_YYYY_TYPE ? '-' : '.');
  const today = new Date(splitDate[2], splitDate[1] - 1, splitDate[0], 0, 0, 0);
  const tomorrow = moment(today).add(1, 'days');

  return datesArrayToUnix([today, tomorrow]);
};

export const getMomentDateAgo = (number, unit) =>
  moment().subtract(number, unit);

export const timestampToFormat = (timestamp, format = 'DD.MM.YY') => {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let min = date.getMinutes();
  let sec = date.getSeconds();

  month = (month < 10 ? '0' : '') + month;
  day = (day < 10 ? '0' : '') + day;
  hour = (hour < 10 ? '0' : '') + hour;
  min = (min < 10 ? '0' : '') + min;
  sec = (sec < 10 ? '0' : '') + sec;

  return format
    .replace('ss', sec)
    .replace('mm', min)
    .replace('hh', hour)
    .replace('DD', day)
    .replace('MM', month)
    .replace('YY', year);
};

export const msFromStartTillNow = {
  start: Number(moment('01.01.2018').format('x')),
  end: Number(
    moment()
      .endOf('day')
      .format('x'),
  ),
};

export const convertToUTC = date => moment(date).add(moment().utcOffset(), 'm');
