import { getNewsCategoriesListApi } from '../../api';

import {
  FAILURE_FETCH_NEWS_CATEGORIES_LIST,
  START_FETCH_NEWS_CATEGORIES_LIST,
  SUCCESS_FETCH_NEWS_CATEGORIES_LIST,
} from '../types';

export const fetchNewsCategoriesList = () => dispatch => {
  dispatch({
    type: START_FETCH_NEWS_CATEGORIES_LIST,
  });

  return getNewsCategoriesListApi()
    .then(payload =>
      dispatch({
        type: SUCCESS_FETCH_NEWS_CATEGORIES_LIST,
        payload: payload.data,
      }),
    )
    .catch(error =>
      dispatch({
        type: FAILURE_FETCH_NEWS_CATEGORIES_LIST,
        payload: error,
      }),
    );
};
