import React from 'react';

import { formatCell } from '../helpers/formatCell';
import columns from './columns';

export default {
  ...columns,

  title: {
    name: 'Название',
    key: 'title',
    render: text => <div className="table-cell--left">{text}</div>,
  },
  position: {
    name: 'Позиция',
    key: 'position',
  },
  views: {
    name: 'Просмотры',
    key: 'views',
    render: formatCell,
  },
  clicks: {
    name: 'Клики',
    key: 'clicks',
    render: formatCell,
  },
  ctr: {
    name: 'CTR',
    key: 'ctr',
    render: formatCell,
  },
  url: {
    name: 'Новость',
    key: 'url',
    align: 'left',
    render: url => (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    ),
  },
};
