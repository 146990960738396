import { showErrorSavingMessage } from '../../components/Notifications/helper';

import {
  addNewSite,
  analyticsCheckApi,
  analyticsSpeedUpApi,
  editSiteById,
  getSitesInfo,
  getSitesStatistics,
  removeSiteById,
} from '../../api';

import { ERROR_NOT_RESPONDED } from '../../constants/messages';
import {
  ADD_SITES_ANALYTICS_FILE_UPLOAD,
  FAILURE_ADD_SITE,
  FAILURE_DELETE_SITE,
  FAILURE_EDIT_SITE,
  FAILURE_FETCH_SITES_CHART,
  FAILURE_FETCH_SITES_TABLE,
  FAILURE_SITE_VERIFICATION,
  FAILURE_SITES_ANALYTICS_SPEED_UP,
  START_ADD_SITE,
  START_DELETE_SITE,
  START_EDIT_SITE,
  START_FETCH_SITES_CHART,
  START_FETCH_SITES_TABLE,
  START_SITE_VERIFICATION,
  START_SITES_ANALYTICS_SPEED_UP,
  SUCCESS_ADD_SITE,
  SUCCESS_DELETE_SITE,
  SUCCESS_EDIT_SITE,
  SUCCESS_FETCH_SITES_CHART,
  SUCCESS_FETCH_SITES_TABLE,
  SUCCESS_SITE_VERIFICATION,
  SUCCESS_SITES_ANALYTICS_SPEED_UP,
} from '../types';

export const fetchTable = params => dispatch => {
  dispatch({
    type: START_FETCH_SITES_TABLE,
  });

  return getSitesInfo(params)
    .then(payload =>
      dispatch({
        type: SUCCESS_FETCH_SITES_TABLE,
        payload: {
          ...payload.data,
          ...params,
        },
      }),
    )
    .catch(error =>
      dispatch({
        type: FAILURE_FETCH_SITES_TABLE,
        payload: error,
        notification: {
          type: 'error',
          text: `Fetch table of sites has failure. Status ${error.status}`,
        },
      }),
    );
};

export const addSite = params => dispatch => {
  dispatch({
    type: START_ADD_SITE,
  });

  return addNewSite(params)
    .then(payload =>
      dispatch({
        type: SUCCESS_ADD_SITE,
        payload: payload.data,
      }),
    )
    .catch(error =>
      dispatch({
        type: FAILURE_ADD_SITE,
        payload: error,
        notification: {
          type: 'error',
          text: showErrorSavingMessage(error),
        },
      }),
    );
};

export const editSite = data => (dispatch, getState) => {
  dispatch({
    type: START_EDIT_SITE,
  });

  return editSiteById(data)
    .then(() => {
      const table = getState().sites.table.data;

      table[table.findIndex(item => data.id === item.id)] = {
        ...table[table.findIndex(item => data.id === item.id)],
        ...data,
      };

      return dispatch({
        type: SUCCESS_EDIT_SITE,
        payload: table,
      });
    })
    .catch(error =>
      dispatch({
        type: FAILURE_EDIT_SITE,
        payload: error,
        notification: {
          type: 'error',
          text: showErrorSavingMessage(error),
        },
      }),
    );
};

export const deleteSite = id => (dispatch, getState) => {
  dispatch({
    type: START_DELETE_SITE,
  });

  return removeSiteById(id)
    .then(() => {
      const table = getState().sites.table.data;

      table.splice(table.findIndex(item => item.id === id), 1);

      dispatch({
        type: SUCCESS_DELETE_SITE,
        payload: table,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_DELETE_SITE,
        payload: error,
        notification: {
          type: 'error',
          text: `Delete site has failure. Status ${error.status}`,
        },
      });
    });
};

export const fetchChart = params => dispatch => {
  dispatch({
    type: START_FETCH_SITES_CHART,
  });

  return getSitesStatistics(params)
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_SITES_CHART,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_SITES_CHART,
        payload: error,
        notification: {
          type: 'error',
          text: `Fetch chart of sites has failure. Status ${error.status}`,
        },
      });
    });
};

export const analyticsCheckAction = params => dispatch => {
  dispatch({
    type: START_SITE_VERIFICATION,
  });

  return analyticsCheckApi(params)
    .then(payload => {
      const { status, message = '' } = payload.data;

      return dispatch({
        type: SUCCESS_SITE_VERIFICATION,
        notification: {
          type: status ? 'success' : 'error',
          text: message,
        },
        payload,
      });
    })
    .catch(error => {
      let message = ERROR_NOT_RESPONDED;

      try {
        // eslint-disable-next-line prefer-destructuring
        message = error.response.data.message;
        // eslint-disable-next-line no-empty
      } catch (e) {}

      return dispatch({
        type: FAILURE_SITE_VERIFICATION,
        payload: error,
        notification: {
          type: 'error',
          text: message,
        },
      });
    });
};

export const analyticsSpeedUpFileAdd = params => dispatch => {
  dispatch({
    type: ADD_SITES_ANALYTICS_FILE_UPLOAD,
    payload: { data: params },
  });
};

export const analyticsSpeedUpAction = params => dispatch => {
  dispatch({
    type: START_SITES_ANALYTICS_SPEED_UP,
  });

  return analyticsSpeedUpApi(params)
    .then(payload =>
      dispatch({
        type: SUCCESS_SITES_ANALYTICS_SPEED_UP,
        payload,
      }),
    )
    .catch(error => {
      let message = ERROR_NOT_RESPONDED;

      try {
        // eslint-disable-next-line prefer-destructuring
        message = error.response.data.message;
        // eslint-disable-next-line no-empty
      } catch (e) {}

      return dispatch({
        type: FAILURE_SITES_ANALYTICS_SPEED_UP,
        payload: error,
        notification: {
          type: 'error',
          text: message,
        },
      });
    });
};
