import React, { Fragment } from 'react';
import { Spin } from 'antd';

const Loader = props => {
  const { className, isLoading, size, style } = props;

  return (
    <Fragment>
      {isLoading && (
        <div className="ant-loader__wrapper">
          <Spin className={`${className} loader`} size={size} style={style} />
        </div>
      )}
    </Fragment>
  );
};

export default Loader;
