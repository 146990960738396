import { arrayOf, bool, exact, number, string } from 'prop-types';

export const iInformerStyle = {
  block: exact({
    backgroundColor: string,
    borderType: string,
    color: string,
    flexBasis: string,
    justifyContent: string,
    marginBottom: string,
    marginLeft: string,
    marginRight: string,
    marginTop: string,
    maxWidth: string,
    paddingBottom: string,
    paddingLeft: string,
    paddingRight: string,
    paddingTop: string,
    position: string,
    radius: string,
    viewType: string,
    widthBorder: string,
  }),
  font: exact({
    bottom: string,
    color: string,
    fontWeight: string,
    height: string,
    left: string,
    marginBottom: string,
    marginLeft: string,
    marginRight: string,
    marginTop: string,
    name: string,
    paddingBottom: string,
    paddingLeft: string,
    paddingRight: string,
    paddingTop: string,
    position: string,
    size: string,
    textAlign: string,
  }),
  general: exact({
    countCalls: string,
    countRows: string,
    marginBottom: string,
    paddingHorizontal: string,
    triggerMobile: string,
    transition: string,
  }),
  image: exact({
    borderType: string,
    color: string,
    height: string,
    radius: string,
    widthBorder: string,
    zoomOnHover: bool,
    width: string,
  }),
  title: exact({
    color: string,
    fontName: string,
    fontWeight: string,
    height: string,
    name: string,
    paddingBottom: string,
    paddingLeft: string,
    paddingRight: string,
    paddingTop: string,
    size: string,
    tag: string,
    textAlign: string,
  }),
};

export const iInformerSiteNews = {
  img: string,
  news_id: number,
  site_id: number,
  title: string,
};

export const iInformerStyleSettings = {
  rubric_ratio: number,
  mix_period: string,
  mix_self: string,
  rotator_last_period: string,
  rotator_last_self: string,
  rotator_main_period: string,
  rotator_main_self: string,
  rotator_rubric_period: string,
  rotator_rubric_self: string,
  rubric_period: string,
  rubric_self: string,
};

export const iInformerSettings = {
  desktop: exact(iInformerStyle),
  id: number,
  categories: arrayOf(number),
  isVisible: bool,
  mobile: exact(iInformerStyle),
  name: string,
  settings: exact(iInformerStyleSettings),
  site_id: number,
  status: string,
  styles: exact({
    desktop: exact(iInformerStyle),
    mobile: exact(iInformerStyle),
    isVisible: bool,
  }),
  categories_disabled: arrayOf(number),
  languages: arrayOf(number),
  heights: exact({
    mobile: number,
    desktop: number,
  }),
};
