import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchTable } from 'redux/actions/informerByDate';

import Table from 'components/Table';
import { getDayUnix } from 'utils/date';
import { informerByDateHeader } from '../../components/Table/config/tables';
import PageTitle from '../../components/PageTitle';
import { withTranslation } from 'react-i18next';
import { getTableSize } from '../../services/localStorage';

class InformerByDate extends Component {
  componentDidMount() {
    const { props } = this;
    const {
      match: {
        params: { date },
      },
    } = props;

    this.handleUpdateAll(getDayUnix(date));
  }

  handleUpdateAll = dates => {
    const { props } = this;
    const {
      match: {
        params: { informerId },
      },
    } = props;
    const { limit, offset } = props.table;

    props.fetchTable({
      limit: getTableSize() || limit,
      offset: dates ? 0 : offset,
      start: +dates[0],
      end: +dates[1],
      informerId,
    });
  };

  handlePagination = ({
    currentPage,
    sortBy,
    sortDirection: { api: sortDirection },
    filters,
    limit,
  }) => {
    const { props } = this;
    const { table } = props;
    const {
      match: {
        params: { informerId },
      },
    } = props;

    const { start, end } = table;
    const offset = (currentPage - 1) * limit;

    props.fetchTable({
      informerId,
      limit,
      offset,
      start,
      end,
      sortDirection,
      sortBy,
      // ...filters,
    });
  };

  handleLinkUrl = row => `news/${row.id}`;

  render() {
    const { props } = this;
    const {
      match: {
        params: { date },
      },
      isTableLoading,
      t,
    } = props;

    return (
      <div className="page-body main-page">
        <PageTitle
          title={`${t('content.title.informerNewsStats')}: ${date}`}
          hint={t('content.titleHint.informerNewsStats')}
        />

        <Table
          headers={informerByDateHeader}
          isLoading={isTableLoading}
          data={props.table.data}
          total={props.table.total}
          limit={props.table.limit}
          fetch={this.handlePagination}
          // actions={{
          //   page: this.handleLinkUrl,
          // }}
          type="informerByDateActions"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    table: state.informerByDate.table,
    isTableLoading: state.informerByDate.table.isLoading,
  };
}

const mapDispatchToProps = {
  fetchTable,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(InformerByDate));
