import _ from 'lodash';

export const replaceObjectByKeys = (prev, next) => {
  const result = prev;

  Object.entries(next).forEach(([key, value]) => {
    const isObject =
      typeof value === 'object' && value !== null && Object.keys(value).length;

    result[key] = isObject ? replaceObjectByKeys(result[key], value) : value;
  });

  return result;
};

const camelize = obj =>
  _.transform(obj, (acc, value, key, target) => {
    const camelKey = _.isArray(target) ? key : _.camelCase(key);

    acc[camelKey] = _.isObject(value) ? camelize(value) : value;
  });

const snakelize = obj =>
  _.transform(obj, (acc, value, key, target) => {
    const camelKey = _.isArray(target) ? key : _.snakeCase(key);

    acc[camelKey] = _.isObject(value) ? snakelize(value) : value;
  });

const convertDataTo = (data, handler) => {
  if (!data) return data;

  let result;

  if (Array.isArray(data)) {
    result = _.map(data, handler);
  } else if (_.isObject(data)) {
    result = handler(data);
  } else {
    result = data;
  }

  return result;
};

export const convertDataToCamelCase = data => convertDataTo(data, camelize);
export const convertDataToSnakeCase = data => convertDataTo(data, snakelize);
