import React from 'react';
import WithTranslation from '../WithTranslation';
import { isMobileDevice } from '../../../../utils/layout';
import breakpoints from '../../../../constants/breakpoints';
import ActionButtons from './components/ActionButtonsList';

const isMobile = isMobileDevice();

const renderActions = actions => {
  const widthActionsCol = 32 * Object.keys(actions).length;
  const minWidth = 85;
  const actionsWidth = widthActionsCol >= minWidth ? widthActionsCol : minWidth;
  const isFixedColumn = !isMobile && window.innerWidth < breakpoints.desktop;

  return {
    title: <WithTranslation type="table.actions" />,
    align: 'center',
    fixed: isFixedColumn ? 'right' : null,
    render: rowData => (
      <ActionButtons row={rowData} actions={actions} width={actionsWidth} />
    ),
  };
};

export default renderActions;
