import React, { memo, useMemo } from 'react';
import { Button } from 'antd';
import ActionButtonsItem from './ActionButtonsItem';

const ActionButtonsList = ({ width, actions, row }) => {
  const actionsArray = useMemo(
    () =>
      Object.entries(actions).sort(
        // eslint-disable-next-line no-unused-vars
        ([aKey, aValue], [bKey, bValue]) => aValue.index - bValue.index,
      ),
    [actions],
  );

  return (
    <Button.Group style={{ width }}>
      {actionsArray.map(([key, value]) => (
        <ActionButtonsItem key={`action${key}`} row={row} {...value} />
      ))}
    </Button.Group>
  );
};

ActionButtonsList.propTypes = {};

export default memo(ActionButtonsList);
