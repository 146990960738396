import { useCallback, useEffect, useState } from 'react';

export const useFormTouch = ({ form, formData }) => {
  const [isFormTouched, setIsFormTouched] = useState(false);

  const checkTouch = useCallback(() => {
    const touched = Object.entries(formData).reduce((acc, [key, value]) => {
      return form[key] === value ? acc : acc + 1;
    }, 0);

    setIsFormTouched(!!touched);
  }, [form, formData]);

  useEffect(checkTouch, [checkTouch, form]);

  return { isFormTouched, setIsFormTouched };
};
