import React from 'react';
import { useTranslation } from 'react-i18next';

export const SiteNotice = ({ siteData }) => {
  const { t } = useTranslation();

  return (
    <>
      {siteData.description && (
        <div>
          <b>{t('basic.description')}</b>
          <br />
          <i>{siteData.description}</i>
        </div>
      )}
      {siteData.notice && (
        <div>
          <b>{t('basic.notice')}</b>
          <br />
          <i>&quot;{siteData.notice}&quot;</i>
        </div>
      )}
    </>
  );
};
