import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Radio } from 'antd';

// Utils
import { ValidatorContext } from 'context/ValidatorContext';
import { mustBeHexColor, mustBeFilled } from 'config/validationRules';

// Components
import ValidateInputWithColorPicker from 'components/ValidateInputWithColorPicker';
import BorderSettings from 'components/SettingPanel/components/BorderSettings';
import DistanceSettings from 'components/SettingPanel/components/DistanceSettings';
import Dropdown from 'components/common/Dropdown';
import Label from 'components/Label';
import Grid from '../../Grid';
import ValidateInput from '../../ValidateInput';

const ContainerBlock = ({ element, onChange, typeDevice, t }) => {
  const { actions } = React.useContext(ValidatorContext);
  const block = element;

  const viewTypes = [
    { key: 'vertical', text: t('basic.vertical'), value: 'vertical' },
    { key: 'horizontal', text: t('basic.horizontal'), value: 'horizontal' },
  ];
  const alignments = [
    { key: 'left', text: t('basic.left'), value: 'flex-start' },
    { key: 'center', text: t('basic.center'), value: 'center' },
    { key: 'right', text: t('basic.right'), value: 'flex-end' },
  ];

  return (
    <Fragment>
      <Grid columns={2}>
        <Fragment>
          <Label labelText={t('basic.backgroundColor')} asterisk />
          <ValidateInputWithColorPicker
            name="backgroundColor"
            validateName="container-color"
            defaultValue={block.backgroundColor}
            value={block.backgroundColor}
            controlled
            colorPicker={{
              onDrag: () => {},
              onComplete: value =>
                onChange({
                  item: 'block',
                  response: { name: 'backgroundColor', value },
                }),
            }}
            inputValidation={{
              placeholder: '#ffffff',
              type: 'text',
              onChange: value =>
                onChange({
                  item: 'block',
                  response: { name: 'backgroundColor', value },
                }),
              onValidate: input => actions.validate(input),
              rules: [mustBeFilled, mustBeHexColor],
            }}
          />
        </Fragment>

        {typeDevice === 'mobile' && (
          <Fragment>
            <Label
              labelText={t('basic.layoutVariants')}
              mark={t('basic.mobileFieldHint')}
              asterisk
            />
            <Dropdown
              name="viewType"
              options={viewTypes}
              selection
              value={block.viewType}
              onChange={value =>
                onChange({
                  item: 'block',
                  response: { name: 'viewType', value },
                })
              }
            />
          </Fragment>
        )}
      </Grid>

      {typeDevice === 'mobile' && (
        <Grid columns={2}>
          <Fragment>
            <Label
              labelText={t('basic.blockAlign')}
              mark={t('basic.mobileFieldHint')}
              asterisk
            />
            <Radio.Group
              buttonStyle="solid"
              name="justifyContent"
              value={block.justifyContent}
              onChange={({ target }) =>
                onChange({
                  item: 'block',
                  response: { name: 'justifyContent', value: target.value },
                })
              }
            >
              {alignments.map(({ value, key, text }) => (
                <Radio.Button key={key} value={value}>
                  {text}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Fragment>
          <Fragment>
            <Label
              labelText={t('basic.maxWidth')}
              mark={t('basic.mobileFieldHint')}
              asterisk
            />
            <ValidateInput
              name="maxWidth"
              validateName="container-max-width"
              defaultValue={element.maxWidth}
              value={element.maxWidth}
              controlled
              rules={[mustBeFilled]}
              onValidate={input => actions.validate(input)}
              onChange={value =>
                onChange({
                  item: 'block',
                  response: { name: 'maxWidth', value },
                })
              }
            />
          </Fragment>
        </Grid>
      )}

      <br />

      <BorderSettings
        prefix="container"
        border={block}
        item="block"
        onChange={onChange}
      />

      <br />

      <DistanceSettings
        prefix="container"
        element={block}
        item="block"
        onChange={onChange}
      />
      <DistanceSettings
        isMargin
        prefix="container"
        element={block}
        item="block"
        onChange={onChange}
      />
    </Fragment>
  );
};

ContainerBlock.propTypes = {
  element: PropTypes.shape({}),
  onChange: PropTypes.func,
};

ContainerBlock.defaultProps = {
  element: {},
  onChange: () => {},
};

export default withTranslation()(ContainerBlock);
