import React, { useCallback, useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import {
  HighchartsChart,
  Chart,
  withHighcharts,
  XAxis,
  Legend,
  Tooltip,
} from 'react-jsx-highcharts';
import Loader from '../Loader';
import Calendar from './components/Calendar';
import { chartColors, labeling, propsDataToChart } from './helper';
import './styles.scss';
import YAxises from './components/YAxises';
import { withTranslation } from 'react-i18next';

const LinearChart = ({
  data,
  categoriesForX,
  isLoading,
  onChange,
  isAutoKeys,
  showKeys,
  excludeKeys,
  isShowChart = true,
  t,
}) => {
  const parentRef = useRef(null);
  const [width, setWidth] = useState(0);
  const removeYear = useCallback(
    () => categoriesForX.map(el => el.slice(0, el.length - 5)),
    [categoriesForX],
  );

  useEffect(() => {
    // why timeout? We need to wait for sidebar action finishes (open/close)
    const handleResize = () =>
      setTimeout(() => {
        if (parentRef.current && parentRef.current.offsetWidth) {
          setWidth(parentRef.current.offsetWidth);
        }
      }, 500);

    if (!isLoading) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isLoading, parentRef]);

  return (
    <div
      ref={parentRef}
      className="highcharts-wrapper"
      style={{ margin: '0 auto' }}
    >
      <Loader isLoading={isLoading} />
      <div
        style={{
          opacity: Number(!isLoading),
          transition: 'opacity 4s',
        }}
      >
        <div
          className="ant-calendar-picker__wrapper"
          style={{
            marginBottom: showKeys.length > 4 ? '3em' : '2em',
            opacity: Number(isShowChart),
          }}
        >
          <Calendar onChange={onChange} categoriesForX={categoriesForX} />
        </div>

        <div
          className="highcharts__wrapper"
          style={{
            ...(showKeys.length > 4 && { marginTop: '-2.75em' }),
            ...(!isLoading && !isShowChart ? { height: 0 } : {}),
          }}
        >
          <HighchartsChart colors={chartColors}>
            <Chart animation width={width} />
            {showKeys.length > 1 && (
              <Legend
                labelFormatter={labeling}
                useHTML
                verticalAlign="top"
                align="right"
              />
            )}
            <Tooltip />

            <XAxis
              id="myXaxis"
              type="datetime"
              categories={removeYear(categoriesForX)}
            />
            <YAxises
              data={propsDataToChart({
                isAutoKeys,
                showKeys,
                excludeKeys,
                data,
                t,
              })}
              hasCoefficient
              isLoading={isLoading}
            />
          </HighchartsChart>
        </div>
        <br />
      </div>
    </div>
  );
};

LinearChart.defaultProps = {
  isLoading: false,
  isAutoKeys: false,
  showKeys: ['views'],
  excludeKeys: [],
};

LinearChart.propTypes = {
  data: PropTypes.shape({}).isRequired,
  categoriesForX: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isAutoKeys: PropTypes.bool,
  showKeys: PropTypes.arrayOf(PropTypes.string),
  excludeKeys: PropTypes.arrayOf(PropTypes.string),
};

export default withTranslation()(withHighcharts(LinearChart, Highcharts));
