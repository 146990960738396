import React from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { Button, Icon, Select } from 'antd';
import { FaEnvelope, FaTelegram } from 'react-icons/fa';

// Utils
import { colorBlue } from '../../../../styles/js/variables/colors';
import { showModal as showModalAction } from '../../../../redux/actions/modals';

// Config
import { languagesList } from '../../../../config/i18n';

// Styles
import { HeaderMenuAction, HeaderMenuItem, HeaderMenuList } from './styles';

const HeaderMenu = ({ logout, isDefinedUser, showModal, t }) => {
  const handleLanguageSwitch = language => i18next.changeLanguage(language);

  const openFeedbackForm = () =>
    showModal({ type: 'MODAL_FEEDBACK', modalWidth: '500px' });

  return (
    <HeaderMenuList>
      <HeaderMenuItem>
        {t('header.language')}
        <Select
          placeholder="---"
          onChange={handleLanguageSwitch}
          defaultValue={i18next.language}
          style={{ minWidth: '100px' }}
        >
          {Object.entries(languagesList).map(([el, { key }]) => (
            <Select.Option key={el} value={key}>
              {t(`languages.${key}`)}
            </Select.Option>
          ))}
        </Select>
      </HeaderMenuItem>

      {/*
      <HeaderMenuItem isTwoSided>
        <LinkWithTag
          isButton
          href={TERMS}
          pageType={TERMS}
          linkProps={{
            type: 'link',
          }}
        >
          {t('header.terms')}
          <FaCertificate size={30} color={colorYellow} />
        </LinkWithTag>
      </HeaderMenuItem>
      */}

      {!isDefinedUser && (
        <HeaderMenuItem isTwoSided isLast>
          <Button type="link" onClick={openFeedbackForm}>
            {t('header.mailUs')}
            <FaEnvelope size={30} color={colorBlue} />
          </Button>
        </HeaderMenuItem>
      )}

      {isDefinedUser && (
        <>
          <HeaderMenuItem isTwoSided>
            <Button
              type="link"
              href={`https://t.me/${process.env.REACT_APP_SUPPORT_TELEGRAM_USER}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('header.support')}
              <HeaderMenuAction>
                <FaTelegram size={30} color={colorBlue} />
              </HeaderMenuAction>
            </Button>
          </HeaderMenuItem>

          <HeaderMenuItem isLast>
            {t('header.leaveSite')}
            <Button type="primary" onClick={logout}>
              <Icon type="logout" /> {t('header.logout')}
            </Button>
          </HeaderMenuItem>
        </>
      )}
    </HeaderMenuList>
  );
};

HeaderMenu.propTypes = {
  t: func,
  logout: func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  showModal: showModalAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(HeaderMenu));
