import { SET_IS_MOBILE } from '../types';

const initialState = {
  isMobile: null,
};

export default function userSettings(state = initialState, action = {}) {
  switch (action.type) {
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload.data,
      };

    default:
      return state;
  }
}
