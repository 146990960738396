import { Button } from 'antd';

import styled from 'styled-components';

import { colorBlue, colorGrey } from '../../styles/js/variables/colors';

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 24px;
`;

export const FooterFeedback = styled(Button)`
  ${({ fixed }) =>
    fixed &&
    `
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 5;
  `};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-color: transparent;
  padding: 10px !important;
  overflow: hidden;
  border-radius: 100px;
  height: auto;
  background: ${colorBlue}!important;
  color: rgba(255, 255, 255, 0.875) !important;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.125);
  transition: box-shadow 0.25s;

  &:hover {
    > div > span {
      margin-right: 0;
      transition: width 0.25s, margin-right 0.25s;
    }
  }

  svg {
    fill: #fff;
    transition: fill 0.25s;
  }
`;

export const FooterFeedbackText = styled.div`
  line-height: 1.2;
  text-align: right;
  overflow: hidden;

  span {
    padding: 0 10px 0 5px;
    margin-right: -150px;
    transition: width 0.25s, margin-right 0.25s;
  }

  small {
    color: ${colorGrey};
    display: block;
  }
`;
