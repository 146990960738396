import { showErrorSavingMessage } from '../../components/Notifications/helper';
import { setIntervalFromStart } from '../../utils';
import { convertDataToCamelCase } from '../../utils/object';

import { getFailureStatus, getStartStatus, getSuccessStatus } from './statuses';

export const basicAction = ({
  type,
  apiCall,
  addToPayload = {},
  successMessage,
  payloadData,
}) => dispatch => {
  dispatch({
    type: getStartStatus(type),
  });

  return apiCall()
    .then(payload =>
      dispatch({
        type: getSuccessStatus(type),
        payload: {
          ...addToPayload,
          ...convertDataToCamelCase(
            payloadData ? { data: payloadData } : payload.data,
          ),
        },
        ...(successMessage && {
          notification: {
            type: 'success',
            text: successMessage || '',
            timeout: 3000,
          },
        }),
      }),
    )
    .catch(error =>
      dispatch({
        type: getFailureStatus(type),
        payload: error,
        notification: {
          type: 'error',
          text: showErrorSavingMessage(error),
        },
      }),
    );
};

export const repeatBasicActionUntilSuccess = (
  params,
  timeout = 5000,
  checkReasonToStopInterval,
) => dispatch =>
  new Promise(resolve => {
    const callback = intervalID =>
      basicAction(params)(dispatch).then(response => {
        if (checkReasonToStopInterval(response)) {
          clearInterval(intervalID);
          resolve();
        }
      });

    return setIntervalFromStart(callback, timeout);
  });
