import {
  FAILURE_FETCH_CHART_INFORMER,
  FAILURE_FETCH_INFORMER,
  FAILURE_FETCH_TABLE_INFORMER,
  START_FETCH_CHART_INFORMER,
  START_FETCH_INFORMER,
  START_FETCH_TABLE_INFORMER,
  SUCCESS_FETCH_CHART_INFORMER,
  SUCCESS_FETCH_INFORMER,
  SUCCESS_FETCH_TABLE_INFORMER,
} from 'redux/types';
import { convertToChartsData } from 'services/formatter';

const initialState = {
  info: {},
  table: {
    isLoading: true,
    total: 0,
    limit: 20,
    offset: 0,
    data: [],
  },
  chart: {
    isLoading: true,
    data: [],
  },
};

export default function informer(state = initialState, action = {}) {
  switch (action.type) {
    // Fetch informer
    // ________________________________________________________
    case START_FETCH_INFORMER:
      return state;

    case SUCCESS_FETCH_INFORMER:
      return {
        ...state,
        info: action.payload.data,
      };

    case FAILURE_FETCH_INFORMER:
      return state;
    // ________________________________________________________
    // END Fetch informer

    // Fetch table informer
    // ________________________________________________________
    case START_FETCH_TABLE_INFORMER:
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: true,
        },
      };

    case SUCCESS_FETCH_TABLE_INFORMER:
      return {
        ...state,
        table: {
          ...state.table,
          ...action.payload,
          isLoading: false,
        },
      };

    case FAILURE_FETCH_TABLE_INFORMER:
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: false,
        },
      };
    // ________________________________________________________
    // END Fetch table informer

    // Fetch chart informer
    // ________________________________________________________
    case START_FETCH_CHART_INFORMER:
      return {
        ...state,
        chart: {
          ...state.chart,
          isLoading: true,
        },
      };

    case SUCCESS_FETCH_CHART_INFORMER:
      return {
        ...state,
        chart: {
          ...convertToChartsData(action.payload.data),
          isLoading: false,
        },
      };

    case FAILURE_FETCH_CHART_INFORMER:
      return {
        ...state,
        chart: {
          ...state.chart,
          isLoading: false,
        },
      };
    // ________________________________________________________
    // END Fetch chart informer

    default:
      return state;
  }
}
