import { SUCCESS_FETCH_NEWS_CATEGORIES_LIST } from '../types';

const initialState = {
  newsCategoriesList: [],
};

export default function dataLists(state = initialState, action = {}) {
  switch (action.type) {
    case SUCCESS_FETCH_NEWS_CATEGORIES_LIST:
      return {
        ...state,
        newsCategoriesList: action.payload.data,
      };

    default:
      return state;
  }
}
