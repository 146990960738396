import React from 'react';

export default ({
  withComponent,
  component: Component,
  componentProps,
  children,
}) =>
  withComponent ? (
    <Component {...componentProps}>{children}</Component>
  ) : (
    children
  );
