import React from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import LayoutDefault from 'HOC/Layouts/LayoutDefault';
import ReduxContainer from 'redux/ReduxContainer';
import routes from 'routes';

import InitRequests from './components/InitRequests';
import ModalContainer from './components/Modals';
import Notifications from './components/Notifications';

function App() {
  return (
    <ReduxContainer>
      <Router>
        <InitRequests />

        <Switch>
          {routes.map(item => (
            <LayoutDefault
              key={item.path}
              path={item.path}
              component={item.component}
              exact={item.exact}
              middleware={item.middleware}
              isAuthorized={item.isAuthorized}
            />
          ))}
        </Switch>

        <Notifications />

        <ModalContainer />
      </Router>
    </ReduxContainer>
  );
}

export default hot(App);
