import { FaUpload } from 'react-icons/fa';

import styled from 'styled-components';

import { colorGrey, colorGreyDark } from '../../styles/js/variables/colors';

export const UploadBlockIcon = styled(FaUpload)`
  fill: ${colorGrey};
  margin-bottom: 5px;
`;

export const UploadBlockTitle = styled.div`
  color: ${colorGreyDark};
  font-weight: bold;
`;

export const UploadBlockTooltip = styled.div`
  color: ${colorGrey};
  font-size: 12px;
`;
