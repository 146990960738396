// label: AUTH
// ________________________________________________________

export const START_SIGN_IN_AUTH = 'START_SIGN_IN_AUTH';
export const SUCCESS_SIGN_IN_AUTH = 'SUCCESS_SIGN_IN_AUTH';
export const FAILURE_SIGN_IN_AUTH = 'FAILURE_SIGN_IN_AUTH';

export const START_REGISTRATION_AUTH = 'START_REGISTRATION_AUTH';
export const SUCCESS_REGISTRATION_AUTH = 'SUCCESS_REGISTRATION_AUTH';
export const FAILURE_REGISTRATION_AUTH = 'FAILURE_REGISTRATION_AUTH';

export const CLEAR_USER = 'CLEAR_USER';

export const START_REFRESH_TOKEN = 'START_REFRESH_TOKEN';
export const SUCCESS_REFRESH_TOKEN = 'SUCCESS_REFRESH_TOKEN';
export const FAILURE_REFRESH_TOKEN = 'FAILURE_REFRESH_TOKEN';

export const START_FETCH_FEEDBACK = 'START_FETCH_FEEDBACK';
export const SUCCESS_FETCH_FEEDBACK = 'SUCCESS_FETCH_FEEDBACK';
export const FAILURE_FETCH_FEEDBACK = 'FAILURE_FETCH_FEEDBACK';

// label: SITES
// ________________________________________________________

export const START_FETCH_SITE = 'START_FETCH_SITE';
export const SUCCESS_FETCH_SITE = 'SUCCESS_FETCH_SITE';
export const FAILURE_FETCH_SITE = 'FAILURE_FETCH_SITE';

export const START_FETCH_SITES_CHART = 'START_FETCH_SITES_CHART';
export const SUCCESS_FETCH_SITES_CHART = 'SUCCESS_FETCH_SITES_CHART';
export const FAILURE_FETCH_SITES_CHART = 'FAILURE_FETCH_SITES_CHART';

export const START_ADD_SITE = 'START_ADD_SITE';
export const SUCCESS_ADD_SITE = 'SUCCESS_ADD_SITE';
export const FAILURE_ADD_SITE = 'FAILURE_ADD_SITE';

export const EDIT_SITE_FORM = 'EDIT_SITE_FORM';
export const START_EDIT_SITE = 'START_EDIT_SITE';
export const SUCCESS_EDIT_SITE = 'SUCCESS_EDIT_SITE';
export const FAILURE_EDIT_SITE = 'FAILURE_EDIT_SITE';

export const START_DELETE_SITE = 'START_DELETE_SITE';
export const SUCCESS_DELETE_SITE = 'SUCCESS_DELETE_SITE';
export const FAILURE_DELETE_SITE = 'FAILURE_DELETE_SITE';

export const FETCH_SITES_LIST = 'FETCH_SITES_LIST';
export const FETCH_SITES_TABLE = 'FETCH_SITES_TABLE';
export const START_FETCH_SITES_TABLE = 'START_FETCH_SITES_TABLE';
export const SUCCESS_FETCH_SITES_TABLE = 'SUCCESS_FETCH_SITES_TABLE';
export const FAILURE_FETCH_SITES_TABLE = 'FAILURE_FETCH_SITES_TABLE';

export const START_SITE_VERIFICATION = 'START_SITE_VERIFICATION';
export const SUCCESS_SITE_VERIFICATION = 'SUCCESS_SITE_VERIFICATION';
export const FAILURE_SITE_VERIFICATION = 'FAILURE_SITE_VERIFICATION';

// label SITE
// ________________________________________________________
export const START_FETCH_CHART_INFORMERS = 'START_FETCH_CHART_INFORMERS';
export const SUCCESS_FETCH_CHART_INFORMERS = 'SUCCESS_FETCH_CHART_INFORMERS';
export const FAILURE_FETCH_CHART_INFORMERS = 'FAILURE_FETCH_CHART_INFORMERS';

export const START_FETCH_TABLE_INFORMERS = 'START_FETCH_TABLE_INFORMERS';
export const SUCCESS_FETCH_TABLE_INFORMERS = 'SUCCESS_FETCH_TABLE_INFORMERS';
export const FAILURE_FETCH_TABLE_INFORMERS = 'FAILURE_FETCH_TABLE_INFORMERS';

// Site Statistics
// ________________________________________________________
export const START_FETCH_CHART_SITE_STATISTICS =
  'START_FETCH_CHART_SITE_STATISTICS';
export const SUCCESS_FETCH_CHART_SITE_STATISTICS =
  'SUCCESS_FETCH_CHART_SITE_STATISTICS';
export const FAILURE_FETCH_CHART_SITE_STATISTICS =
  'FAILURE_FETCH_CHART_SITE_STATISTICS';

export const START_FETCH_TABLE_SITE_STATISTICS =
  'START_FETCH_TABLE_SITE_STATISTICS';
export const SUCCESS_FETCH_TABLE_SITE_STATISTICS =
  'SUCCESS_FETCH_TABLE_SITE_STATISTICS';
export const FAILURE_FETCH_TABLE_SITE_STATISTICS =
  'FAILURE_FETCH_TABLE_SITE_STATISTICS';

// label NEWS
// ________________________________________________________

export const START_FETCH_SITE_NEWS = 'START_FETCH_SITE_NEWS';
export const FAILURE_FETCH_SITE_NEWS = 'FAILURE_FETCH_SITE_NEWS';
export const SUCCESS_FETCH_SITE_NEWS = 'SUCCESS_FETCH_SITE_NEWS';

export const START_FETCH_TABLE_INFORMER_NEWS =
  'START_FETCH_TABLE_INFORMER_NEWS';
export const SUCCESS_FETCH_TABLE_INFORMER_NEWS =
  'SUCCESS_FETCH_TABLE_INFORMER_NEWS';
export const FAILURE_FETCH_TABLE_INFORMER_NEWS =
  'FAILURE_FETCH_TABLE_INFORMER_NEWS';

export const START_FETCH_CHART_INFORMER_NEWS =
  'START_FETCH_CHART_INFORMER_NEWS';
export const SUCCESS_FETCH_CHART_INFORMER_NEWS =
  'SUCCESS_FETCH_CHART_INFORMER_NEWS';
export const FAILURE_FETCH_CHART_INFORMER_NEWS =
  'FAILURE_FETCH_CHART_INFORMER_NEWS';

export const START_FETCH_NEWS = 'START_FETCH_NEWS';
export const SUCCESS_FETCH_NEWS = 'SUCCESS_FETCH_NEWS';
export const FAILURE_FETCH_NEWS = 'FAILURE_FETCH_NEWS';

// label INFORMER
// ________________________________________________________
export const START_FETCH_INFORMER = 'START_FETCH_INFORMER';
export const SUCCESS_FETCH_INFORMER = 'SUCCESS_FETCH_INFORMER';
export const FAILURE_FETCH_INFORMER = 'FAILURE_FETCH_INFORMER';

export const START_FETCH_CHART_INFORMER = 'START_FETCH_CHART_INFORMER';
export const SUCCESS_FETCH_CHART_INFORMER = 'SUCCESS_FETCH_CHART_INFORMER';
export const FAILURE_FETCH_CHART_INFORMER = 'FAILURE_FETCH_CHART_INFORMER';

export const START_FETCH_TABLE_INFORMER = 'START_FETCH_TABLE_INFORMER';
export const SUCCESS_FETCH_TABLE_INFORMER = 'SUCCESS_FETCH_TABLE_INFORMER';
export const FAILURE_FETCH_TABLE_INFORMER = 'FAILURE_FETCH_TABLE_INFORMER';

export const START_FETCH_TABLE_INFORMER_BY_DATE =
  'START_FETCH_TABLE_INFORMER_BY_DATE';
export const SUCCESS_FETCH_TABLE_INFORMER_BY_DATE =
  'SUCCESS_FETCH_TABLE_INFORMER_BY_DATE';
export const FAILURE_FETCH_TABLE_INFORMER_BY_DATE =
  'FAILURE_FETCH_TABLE_INFORMER_BY_DATE';

export const START_ADD_INFORMER = 'START_ADD_INFORMER';
export const SUCCESS_ADD_INFORMER = 'SUCCESS_ADD_INFORMER';
export const FAILURE_ADD_INFORMER = 'FAILURE_ADD_INFORMER';

export const START_EDIT_INFORMER = 'START_EDIT_INFORMER';
export const SUCCESS_EDIT_INFORMER = 'SUCCESS_EDIT_INFORMER';
export const FAILURE_EDIT_INFORMER = 'FAILURE_EDIT_INFORMER';

export const EDIT_INFORMER_LOCAL = 'EDIT_INFORMER_LOCAL';

export const CHOICE_INFORMER = 'CHOICE_INFORMER';

export const START_DELETE_INFORMER = 'START_DELETE_INFORMER';
export const SUCCESS_DELETE_INFORMER = 'SUCCESS_DELETE_INFORMER';
export const FAILURE_DELETE_INFORMER = 'FAILURE_DELETE_INFORMER';

export const RESET_INFORMER_STYLE = 'RESET_INFORMER_STYLE';

export const START_RESET_INFORMER_STYLES = 'START_RESET_INFORMER_STYLES';
export const FAILURE_RESET_INFORMER_STYLES = 'FAILURE_RESET_INFORMER_STYLES';
export const SUCCESS_RESET_INFORMER_STYLES = 'SUCCESS_RESET_INFORMER_STYLES';

// label: OTHER
// ________________________________________________________

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const SET_IS_MOBILE = 'SET_IS_MOBILE';

export const ADD_SITES_ANALYTICS_FILE_UPLOAD =
  'ADD_SITES_ANALYTICS_FILE_UPLOAD';

export const START_SITES_ANALYTICS_SPEED_UP = 'START_SITES_ANALYTICS_SPEED_UP';
export const SUCCESS_SITES_ANALYTICS_SPEED_UP =
  'SUCCESS_SITES_ANALYTICS_SPEED_UP';
export const FAILURE_SITES_ANALYTICS_SPEED_UP =
  'FAILURE_SITES_ANALYTICS_SPEED_UP';

// ________________________________________________________
// Data lists

export const START_FETCH_NEWS_CATEGORIES_LIST =
  'START_FETCH_NEWS_CATEGORIES_LIST';
export const SUCCESS_FETCH_NEWS_CATEGORIES_LIST =
  'SUCCESS_FETCH_NEWS_CATEGORIES_LIST';
export const FAILURE_FETCH_NEWS_CATEGORIES_LIST =
  'FAILURE_FETCH_NEWS_CATEGORIES_LIST';

export const FETCH_SCRIPT_CHECKS_LOG = 'FETCH_SCRIPT_CHECKS_LOG';
export const FETCH_SITE_STATUSES_HISTORY = 'FETCH_SITE_STATUSES_HISTORY';
export const FETCH_PARSING_ERRORS = 'FETCH_PARSING_ERRORS';
