import React from 'react';
import { string, exact } from 'prop-types';
import { mustBeTagName } from '../../../config/validationRules';

const SettingsGalleryTitle = ({ title, titleName }) => {
  const titleStyle = {
    display: 'block',
    width: '100%',
    fontSize: `${title.size}px`,
    fontWeight: `${title.fontWeight}`,
    lineHeight: `${title.height}px`,
    color: `${title.color}`,
    padding: `${title.paddingTop}px ${title.paddingRight}px ${title.paddingBottom}px ${title.paddingLeft}px`,
    ...(title.fontName !== 'Default' && { fontFamily: `${title.fontName}` }),
    ...(title.textAlign !== 'left' && { textAlign: `${title.textAlign}` }),
  };

  const TitleTag = title.tag.match(mustBeTagName.regexp) ? title.tag : 'span';

  return <TitleTag style={titleStyle}>{titleName}</TitleTag>;
};

SettingsGalleryTitle.propTypes = {
  title: exact({
    name: string,
    size: string,
    fontName: string,
    fontFamily: string,
    fontWeight: string,
    height: string,
    color: string,
    paddingTop: string,
    paddingRight: string,
    paddingBottom: string,
    paddingLeft: string,
    textAlign: string,
    tag: string,
  }),
  titleName: string,
};

export default SettingsGalleryTitle;
