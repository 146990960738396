import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Icon } from 'antd';

import LinkWithTag from '../../../components/LinkWithTag';
import PageTitle from '../../../components/PageTitle';
import { msFromStartTillNow } from '../../../utils/date';
import { getTokenData } from '../../../utils/token';

import { generatePath, SITE_STATISTICS } from '../../../config/routePaths';
import { getSites } from '../../../redux/actions/site';
import { colorBlue } from '../../../styles/js/variables/colors';
import { SiteStatsInfo } from './SiteStatisticsInfo';

export const SiteStatistics = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { isMobile } = useSelector(s => s.userSettings);
  const linkToSiteStats = generatePath({
    path: SITE_STATISTICS,
    params: match.params,
  });
  const publisherId = getTokenData().userId;

  const getSitesList = useCallback(() => {
    getSites(msFromStartTillNow)(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (publisherId) getSitesList();
  }, [getSitesList, publisherId]);

  return (
    <>
      <PageTitle
        title={t('basic.siteStatsAllTime')}
        titleTag="h4"
        button={
          <LinkWithTag
            isButton
            href={linkToSiteStats}
            linkProps={{
              type: 'default',
            }}
          >
            <Icon type="line-chart" style={{ color: colorBlue }} />
            {!isMobile && t('content.title.siteStatistics')}
          </LinkWithTag>
        }
      />
      <SiteStatsInfo excludedFields={['status', 'notice', 'id']} />
    </>
  );
};
