import cloneDeep from 'lodash/cloneDeep';

import { informerTemplates } from '../../constants/informerTemplates';
import {
  CHOICE_INFORMER,
  EDIT_INFORMER_LOCAL,
  RESET_INFORMER_STYLE,
  SUCCESS_RESET_INFORMER_STYLES,
} from '../types';

export default function informer(
  state = {
    ...cloneDeep(informerTemplates.default),
    isVisible: true,
    name: '',
    categories_disabled: [],
    languages: [],
  },
  action = {},
) {
  switch (action.type) {
    case CHOICE_INFORMER:
      return { ...state, ...action.payload };

    case SUCCESS_RESET_INFORMER_STYLES:
      return { ...state, ...action.payload };

    case EDIT_INFORMER_LOCAL: {
      const { item, response, typeDevice, isSetting } = action.payload;
      const container = state[typeDevice];

      const block = isSetting
        ? { [item]: response }
        : {
            [typeDevice]: {
              ...container,
              [item]: {
                ...container[item],
                [response.name]: response.value,
              },
            },
          };

      return {
        ...state,
        ...block,
      };
    }

    case RESET_INFORMER_STYLE: {
      return cloneDeep(informerTemplates.default);
    }

    default:
      return state;
  }
}
