import { useEffect, useState } from 'react';

import { scriptCompilator } from './scriptCompiler';

export const useCodesGenerator = props => {
  const { siteId, informerId, siteDomain } = props;
  const [codeData, setCodeData] = useState({});

  // prettier-ignore
  useEffect(() => {
    setCodeData({
      ampScript: '<script async custom-element="amp-ad" src="https://cdn.ampproject.org/v0/amp-ad-0.1.js"></script>',
      ampInformer: `<amp-ad data-sid="${siteId}" data-iid="${informerId}" width="600" height="1600" layout="responsive"></amp-ad>`,
      informer: `<div data-top-iid="${informerId}"></div>`,
      script: `<script async type="text/javascript" src="${process.env.REACT_APP_CLIENT_SCRIPT_URL}/script.js" data-top-sid="${siteId}"></script>`,
      scriptUnique: scriptCompilator(siteId, siteDomain, true),
      // scriptAltCompilator: getScriptCompilatorCode(siteId, siteDomain),
    })
  }, [siteId, informerId, siteDomain]);

  return codeData;
};
