import React from 'react';
import PropTypes from 'prop-types';
import { HexColorPicker } from 'react-colorful';
import { FaCog } from 'react-icons/fa';

import useOutsideClick from '../../hooks/useOutsideClick';

import * as S from './styles';

const ColorPicker = props => {
  const [isVisible, toggleVisibility] = React.useReducer(s => !s, false);
  const pickerRef = React.useRef();

  const onChange = color => props.onComplete(color);

  useOutsideClick(pickerRef, () => {
    if (isVisible) toggleVisibility();
  });

  return (
    <S.PickerWrapper>
      <S.PickerButton
        style={{ backgroundColor: props.value }}
        onClick={toggleVisibility}
      >
        <FaCog />
      </S.PickerButton>
      <S.PickerChildrenWrapper pickerRef={pickerRef} isVisible={isVisible}>
        <HexColorPicker color={props.value} onChange={onChange} />;
      </S.PickerChildrenWrapper>
    </S.PickerWrapper>
  );
};

ColorPicker.propTypes = {
  value: PropTypes.string,
  onComplete: PropTypes.func,
};

ColorPicker.defaultProps = {
  value: '',
  onComplete: () => {},
};

export default ColorPicker;
