import React, { useCallback, useEffect, useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Select, Steps } from 'antd';
import { number, string, oneOfType } from 'prop-types';

// Constants
import {
  STATUS_ACTIVE,
  STATUS_ANALYTICS,
  STATUS_PENDING,
  STATUS_VERIFICATION,
  STATUS_SUSPENDED,
  STATUS_ANALYTICS_SPEEDUP,
} from '../../../constants/statuses';

// Utils
import { datesArrayToUnix, getDaysAgoRange } from '../../../utils/date';

// Redux
import {
  fetchTable as fetchSites,
  analyticsCheckAction,
} from '../../../redux/actions/sites';
import { fetchTable as fetchInformers } from '../../../redux/actions/site';

// Components
import InformerStatusText from '../components/InformerDetails';
import CodeBlock from '../../CodeBlock';
import Status from '../../Status';
import Loader from '../../Loader';
import AnalyticsSpeedUpForm from '../../Form/forms/AnalyticsSpeedUpForm';

const { Step } = Steps;

const Verification = ({
  siteId,
  status: statusProp,
  t,
  domain,
  getInformers,
  getSites,
  site,
  sites,
  onClose,
  isLoadingInformers,
  history,
  analyticsCheck,
  isLoadingSites,
}) => {
  const [status, setStatus] = useState(statusProp);
  const [informer, setInformer] = useState('');
  const [isInformerPlaced, setIsInformersPlaced] = useState(false);

  const checkForPlacedInformers = useCallback(() => {
    let isPlaced = false;

    try {
      const informersList = site.table.data;
      isPlaced =
        site.table.data.length > 0 &&
        informersList.find(
          // If there are at least one active informer with views
          ({ status: infStatus, views }) => infStatus === 'active' && views > 0,
        );
    } catch (e) {}

    return isPlaced;
  }, [site]);

  const statusIndexes = {
    [STATUS_VERIFICATION]: 1,
    [STATUS_SUSPENDED]: 2,
    [STATUS_ANALYTICS]: 2,
    [STATUS_ANALYTICS_SPEEDUP]: 2,
    [STATUS_PENDING]: 3,
    [STATUS_ACTIVE]: 4,
  };
  const stepIndex = statusIndexes[status];

  const onSelectInformer = informerId => {
    const currentInformer = site.table.data.find(({ id }) => informerId === id);
    setInformer(currentInformer);
  };

  const onSuccessIntegration = () => {
    const { data, total, ...sitesTable } = sites.table;

    getSites(sitesTable).then(onClose);
  };

  const goToInformersPage = () => {
    onClose();
    history.push(`/${siteId}`);
  };

  const restartAnalytics = () => {
    const { data, total, ...sitesTable } = sites.table;

    analyticsCheck({ siteId }).then(() => {
      setStatus(STATUS_ANALYTICS);
      getSites(sitesTable);
    });
  };

  useEffect(() => {
    const [start, end] = datesArrayToUnix(getDaysAgoRange());
    getInformers({ siteId, start, end });
  }, [getInformers, siteId]);

  useEffect(() => {
    setIsInformersPlaced(checkForPlacedInformers());
  }, [site, checkForPlacedInformers]);

  return (
    <div>
      <Loader isLoading={isLoadingSites} />
      <p>
        {t('basic.status')}: <Status status={status} />
      </p>

      {/* LABEL: Status ACTIVE */}
      <Steps direction="vertical">
        <Step
          status={stepIndex === 1 ? 'process' : 'finish'}
          title={t('verificationSteps.verification.title')}
          description={
            <>
              {stepIndex === 1 && (
                <p>{t('verificationSteps.verification.description')}</p>
              )}
              {/* Basic script */}
              <CodeBlock
                isCollapsible={stepIndex !== 1}
                siteId={siteId}
                domain={domain}
                status={status}
              />

              {/* Generated script
              <CodeBlock
                isCollapsible
                siteId={siteId}
                siteDomain={domain}
                altCode="scriptAlt"
                status={status}
              />
              */}

              {/* Script generator
              <CodeBlock
                isCollapsible
                siteId={siteId}
                siteDomain={domain}
                altCode="scriptAltCompilator"
                status={status}
              />
              */}
            </>
          }
        />

        {/* LABEL: Status ANALYTICS & SUSPENDED */}
        <Step
          status={
            stepIndex === 2
              ? status === STATUS_ANALYTICS ||
                status === STATUS_ANALYTICS_SPEEDUP
                ? 'process'
                : 'error'
              : stepIndex < 2
              ? 'wait'
              : 'finish'
          }
          title={t('verificationSteps.analytics.title')}
          description={
            stepIndex === 2 && (
              <>
                {status === STATUS_SUSPENDED && (
                  <Trans i18nKey="verificationSteps.suspended.description">
                    К сожалению, сайт не прошел проверку по количеству трафика
                    (1000 просмотров за сутки). При достижении сайтом
                    необходимого количества просмотров пройдите проверку
                    повторно
                    <br />
                    <Button
                      type="error"
                      style={{ float: 'right' }}
                      onClick={restartAnalytics}
                    >
                      Повторный анализ
                    </Button>
                  </Trans>
                )}
                {status === STATUS_ANALYTICS && (
                  <>
                    {t('verificationSteps.analytics.description')}
                    <AnalyticsSpeedUpForm
                      siteId={siteId}
                      onSuccess={onSuccessIntegration}
                    />
                  </>
                )}
                {status === STATUS_ANALYTICS_SPEEDUP && (
                  <>{t('verificationSteps.analyticsSpeedup.description')}</>
                )}
              </>
            )
          }
        />

        {/* LABEL: Status PENDING */}
        <Step
          status={
            stepIndex === 3 ? 'process' : stepIndex < 3 ? 'wait' : 'finish'
          }
          title={t('verificationSteps.pending.title')}
          description={
            stepIndex === 3 && t('verificationSteps.pending.description')
          }
        />

        {/* LABEL: Status ACTIVE */}
        <Step
          status={
            stepIndex === 4 && !isInformerPlaced
              ? 'process'
              : stepIndex < 4
              ? 'wait'
              : 'finish'
          }
          title={t('verificationSteps.active.title')}
          description={
            stepIndex === 4 && (
              <>
                {isInformerPlaced && (
                  <p>{t('verificationSteps.placedInformers')}</p>
                )}
                <p>
                  <Trans i18nKey="verificationSteps.active.description">
                    Для добавления информера перейдите на&nbsp;
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={goToInformersPage}>
                      страницу со списком информеров
                    </a>
                    , либо выберите из списка уже созданных вами:
                  </Trans>
                </p>
                <Select
                  loading={isLoadingInformers}
                  placeholder={t('basic.informers')}
                  onChange={onSelectInformer}
                  style={{ minWidth: '200px', marginBottom: '10px' }}
                >
                  {site &&
                    site.table.data.map(({ id, name }) => (
                      <Select.Option key={id} value={id}>
                        {name || `-- ${t('basic.noName')} --`}
                      </Select.Option>
                    ))}
                </Select>
                &nbsp;
                <InformerStatusText informer={informer} />
                {informer && (
                  <CodeBlock
                    isInformer
                    code={`<div data-top-iid="${informer.id}"></div>`}
                    informerId={informer.id}
                    status={status}
                  />
                )}
              </>
            )
          }
        />
      </Steps>
    </div>
  );
};

Verification.defaultProps = {
  siteId: null,
};

Verification.propTypes = {
  siteId: oneOfType([string, number]),
};

const mapStateToProps = ({ site, sites, network }) => ({
  isLoadingInformers: network.START_FETCH_TABLE_INFORMERS,
  isLoadingSites: network.START_FETCH_SITES_TABLE,
  site,
  sites,
});

const mapDispatchToProps = {
  getInformers: fetchInformers,
  getSites: fetchSites,
  analyticsCheck: analyticsCheckAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Verification));
