// TODO: it's a bad idea. It should be automatic (see ActionButtonsItem)
export const linksActions = ['page', 'link', 'edit', 'list', 'informersList'];

export default {
  approve: {
    index: 0,
    icon: 'check',
    color: 'green',
    type: 'success',
  },
  notification: {
    index: 0,
    icon: 'notification',
    color: 'blue',
  },
  decline: {
    index: 10,
    icon: 'stop',
    color: 'red',
    type: 'error',
  },
  page: {
    index: 20,
    icon: 'line-chart',
    color: 'blue',
  },
  list: {
    index: 30,
    icon: 'unordered-list',
  },
  informersList: {
    index: 30,
    icon: 'unordered-list',
  },
  link: {
    index: 40,
    icon: 'link',
  },
  settings: {
    index: 50,
    icon: 'setting',
    color: 'yellow',
  },
  code: {
    index: 60,
    icon: 'code',
  },
  siteIntegration: {
    index: 61,
    icon: 'file-done',
    color: 'green',
  },
  edit: {
    index: 70,
    icon: 'setting',
    color: 'yellow',
  },
  editInformer: {
    index: 71,
    icon: 'setting',
    color: 'yellow',
  },
  delete: {
    index: 100,
    icon: 'delete',
    color: 'red',
    confirm: {
      title: 'Уверены, что хотите удалить?',
      okText: 'Удалить',
      cancelText: 'Отмена',
      okButtonProps: {
        type: 'error',
      },
    },
  },
};
