import { Divider } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

import ValidateInput from 'components/ValidateInput';
import ValidateInputWithColorPicker from 'components/ValidateInputWithColorPicker';
import Label from 'components/Label';
import Dropdown from 'components/common/Dropdown';
import {
  mustBeFilled,
  mustBeNumber,
  mustBeHexColor,
} from 'config/validationRules';

import { ValidatorContext } from 'context/ValidatorContext';
import { withTranslation } from 'react-i18next';
import Grid from '../../Grid';

const BorderSettings = ({ border, item, onChange, prefix = '', t }) => {
  const { actions } = React.useContext(ValidatorContext);
  const borderTypes = [
    {
      key: 'dashed',
      text: t('basic.dashed'),
      value: 'dashed',
    },
    {
      key: 'solid',
      text: t('basic.solid'),
      value: 'solid',
    },
  ];

  return (
    <>
      <Divider>{t('basic.border')}</Divider>

      <Grid colProps={{ xs: 12, lg: 12 }}>
        <div>
          <Label labelText={t('basic.width')} asterisk />
          <ValidateInput
            name="widthBorder"
            type="number"
            validateName={`${prefix}-widthBorder`}
            defaultValue={border.widthBorder}
            value={border.widthBorder}
            controlled
            placeholder="3"
            rules={[mustBeFilled, mustBeNumber]}
            onValidate={input => actions.validate(input)}
            onChange={value =>
              onChange({
                item,
                response: { name: 'widthBorder', value },
              })
            }
          />
        </div>

        <div>
          <Label labelText={t('basic.color')} asterisk />
          <ValidateInputWithColorPicker
            name="color"
            validateName={`${prefix}-color`}
            defaultValue={border.color}
            value={border.color}
            controlled
            colorPicker={{
              onDrag: () => {},
              onComplete: value =>
                onChange({
                  item,
                  response: { name: 'color', value },
                }),
            }}
            inputValidation={{
              placeholder: '#000000',
              type: 'text',
              onChange: value =>
                onChange({
                  item,
                  response: { name: 'color', value },
                }),
              rules: [mustBeFilled, mustBeHexColor],
              onValidate: input => actions.validate(input),
            }}
          />
        </div>

        <div>
          <Label labelText={t('basic.type')} asterisk />
          <Dropdown
            name="borderType"
            validateName={`${prefix}-borderType`}
            options={borderTypes}
            selection
            value={border.borderType}
            onChange={value =>
              onChange({
                item,
                response: { name: 'borderType', value },
              })
            }
          />
        </div>

        <div>
          <Label labelText={t('basic.radius')} asterisk />
          <ValidateInput
            name="radius"
            type="number"
            validateName={`${prefix}-radius`}
            defaultValue={border.radius}
            value={border.radius}
            controlled
            placeholder="2"
            rules={[mustBeFilled, mustBeNumber]}
            onValidate={input => actions.validate(input)}
            onChange={value =>
              onChange({
                item,
                response: { name: 'radius', value },
              })
            }
          />
        </div>
      </Grid>
    </>
  );
};
BorderSettings.propTypes = {
  border: PropTypes.shape({
    borderType: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    radius: PropTypes.string.isRequired,
    widthBorder: PropTypes.string.isRequired,
  }).isRequired,
  item: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withTranslation()(BorderSettings);
