import * as routesPath from 'config/routePaths';
import AddInformer from 'pages/AddInformer';
import Error404 from 'pages/Errors/Error404';
import Informer from 'pages/Informer';
import InformerByDate from 'pages/InformerByDate';
import InformersList from 'pages/InformersList';
import Login from 'pages/Login';
import {Site} from 'pages/Site';
import Sites from 'pages/Sites';
import SiteStatistics from 'pages/SiteStatistics';
import Terms from 'pages/Terms';
// import InformerNews from 'pages/InformerNews';
import { areInvalidRoutes, isAuth, isNotAuth } from 'services/routeMiddlewares';

const publicRoutes = [
  {
    path: routesPath.LOGIN,
    component: Login,
    exact: true,
    middleware: isAuth,
    isAuthorized: false,
  },
  {
    path: routesPath.ERROR_404,
    component: Error404,
  },
  {
    path: routesPath.TERMS,
    component: Terms,
  },
];

const privateRoutes = [
  {
    path: routesPath.HOME,
    component: Sites,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.SITE,
    component: Site,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.SITE_STATISTICS,
    component: SiteStatistics,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.INFORMERS_LIST,
    component: InformersList,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.INFORMER_ADD,
    component: AddInformer,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.INFORMER,
    component: Informer,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.INFORMER_EDIT,
    component: AddInformer,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.INFORMER_BY_DATE,
    component: InformerByDate,
    exact: true,
    middleware: [isNotAuth, areInvalidRoutes],
  },
  // {
  //   path: routesPath.INFORMER_NEWS,
  //   component: InformerNews,
  //   exact: true,
  //   middleware: isNotAuth
  // }
];

const routes = [...publicRoutes, ...privateRoutes];

export default routes;
