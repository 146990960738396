// eslint-disable-next-line import/named
import { notSecureAxios, secureAxios } from 'services/axios/enhanceAxios';

import { api } from './config';
import { correctParams, paramsToQueries } from './helper';

// label AUTHORIZATION ________________________________________________________
export const signIn = params =>
  notSecureAxios.post(`${api}/login`, correctParams(params));

export const registration = ({ passwordConfirm, ...params }) =>
  notSecureAxios.post(`${api}/registration`, correctParams(params));

// label PUBLISHER (Sites list) ________________________________________________________

export const getSitesInfo = params =>
  secureAxios().get(`${api}/sites`, {
    params: correctParams(params, true),
  });

export const getSiteById = id => secureAxios().get(`${api}/sites/${id}`, {});

export const addNewSite = ({ domain, name }) =>
  secureAxios().post(`${api}/sites`, { domain, name });

export const editSiteById = ({ id, domain, name }) =>
  secureAxios().put(`${api}/sites/${id}`, { domain, name });

export const removeSiteById = id =>
  secureAxios().delete(`${api}/sites/${id}`, {});

export const analyticsCheckApi = ({ siteId, ...params }) =>
  secureAxios().post(
    `${api}/sites/${siteId}/analytics`,
    correctParams(params, true),
  );

export const analyticsSpeedUpApi = ({ siteId, ...params }) =>
  secureAxios().post(
    `${api}/sites/${siteId}/analytics`,
    correctParams(params, false),
  );

// label SITE STATISTICS ________________________________________________________

export const getSitesStatistics = params =>
  secureAxios().get(`${api}/statistics`, { params: correctParams(params) });

export const getSiteStatisticsChart = params =>
  secureAxios().get(`${api}/statistics`, {
    params: correctParams(params),
  });

export const getSiteStatisticsTable = params =>
  secureAxios().get(`${api}/statistics/site`, {
    params: correctParams(params, true),
  });

export const getSitesListApi = params => {
  return secureAxios().get(
    `${api}/sites${paramsToQueries({
      params: correctParams(params),
      isClearCommas: true,
    })}`,
    {},
  );
};

// label INFORMER ________________________________________________________

export const getInformersBySite = ({isLoading, ...params}) =>
  secureAxios().get(`${api}/informers`, {
    params: correctParams(params),
  });

export const getInformerById = id =>
  secureAxios().get(`${api}/informers/${id}`, {});

export const removeInformerById = id =>
  secureAxios().delete(`${api}/informers/${id}`, {});

export const setInformerStyles = ({
  name,
  siteId,
  data: { desktop, mobile, isVisible, categories_disabled, languages }, // eslint-disable-line camelcase
}) =>
  secureAxios().post(`${api}/informers`, {
    name,
    languages,
    site_id: siteId,
    categories_disabled,
    styles: { desktop, mobile, isVisible },
  });

export const editInformerStyles = ({
  id,
  name,
  data: { desktop, mobile, isVisible, categories_disabled, languages }, // eslint-disable-line camelcase
}) =>
  secureAxios().put(`${api}/informers/${id}`, {
    name,
    languages,
    categories_disabled,
    styles: { desktop, mobile, isVisible },
  });

export const resetInformerStylesApi = ({ id }) =>
  secureAxios().put(`${api}/informers/${id}/reset`);

export const getSiteNewsApi = params =>
  secureAxios().get(`${api}/informers/news`, { params: correctParams(params) });

// label INFORMER STATISTICS __________________________________________

export const getInformersStatisticBySite = params =>
  secureAxios().get(`${api}/statistics`, {
    params: correctParams(params),
  });

export const getInformerStatistics = ({ start, end, id }) =>
  secureAxios().get(`${api}/statistics`, {
    params: { start, end, informer_id: id },
  });

export const getInformerInfo = params =>
  secureAxios().get(`${api}/statistics/informer`, {
    params: correctParams(params),
  });

// label NEWS ________________________________________________________

export const getNews = id => secureAxios().get(`${api}/news/${id}`, {});

export const getInformerByDateInfo = params =>
  secureAxios().get(`${api}/news`, {
    params: correctParams(params),
  });

export const getInformerNewsInfo = params =>
  secureAxios().get(`${api}/statistics/news`, {
    params: correctParams(params),
  });

export const getInformerNewsStatistics = params =>
  secureAxios().get(`${api}/statistics`, { params: correctParams(params) });

// label DATA LISTS _________________________________________________

export const getNewsCategoriesListApi = () =>
  secureAxios().get(`${api}/news/categories/all`, {});

// label OTHER _________________________________________________

export const sendFeedbackApi = params =>
  secureAxios().post(`${api}/feedback`, correctParams(params));
