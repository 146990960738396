import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSiteNews } from '../../../redux/actions/site';

export const useExampleNews = (typeDevice = 'mobile') => {
  const dispatch = useDispatch();
  const { informerSettings, site } = useSelector(s => s);
  const informerStyle = informerSettings[typeDevice];
  const { general } = informerStyle;
  const { countCalls, countRows } = general;
  const { info } = site;
  const siteId = info.id;

  const [limit, setLimit] = useState(10);

  const countLimit = useCallback(() => {
    setLimit(countCalls * countRows);
  }, [countCalls, countRows]);

  const handleGetNews = useCallback(() => {
    if (siteId && limit) dispatch(getSiteNews({ siteId, limit }));
  }, [dispatch, siteId, limit]);

  useEffect(handleGetNews, [siteId, limit, handleGetNews]);

  useEffect(countLimit, [typeDevice, countCalls, countRows, countLimit]);

  return { limit };
};
