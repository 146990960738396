import React from 'react';
import { Series, YAxis } from 'react-jsx-highcharts';

const YAxises = ({ data, isLoading, hasCoefficient }) => {
  const mainData = data.slice(0, data.length - Number(hasCoefficient));
  const lastData = data.slice(data.length - 1, data.length)[0];

  return !isLoading && mainData && lastData ? (
    <>
      {mainData && (
        <YAxis id="yaxis-left">
          {mainData.map(dataChunk => (
            <Series
              type="spline"
              key={dataChunk.name}
              id={dataChunk.name}
              yAxis="yaxis-left"
              zIndex={1}
              {...dataChunk}
            />
          ))}
        </YAxis>
      )}
      {hasCoefficient && (
        <YAxis id="yaxis-right" opposite={!!mainData.length}>
          <Series
            type="spline"
            key={lastData.name}
            id={lastData.name}
            yAxis="yaxis-right"
            zIndex={1}
            {...lastData}
          />
          <YAxis.Title>{lastData.name}</YAxis.Title>
        </YAxis>
      )}
    </>
  ) : null;
};

export default YAxises;
