import React, { useEffect, useState } from 'react';
import { bool, element, shape, func } from 'prop-types';
import { Tooltip } from 'antd';

const TooltipConditional = ({
  onVisibleChange,
  isHiddenByDefault = true,
  withTooltip,
  withTimeout,
  children,
  ...tooltipProps
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleChangeVisibility = () => {
    setIsVisible(!isVisible);

    if (onVisibleChange) {
      onVisibleChange();
    }
  };

  useEffect(() => {
    if (isHiddenByDefault) return undefined;

    const timeout = setTimeout(
      () => setIsVisible(true),
      withTimeout ? 2000 : 0,
    );
    return () => clearTimeout(timeout);
  }, [withTimeout, isHiddenByDefault]);

  return withTooltip ? (
    <Tooltip
      {...tooltipProps}
      visible={isVisible}
      trigger={['hover', 'click', 'focus']}
      onVisibleChange={handleChangeVisibility}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
};

TooltipConditional.propTypes = {
  withTooltip: bool,
  children: element,
  tooltipProps: shape({}),
  withTimeout: bool,
  onVisibleChange: func,
};

export default TooltipConditional;
