import React, { useState } from 'react';
import { bool, func, shape } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Button, Col, Divider, Form, Icon, Input, Row } from 'antd';

// Utils
import {
  sameValueAsField,
  lengthMustBeMoreAndLess,
  mustBeAllowedSymbols,
  mustBeFilled,
  mustBeEmail,
} from '../../../config/validationRules';
import useValidation from '../utils/useValidation';

// Components
import Loader from '../../Loader';
import { registrationAction, signInAction } from '../../../redux/actions/auth';
import FormCaptcha from '../components/FormCaptcha';

const initialForm = {
  login: {
    value: '',
    rules: [
      mustBeFilled,
      lengthMustBeMoreAndLess(1, 255),
      mustBeAllowedSymbols,
    ],
  },
  password: {
    value: '',
    rules: [mustBeFilled, lengthMustBeMoreAndLess(6, 256)],
  },
  passwordConfirm: {
    value: '',
    rules: [mustBeFilled, sameValueAsField('password')],
  },
  name: {
    value: '',
    rules: [mustBeFilled],
  },
  email: {
    value: '',
    rules: [mustBeFilled, mustBeEmail],
  },
  phone: {
    value: '',
    rules: [],
  },
  gRecaptchaResponse: {
    value: null,
    rules: [mustBeFilled],
  },
};

const RegistrationForm = props => {
  const { isLoadingRegistration, isLoadingLogin, history, register, t } = props;
  const [isServerError, setIsServerError] = useState(null);

  const onSubmit = currentForm =>
    register(currentForm)
      .then(response => {
        setIsServerError(false);
        return !response.fail || Promise.reject();
      })
      .then(isSuccess => isSuccess && history.push('/'))
      .catch(() => setIsServerError(true));

  const { bindedInputFunctions, bindedSubmit, errors } = useValidation(
    onSubmit,
    initialForm,
  );

  return (
    <>
      <Loader isLoading={isLoadingRegistration || isLoadingLogin} />

      <Form className="login-form">
        <Form.Item {...errors.login}>
          <Input
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="login"
            placeholder={t('basic.username')}
            autoComplete="new-username"
            size="large"
            defaultValue={initialForm.login.value}
            {...bindedInputFunctions}
          />
        </Form.Item>

        <Row type="flex" align="top" justify="space-between" gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item {...errors.password}>
              <Input.Password
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                name="password"
                placeholder={t('basic.password')}
                autoComplete="new-password"
                size="large"
                defaultValue={initialForm.password.value}
                {...bindedInputFunctions}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item {...errors.passwordConfirm}>
              <Input.Password
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                name="passwordConfirm"
                placeholder={t('basic.passwordConfirm')}
                autoComplete="off"
                size="large"
                defaultValue={initialForm.passwordConfirm.value}
                {...bindedInputFunctions}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left" style={{ fontSize: '12px' }}>
          {t('basic.userData')}
        </Divider>

        <Form.Item {...errors.name}>
          <Input
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="name"
            placeholder={t('basic.name')}
            autoComplete="off"
            size="large"
            defaultValue={initialForm.name.value}
            {...bindedInputFunctions}
          />
        </Form.Item>

        <Form.Item {...errors.email}>
          <Input
            prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="email"
            placeholder={t('basic.email')}
            autoComplete="off"
            size="large"
            defaultValue={initialForm.email.value}
            {...bindedInputFunctions}
          />
        </Form.Item>

        <Form.Item {...errors.phone}>
          <Input
            prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="phone"
            placeholder={t('basic.phone')}
            autoComplete="off"
            size="large"
            defaultValue={initialForm.phone.value}
            {...bindedInputFunctions}
          />
        </Form.Item>

        <Form.Item {...errors.gRecaptchaResponse} style={{ minHeight: '78px' }}>
          <FormCaptcha
            name="gRecaptchaResponse"
            isServerError={isServerError}
            {...bindedInputFunctions}
          />
        </Form.Item>

        <Form.Item>
          <Button
            size="large"
            type="success"
            onClick={bindedSubmit}
            style={{ width: '100%' }}
          >
            {t('form.buttonRegistration')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

RegistrationForm.propTypes = {
  isLoadingRegistration: bool,
  showSuccessModal: func,
  isLoadingLogin: bool,
  history: shape({}),
  register: func,
  t: func,
};

const mapStateToProps = ({ auth, network, userSettings }) => ({
  status: auth.user.status,
  isLoadingRegistration: network.START_REGISTRATION_AUTH,
  isLoadingLogin: network.START_SIGN_IN_AUTH,
  isMobile: userSettings.isMobile,
});

const mapDispatchToProps = dispatch => ({
  register: bindActionCreators(registrationAction, dispatch),
  signIn: bindActionCreators(signInAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withTranslation('')(RegistrationForm)));
