import styled from 'styled-components';

import { colorGrey } from '../../../../styles/js/variables/colors';

export const HeaderMenuList = styled.div`
  min-width: 240px;
  padding: 0.5em;
`;

export const HeaderMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.75em;
  margin-bottom: 0.75em;
  border-bottom: 1px solid #eee;

  ${({ isLast }) =>
    isLast &&
    `
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  `}

  ${({ isTwoSided }) =>
    isTwoSided &&
    `
    > .ant-btn {
      padding-left: 0;
      padding-right: 0;
      color: rgba(0, 0, 0, 0.65);
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      > span {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  `}
`;

export const HeaderMenuAction = styled.div`
  display: flex;

  small {
    display: block;
    color: ${colorGrey};
  }
`;
