import React, { useCallback, useEffect, useRef, useState } from 'react';

// Components
import FormActions from './FormActions';

const FormActionsSticky = props => {
  const [isVisible, setIsVisible] = useState(true);
  const buttonsRef = useRef();
  const options = {
    root: buttonsRef.current,
    rootMargin: '0px',
    threshold: 1.0,
  };

  const runObserver = useCallback(() => {
    const callback = entries => setIsVisible(entries[0].isIntersecting);
    const observer = new IntersectionObserver(callback, options);

    observer.observe(buttonsRef.current);

    return () => observer.unobserve(buttonsRef.current);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(runObserver, [runObserver]);

  return (
    <>
      <FormActions {...props} buttonsRef={buttonsRef} />
      {!isVisible && <FormActions {...props} sticky={!isVisible} />}
    </>
  );
};

export default FormActionsSticky;
