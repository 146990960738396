import { getAccessToken } from '../services/localStorage';
import { convertDataToCamelCase } from './object';

// TODO: we should find a better way to parse token
const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const getTokenData = () => {
  const token = getAccessToken();
  return convertDataToCamelCase(parseJwt(token)) || {};
};
