// eslint-disable-next-line import/no-cycle
import middlewaredAxios from 'services/axios/axiosMiddleware';

import { getAccessToken } from '../localStorage';

const createEnhanceAxiosWithHeader = headers => {
  const addHeaders = (event, ...args) => {
    switch (event) {
      case 'getUri':
      case 'options':
      case 'request':
      case 'head':
      case 'delete':
      case 'get': {
        const newArgs = [...args];
        if (newArgs.length > 1) {
          newArgs[1].headers = { ...newArgs[1].headers, ...headers };
        }

        return newArgs;
      }

      case 'post':
      case 'put':
      case 'patch': {
        const newArgs = [...args];
        if (newArgs.length > 1) {
          newArgs[2] = {};
          newArgs[2].headers = { ...newArgs[2].headers, ...headers };
        }

        return newArgs;
      }
      default: {
        const newArgs = [...args];
        if (newArgs.length > 1) {
          newArgs[1].headers = { ...newArgs[1].headers, ...headers };
        }

        return newArgs;
      }
    }
  };
  const callAxios = (event, ...args) => middlewaredAxios[event](...args);
  const callSecureAxios = (event, ...args) =>
    callAxios(event, ...addHeaders(event, ...args));
  const instance = [
    'request',
    'get',
    'delete',
    'head',
    'options',
    'post',
    'put',
    'patch',
    'getUri',
  ].reduce(
    (instanceObject, event) => ({
      ...instanceObject,
      [event]: (...args) => callSecureAxios(event, ...args),
    }),
    {},
  );
  return instance;
};

const secureAxios = (params = {}) => {
  const token = params.token || getAccessToken();

  return createEnhanceAxiosWithHeader({
    Authorization: token,
  });
};

export {
  secureAxios,
  createEnhanceAxiosWithHeader,
  middlewaredAxios as notSecureAxios,
};
