import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { ColumnDescriptions } from '../../../components/Descriptions/components/ColumnDescriptions';
import EmptyData from '../../../components/EmptyData';

export const SiteStatsInfo = ({ excludedFields }) => {
  const match = useRouteMatch();
  const { statisticsBasic } = useSelector(s => s.site);
  const { siteId } = match.params;
  const stats =
    statisticsBasic?.data?.find(({ id }) => id === Number(siteId)) || {};

  if (Object.keys(stats).length === 0) return <EmptyData />;

  return <ColumnDescriptions data={stats} excludedFields={excludedFields} />;
};
