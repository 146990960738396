import React from 'react';
import { Icon, Tag } from 'antd';
import { func, string } from 'prop-types';
import { withTranslation } from 'react-i18next';

import { statusesStyles } from '../../constants/statuses';

const Status = ({
  status,
  noIcon = false,
  noTitle = false,
  noBackground = false,
  style = {},
  onClick,
  t,
}) => {
  if (!statusesStyles[status]) return status;

  const IconComponent = () =>
    noIcon ? null : (
      <Icon
        type={statusesStyles[status].icon}
        theme={statusesStyles[status].theme || 'filled'}
        twoToneColor="#fff"
        style={{ opacity: 0.65 }}
      />
    );

  const customStyles = noBackground
    ? {
        backgroundColor: 'transparent',
        color: statusesStyles[status].color,
        fontWeight: 'bold',
      }
    : {};

  return (
    <Tag
      {...statusesStyles[status]}
      style={{ marginRight: 0, ...customStyles, ...style }}
      onClick={onClick}
    >
      <IconComponent />
      &nbsp;
      {!noTitle && t(`status.${status}`)}
    </Tag>
  );
};

Status.defaultProps = {
  t: () => {},
};

Status.propTypes = {
  status: string.isRequired,
  t: func,
};

export default withTranslation('common')(Status);
