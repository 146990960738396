import { showErrorSavingMessage } from '../../components/Notifications/helper';

import {
  registration as registrationAuth,
  signIn as signInAuth,
} from '../../api';

// Utils
import { saveDataToLocalStorage } from '../../services/localStorage';
import {
  CLEAR_USER,
  FAILURE_REGISTRATION_AUTH,
  FAILURE_SIGN_IN_AUTH,
  START_REGISTRATION_AUTH,
  START_SIGN_IN_AUTH,
  SUCCESS_REGISTRATION_AUTH,
  SUCCESS_SIGN_IN_AUTH,
} from '../types';

export const clearUser = () => dispatch => {
  dispatch({
    type: CLEAR_USER,
  });
};

export const signInAction = signInData => dispatch => {
  dispatch({
    type: START_SIGN_IN_AUTH,
  });

  return signInAuth(signInData)
    .then(response => {
      const { data, status } = response;

      if (status !== 200) throw new Error();

      saveDataToLocalStorage(data);

      return dispatch({
        type: SUCCESS_SIGN_IN_AUTH,
        payload: data,
      });
    })
    .catch(fail => {
      return dispatch({
        status: 401,
        type: FAILURE_SIGN_IN_AUTH,
        fail,
        notification: {
          type: 'error',
          text: `Login has failure. Status ${fail.status}`,
        },
      });
    });
};

export const registrationAction = params => dispatch => {
  dispatch({
    type: START_REGISTRATION_AUTH,
  });

  return registrationAuth(params)
    .then(response => {
      const { data, status } = response;

      if (status !== 200) throw new Error();

      saveDataToLocalStorage(data);

      return dispatch({
        type: SUCCESS_REGISTRATION_AUTH,
        payload: data,
      });
    })
    .catch(fail => {
      const { code, message } = fail.response.data;

      return dispatch({
        status: 401,
        type: FAILURE_REGISTRATION_AUTH,
        fail,
        notification: {
          type: 'error',
          text: code === 2 ? showErrorSavingMessage(fail) : message,
        },
      });
    });
};
