import React from 'react';
import { func, shape, bool } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AddSiteForm from '../../Form/forms/AddSiteForm';

const AddSiteModal = ({
  fields,
  actions,
  onClose,
  isSaving,
  success,
  publisherId,
  t,
}) => {
  const { onSubmit } = actions;
  const id = fields ? fields.id : null;

  const handleSubmit = ({ notice, ...form }) =>
    onSubmit({ ...form, id, publisherId })
      .then(({ type }) =>
        type.includes('FAILURE') ? Promise.reject() : success.onSubmit(),
      )
      .then(onClose);

  return (
    <>
      <h2>{t(`form.title.${fields ? 'editSite' : 'addSite'}`)}</h2>
      <AddSiteForm
        isSaving={isSaving}
        formData={fields}
        onSubmit={handleSubmit}
        onCancel={onClose}
      />
    </>
  );
};

AddSiteModal.defaultProps = {
  actions: {
    onSubmit: () => {},
  },
  success: {
    onSubmit: () => {},
  },
};

AddSiteModal.propTypes = {
  isSaving: bool,
  fields: shape({}),
  onClose: func.isRequired,
  actions: shape({
    onSubmit: func,
  }),
  success: shape({
    onSubmit: func,
  }),
};

const mapStateToProps = ({ network }) => ({
  isSaving: network.START_EDIT_SITE,
});

export default connect(mapStateToProps)(withTranslation()(AddSiteModal));
