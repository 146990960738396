import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

function processMiddleware(middlewares, props) {
  let location = { ...props.location };

  const replace = data => {
    location = { ...data };
  };

  if (middlewares) {
    if (typeof middlewares === 'function') {
      middlewares(props, replace);

      return location;
    }

    if (Array.isArray(middlewares)) {
      // eslint-disable-next-line
      for (const middleware of middlewares) {
        middleware(props, replace);
      }

      return location;
    }
  }

  return location;
}

const LayoutWrapper = Layout => {
  const WrappedRoute = ({ middleware, component, ...rest }) => (
    <Route
      {...rest}
      render={props => {
        const to = processMiddleware(middleware, props);
        if (to.pathname !== props.location.pathname) {
          return <Redirect to={to} />;
        }

        return <Layout {...rest} {...props} component={component} />;
      }}
    />
  );

  WrappedRoute.propTypes = {
    component: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node,
      PropTypes.shape({}),
    ]).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    middleware: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  };

  WrappedRoute.defaultProps = {
    location: {
      pathname: '',
    },
  };

  return WrappedRoute;
};

LayoutWrapper.propTypes = {
  Layout: PropTypes.element,
};

export default LayoutWrapper;
