import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import history from './history';
import authorizationMiddleware from './middlewares/authorization';
import errorsMiddleware from './middlewares/errors';
import notificationMiddleware from './middlewares/notifications';
import reducer from './reducers';

const initialState = {};

const developmentMiddlewares = [];

const routeMiddlewareWithHistory = routerMiddleware(history);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'MemberAdmin' })
  : compose;

const store = createStore(
  reducer,
  initialState,
  composeEnhancers(
    applyMiddleware(
      thunk,
      errorsMiddleware,
      notificationMiddleware,
      authorizationMiddleware,
      routeMiddlewareWithHistory,
      ...(process.env.NODE_ENV === 'production' ? [] : developmentMiddlewares),
    ),
  ),
);

export { store as default };
