import React from 'react';
import { func, shape } from 'prop-types';
import { Button, Empty } from 'antd';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

// Styles
import {
  ErrorDescription,
  ErrorImage,
  ErrorTitle,
  ErrorWrapper,
} from '../styles';

const Error404 = ({ history, t }) => (
  <ErrorWrapper>
    <div>
      <ErrorImage image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
      <ErrorTitle>{t('content.title.error')} 404</ErrorTitle>
      <ErrorDescription>{t('content.titleHint.pageNotFound')}</ErrorDescription>
      <div>
        <Button type="success" onClick={history.goBack} icon="double-left">
          {t('form.buttonBack')}
        </Button>
        &nbsp;
        <Button type="primary" onClick={() => history.push('/')} icon="home">
          {t('form.buttonHome')}
        </Button>
      </div>
    </div>
  </ErrorWrapper>
);

Error404.propTypes = {
  history: shape({}),
  t: func,
};

export default withRouter(withTranslation()(Error404));
